import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import userPic from './../../../assets/img/user.png';
import logo from './../../../assets/img/logo.png';
import { toasterSuccess } from './../../../helper/toaster.helper';
import { jwtDecode } from './../../../helper/jwt.helper';

class Header extends Component {

    _unmounted = false;

    container = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }
    componentDidMount() {
        this._unmounted = true;
        document.addEventListener('click', this.handleOutisideClick);
    }

    componentWillUnmount() {
        this._unmounted = false;
        document.removeEventListener('click', this.handleOutisideClick);
    }

    handleClick = () => {
        this.setState(state => ({
            isOpen: !state.isOpen
        }))
    }
    handleOutisideClick = (e) => {
        if (this.container.current && !this.container.current.contains(e.target)) {
            this.setState({
                isOpen: false
            })
        }
    }

    notRedirect = (e) => {
        e.preventDefault();
        this.setState(state => ({
            isOpen: !state.isOpen
        }))
    }

    logout = (e) => {
        e.preventDefault()
        const { history } = this.props;
        //toasterSuccess("Logout successfully.");
        localStorage.removeItem('token');
        history.push('/admin');
    }

    render() {
        const token = localStorage.getItem('token');
        const res = jwtDecode(token);

        const { isOpen } = this.state;
        const { location: { pathname } } = this.props;
        const splitPath = pathname.split('/');
        let isAdmin = false;
        if (splitPath[1] === 'admin') {
            isAdmin = true;
        }

        return (
            isAdmin && token &&
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="brand">
                    <NavLink to="/admin/dashboard"><img src={logo} alt="Klorofil Logo" className="img-responsive logo" /></NavLink>
                </div>
                <div className="container-fluid">
                    <div id="navbar-menu">
                        <ul className="nav navbar-nav navbar-right">

                            <li className={isOpen ? "dropdown open" : "dropdown"} ref={this.container}>
                                <a href="/#" onClick={this.notRedirect} className="dropdown-toggle nav-def" data-toggle="dropdown"><img src={logo} className="img-circle" alt="Avatar" /> <span>{res.username}</span> <i className="icon-submenu lnr lnr-chevron-down"></i></a>
                                <ul className="dropdown-menu">
                                    {/* <li><Link to="/dashboard"><i className="lnr lnr-user"></i> <span>My Profile</span></Link></li>
                                    <li><Link to="/dashboard"><i className="lnr lnr-envelope"></i> <span>Message</span></Link></li>
                                    <li><Link to="/dashboard"><i className="lnr lnr-cog"></i> <span>Settings</span></Link></li> */}
                                    <li><Link to="/admin/changepassword"><i className="lnr lnr-user"></i> <span>Change Password</span></Link></li>
                                    <li><Link to="/" onClick={this.logout}><i className="lnr lnr-exit"></i> <span>Logout</span></Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withRouter(props => <Header {...props} />);