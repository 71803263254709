import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ARLogo from './../../../assets/img/AR_Logo.png';
import { emailValidation } from '../../../helper/validation.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';
import './UserPasswordReset.css';

class UserPasswordReset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: {
                email: ''
            },
            valid: {
                email: false
            }
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkvalidation(name, value));
    }
    checkvalidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;

        switch (name) {
            case 'email':
                const emailValid = emailValidation(value);
                if (emailValid.status) {
                    error.email = '';
                    valid.email = emailValid.status;
                } else {
                    error.email = emailValid.message;
                    valid.email = emailValid.status;
                }
                break;
            default:
                break;
        }
        this.setState({
            error,
            valid
        })
    }
    submitForm = async (e) => {
        try {

            e.preventDefault();
            const { valid } = this.state;
            if (valid.email) {

                const formData = new FormData();
                formData.append('email', this.state.email);

                const response = await apiHelper('user/passwordReset', 'POST', formData, null);
                const res = response.data;
                if (res.status) {
                    // localStorage.setItem('userToken', res.data);
                    //toasterSuccess(res.message);
                    this.props.history.push('/login');
                } else {
                    toasterError(res.message);
                }

            } else {
                toasterError('All field required');
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    render() {
        const { email, valid } = this.state;
        const disabled = !valid.email;

        let btnClass = "MuiButtonBase-root MuiButton-root MuiButton-contained w-full mx-auto mt-16 MuiButton-containedPrimary";
        if (disabled) {
            btnClass += "  Mui-disabled Mui-disabled";
        }
        return (
            <div id="fuse-layout">
                <div className="sc-Axmtr hvJMgY">
                    <div className="main12">
                        <div className="jss12 mt-0" style={{ marginBottom: '64px', height: '100%', minHeight: '100vh' }}>
                            <div className="sc-AxjAm bcMPWx jss36">
                                <div className="sc-AxiKw eSbheu">
                                    {/* <img src={ARLogo} alt="logo" /> */}
                                    <div className="heading-txt">
                                        <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-colorInherit">Welcome to Audition Revolution!</h3>
                                    </div>
                                    <div>
                                        <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorInherit">Sign In Or Create New Account!</h6>
                                    </div>
                                </div>
                                <div className="MuiPaper-root MuiCard-root sc-AxirZ bJCmFu MuiPaper-elevation1">
                                    <div className="MuiCardContent-root">
                                        <h6 className="MuiTypography-root MuiTypography-h6">RESET YOUR PASSWORD</h6>
                                        <form name="recoverForm" onSubmit={this.submitForm}>
                                            <div className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth" style={{ marginBottom: '1.6rem' }}>
                                                <label>Email
                                                        <span> *</span>
                                                </label>
                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                                                    <input name="email" required="" type="email" className="form-control" value={email} onChange={this.inputHandler} />
                                                </div>
                                            </div>
                                            <button className={btnClass} tabindex="-1" type="submit" disabled={disabled} aria-label="Reset">
                                                <span className="MuiButton-label">RESET PASSWORD</span>
                                            </button>
                                        </form>
                                        <div className="no-account">
                                            <a className="font-medium" href="/login">Go back to login</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="jss11">
                            <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters" style={{ padding: '0px' }}>
                                <div className="sc-AxheI eXzlnr">
                                    <p className="MuiTypography-root MuiTypography-body2">Audition Revolution.v2 Beta</p>
                                    <p className="MuiTypography-root MuiTypography-body2">Contact Support: support@auditionrevolution.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <UserPasswordReset {...props} />);