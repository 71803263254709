import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ProfileComponent from '../ProfileComponent';
import { jwtDecode } from '../../../helper/jwt.helper';
import CompanyComponent from '../CompanyComponent';

class CommonComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userType: null,
            tabsActive: 0
        }
    }

    componentDidMount() {
        const userToken = localStorage.getItem('userToken');
        const tokenData = jwtDecode(userToken);

        if (userToken && tokenData) {
            this.setState({
                userType: tokenData.userType,
            })
        }
    }
    tabHandler = (val) => {
        this.setState({
            tabsActive: val
        })
    }
    render() {
        const { tabsActive } = this.state;

        if (this.state.userType && this.state.userType.includes("theatre")) {
            if (this.state.userType.includes("actor")) {
                return (
                    <>
                        <div className="tab-blos">
                            <ul className="tbs-ul">
                                <li className={tabsActive === 0 ? "active" : ""} onClick={() => this.tabHandler(0)}>Company Dashboard</li>
                                <li className={tabsActive === 1 ? "active" : ""} onClick={() => this.tabHandler(1)}>Actor Profile</li>
                            </ul>
                            <div className="blo-tabs">
                                {tabsActive === 0 ? <CompanyComponent /> : null}
                                {tabsActive === 1 ? <ProfileComponent /> : null}
                            </div>
                        </div>
                    </>
                );
            }
            return <CompanyComponent />;
        } else {
            return <ProfileComponent />;
        }
    }
}

export default withRouter(props => <CommonComponent {...props} />);