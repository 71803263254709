import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
// import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
// import { apiHelper } from '../../../helper/api.helper';

// const headers = {
//     'Authorization': localStorage.getItem('userToken')
// }
class AddSkillsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skillText: '',
            error: {
                skillText: ''
            },
            valid: {
                skillText: false
            }
        }
    }

    componentDidMount() {
        const { isEdit, data } = this.props;
        if (isEdit) {
            this.setState({
                skillText: data?.text,
                error: {
                    skillText: ''
                },
                valid: {
                    skillText: data?.text ? true : false
                }
            })
        } else {
            this.setState({
                skillText: '',
                error: {
                    skillText: ''
                },
                valid: {
                    skillText: false
                }
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'skillText':
                valid.skillText = value.length > 0;
                error.skillText = valid.skillText ? "" : "Text is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }

    closeSkillsModal = () => {
        this.setState({
            isSkillsModal: false
        })
    }
    skillsSubmit = async (e) => {
        // try {
        e.preventDefault();
        const { skillText } = this.state;
        const { isEdit, data } = this.props;
        if (isEdit) {
            this.props.addSkillsSubmit({ id: data?.id, skillText });
        } else {
            this.props.addSkillsSubmit({ skillText });
        }

        // const formData = new FormData();
        // formData.append('skillText', skillText);

        // const response = await apiHelper('actor/addActorSkills', 'POST', formData, headers);
        // const res = response.data;
        // if (res.status) {
        //     toasterSuccess(res.message);
        // } else {
        //     toasterError(res.message);
        // }
        // } catch (e) {
        //     toasterError(e.message);
        // }
    }

    skillsSave = () => {
        const { skillText } = this.state;
        this.props.addSkillsSubmit({ isMore: true, skillText });
        this.setState({
            skillText: '',
            error: {
                skillText: ''
            },
            valid: {
                skillText: false
            }
        })
    }
    render() {
        // error
        const { skillText, valid } = this.state;

        const disabled = !valid.skillText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>{`${this.props.isEdit ? 'Update' : 'Add'} A Special Skill`}</h3>
                    </Modal.Header>
                    <form action="/" method="POST" onSubmit={this.skillsSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="text" name="skillText" className="form-control" value={skillText} onChange={this.inputHandler} placeholder="Text*" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="submit" className="btn btn-primary" disabled={disabled}>{this.props.isEdit ? 'Update' : 'Save & Close'}</button>

                        {!this.props.isEdit ? <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.skillsSave}>{'Save & Add More'}</button> : null}
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}
export default withRouter(props => <AddSkillsComponent {...props} />);