import React, { Component, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';

class TrainingComponent extends Component {

    _unmounted = false;

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            traniningArray: [],
        }
    }
    componentDidMount() {
        this._unmounted = true;
        this.getActorTraining(this.props.match.params.id);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getActorTraining = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/getActorTraining', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        traniningArray: data.trainingData
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { traniningArray } = this.state;
        return (
            <div style={{ marginBottom: '20px' }}>
                <div className="titl-wrp">
                    <h4 className="res-tit">Training</h4>
                </div>
                <div className="table-viwe">
                    <UlLi
                        data={traniningArray}
                    />
                </div>
            </div>
        );
    }
}

const UlLi = ({ data }) => {

    const [records, setRecords] = React.useState([]);
    React.useEffect(() => {
        if (records.length === 0) {
            setRecords(data)
        }
    }, [records, data])



    const renderCard = (record, index) => {
        return (
            <LICompo
                text={record.text} key={index}
            />
        );
    };

    return (<>
        <ul>{records.length > 0 ? records.map((record, i) => renderCard(record, i)) : <li></li>}</ul>
    </>);

}

const LICompo = ({ text }) => {

    return (<li>
        <span>{text}
        </span>
    </li>);
};


export default withRouter(props => <TrainingComponent {...props} />);