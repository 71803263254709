import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';

class EditNotesComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            noteText: '',
            error: {
                noteText: ''
            },
            valid: {
                noteText: false
            }
        }
    }

    componentDidMount() {
        const { data } = this.props;
        // console.log(data);
        this.setState({
            noteText: data.text,
            error: {
                noteText: ''
            },
            valid: {
                noteText: data.text ? true : false
            }
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'noteText':
                valid.noteText = value.length > 0;
                error.noteText = valid.noteText ? "" : "Note is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    noteSubmit = async () => {
        try {

            const { noteText } = this.state;
            const { data } = this.props;

            const formData = new FormData();
            formData.append('id', data.id);
            formData.append('noteText', noteText);

            const response = await apiHelper('company/notes/update', 'PUT', formData, this.headers);
            const res = response.data;

            if (res.status) {
                this.props.onHide();
                this.props.reloadNoteFun(true);
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    closeNoteModal = () => {
        this.setState({
            isNoteModal: false
        })
    }
    render() {
        // error
        const { noteText, valid } = this.state;
        const disabled = !valid.noteText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Notes on</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="text" name="noteText" className="form-control" value={noteText} onChange={this.inputHandler} placeholder="Add Notes here..." />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.noteSubmit}>Update</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    reloadNoteFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_NOTES_API, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(EditNotesComponent));