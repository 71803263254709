import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withRouter } from "react-router";
import { apiHelper } from "./../../../helper/api.helper";
import { toasterError } from "./../../../helper/toaster.helper";
import ServiceDirectoryViewModal from "./ServiceDirectoryViewModal";
import ServiceDirectoryEditModal from "./ServiceDirectoryEditModal";
import RejectServiceDirectoryModal from "./RejectServiceDirectoryModal";

class AdminServiceDirectoryComponent extends Component {
  headers = {
    Authorization: localStorage.getItem("token"),
  };
  _unmounted = false;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      actorStatus: null,
      isServiceDirectoryViewModal: false,
      serviceDirectoryViewData: null,
      rejectServiceDirectoryViewModal: false,
      rejectServiceDirectoryViewData: null,
      serviceDirectoryEditData: null,
      isServiceDirectoryEditModal: false,
    };
  }

  componentDidMount() {
    this._unmounted = true;
    this.getServiceDirectoryData();
  }

  openServiceDirectoryViewModal = (row) => {
    this.setState({
      serviceDirectoryViewData: row,
      isServiceDirectoryViewModal: true,
    });
  };
  closeServiceDirectoryViewModal = () => {
    this.setState({
      isServiceDirectoryViewModal: false,
      serviceDirectoryViewData: null,
    });
  };
  openServiceDirectoryEditModal = (row) => {
    this.setState({
      serviceDirectoryEditData: row,
      isServiceDirectoryEditModal: true,
    });
  };
  closeServiceDirectoryEditModal = () => {
    this.setState({
      isServiceDirectoryEditModal: false,
      serviceDirectoryEditData: null,
    });
  };
  openRejectServiceDirectoryViewModal = (row) => {
    this.setState({
      rejectServiceDirectoryViewData: row,
      rejectServiceDirectoryViewModal: true,
    });
  };
  closeRejectServiceDirectoryViewModal = () => {
    this.setState({
      rejectServiceDirectoryViewModal: false,
      rejectServiceDirectoryViewData: null,
    });
  };

  componentWillUnmount() {
    this._unmounted = false;
  }

  getServiceDirectoryData = async () => {
    try {
      const response = await apiHelper(
        "admin/service-directory/get",
        "GET",
        null,
        this.headers
      );
      let res = response.data;
      if (res.status) {
        if (this._unmounted) {
          this.setState({
            data: res.data,
          });
        }
      }
    } catch (e) {
      toasterError(e.message);
    }
  };

  actionFormat = (cell, row) => {
    return (
      <>
        <button
          type="button"
          onClick={() => this.openServiceDirectoryViewModal(row)}
          className="btn btn-primary"
          style={{ marginRight: "10px" }}
        >
          View
        </button>

        <button
          type="button"
          className="btn btn-danger"
          onClick={() => this.deleteServiceDirectory(row)}
          style={{ marginRight: "10px" }}
        >
          Delete
        </button>
        <button
          type="button"
          onClick={() => this.openServiceDirectoryEditModal(row)}
          className="btn btn-info"
        >
          Edit
        </button>
      </>
    );
  };
  verifiedStatus = (cell, row) => {
    const { data } = this.state;
    let status = "0";
    if (data.length > 0) {
      const filterArr = data.filter((info) => info.id === row.id);
      status = filterArr.length > 0 ? filterArr[0].status : row.status;
    } else {
      status = row.status;
    }
    return status === "1" ? (
      <button
        type="button"
        className="btn btn-success"
        disabled
        style={{ marginRight: "10px" }}
      >
        Approved
      </button>
    ) : // <button
    //   type="button"
    //   className="btn btn-warning"
    //   onClick={() => this.openRejectServiceDirectoryViewModal(row)}
    //   style={{ marginRight: "10px" }}
    // >
    //   Reject
    // </button>
    status === "2" ? (
      <button type="button" className="btn btn-danger" disabled>
        Rejected
      </button>
    ) : (
      <>
        <button
          type="button"
          className="btn btn-success"
          onClick={(e) => this.approveServiceDirectory(e, row)}
          style={{ marginRight: "10px" }}
        >
          Approve
        </button>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => this.openRejectServiceDirectoryViewModal(row)}
          style={{ marginRight: "10px" }}
        >
          Reject
        </button>
      </>
    );
  };

  deleteServiceDirectory = async (row) => {
    try {
      if (
        window.confirm("Are you sure want to delete this service directory?")
      ) {
        const formData = new FormData();
        formData.append("id", row.id);
        const response = await apiHelper(
          "admin/service-directory/delete",
          "POST",
          formData,
          this.headers
        );
        const res = response.data;
        if (res.status) {
          const darr = this.state.data.filter((info) => info.id !== row.id);
          this.setState({
            data: darr,
          });
        } else {
          toasterError(res.message);
        }
      }
    } catch (e) {
      toasterError(e.message);
    }
  };
  approveServiceDirectory = async (e, row) => {
    try {
      if (
        window.confirm("Are you sure want to approve this service directory?")
      ) {
        const formData = new FormData();
        formData.append("id", row.id);
        formData.append("firstName", row.firstName);
        formData.append("lastName", row.lastName);
        formData.append("email", row.email);
        const response = await apiHelper(
          "admin/service-directory/approve",
          "POST",
          formData,
          this.headers
        );
        const res = response.data;
        if (res.status) {
          window.location.reload();
        } else {
          toasterError(res.message);
        }
      }
    } catch (e) {
      toasterError(e.message);
    }
  };

  render() {
    const { SearchBar } = Search;
    const columns = [
      { dataField: "firstName", text: "First Name" },
      { dataField: "lastName", text: "Last Name" },
      { dataField: "email", text: "Email" },
      { dataField: "companyName", text: "Company Name" },
      {
        dataField: "action",
        text: "Verified Status",
        formatter: this.verifiedStatus,
      },
      { dataField: "action", text: "Action", formatter: this.actionFormat },
    ];

    return (
      <>
        <div className="main">
          <div className="main-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel">
                    <div className="panel-heading">
                      <h3 className="panel-title">Service Directory List</h3>
                    </div>
                    <div className="panel-body no-padding">
                      <ToolkitProvider
                        keyField="id"
                        data={this.state.data}
                        columns={columns}
                        search
                      >
                        {(props) => (
                          <div>
                            <div className="customSearch">
                              <SearchBar {...props.searchProps} />
                            </div>
                            <BootstrapTable
                              {...props.baseProps}
                              classes="table table-striped"
                              pagination={paginationFactory()}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        {this.state.isServiceDirectoryViewModal ? (
          <ServiceDirectoryViewModal
            show={this.state.isServiceDirectoryViewModal}
            onHide={this.closeServiceDirectoryViewModal}
            data={this.state.serviceDirectoryViewData}
          />
        ) : null}
        {this.state.isServiceDirectoryEditModal ? (
          <ServiceDirectoryEditModal
            show={this.state.isServiceDirectoryEditModal}
            onHide={this.closeServiceDirectoryEditModal}
            data={this.state.serviceDirectoryEditData}
          />
        ) : null}

        {this.state.rejectServiceDirectoryViewModal ? (
          <RejectServiceDirectoryModal
            show={this.state.rejectServiceDirectoryViewModal}
            onHide={this.closeRejectServiceDirectoryViewModal}
            data={this.state.rejectServiceDirectoryViewData}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter((props) => (
  <AdminServiceDirectoryComponent {...props} />
));
