import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import './assets/vendor/linearicons/style.css';
import './assets/css/main.css';
import './assets/css/demo.css';
import Header from './admin/components/common/header';
import Sidebar from './admin/components/common/sidebar';
import Footer from './admin/components/common/footer';
import { jwtDecode } from './helper/jwt.helper';

import AllRouters from './Routers/index';
import HomeHeader from './users/components/common/HomeHeader';
import HomeFooter from './users/components/common/HomeFooter';
import HeaderUser from './users/components/common/HeaderUser';
import FooterUser from './users/components/common/FooterUser';

class App extends Component {

  render() {

    const userToken = localStorage.getItem('userToken');
    const tokenData = jwtDecode(userToken);


    const token = localStorage.getItem('token');

    const { location: { pathname } } = this.props;
    const splitPath = pathname.split('/');

    if (userToken && !tokenData.userType.includes("theatre")) {
      if (splitPath[1] === 'search' || splitPath[2] === 'tags' || splitPath[1] === 'actors') {
        this.props.history.push('/profile');
      }
    }
    // console.log(splitPath);
    let isAdmin = false;

    if (splitPath[1] === 'admin') {
      isAdmin = true;
    }

    return (
      <div id="wrapper">
        {isAdmin && <><Header /> <Sidebar /></>}
        {userToken ?
          <HeaderUser />
          :
          <HomeHeader />
        }
        <AllRouters />
        {isAdmin && token && <Footer />}
        {userToken || isAdmin ?
          <FooterUser />
          :
          <HomeFooter />
        }
      </div>
    );
  }
}

export default withRouter(props => <App {...props} />);
