import React, { Component } from "react";
import { withRouter } from "react-router";
import "./CompanyRegisterComponent.css";
import ARLogo from "./../../../assets/img/AR_Logo.png";
import * as places from "places.js";
import { apiHelper } from "../../../helper/api.helper";
import {
  emailValidation,
  passwordValidation,
  confirmPassValidation,
} from "../../../helper/validation.helper";
import { toasterError, toasterSuccess } from "../../../helper/toaster.helper";
import Autocomplete from "react-google-autocomplete";

class CompanyRegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyType: "",
      cityState: "",
      city: "",
      state: "",
      einNumber: "",
      fName: "",
      lName: "",
      email: "",
      password: "",
      confirmPass: "",
      acceptTerms: false,

      error: {
        companyName: "",
        companyType: "",
        cityState: "",
        einNumber: "",
        fName: "",
        lName: "",
        email: "",
        password: "",
        confirmPass: "",
      },
      valid: {
        companyName: false,
        companyType: false,
        cityState: false,
        einNumber: false,
        fName: false,
        lName: false,
        email: false,
        password: false,
        confirmPass: false,
      },
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("userToken");
    if (token) {
      this.props.history.push("/profile");
      return true;
    }
    // const placeInstance = places({
    //     appId: "plL5W1957CCG",
    //     apiKey: "8f309db6542eab1fb7c18b83fcff357c",
    //     container: document.querySelector("#address-input")
    // });

    // placeInstance.configure({
    //     language: "en",
    //     type: this.props.type,
    //     countries: ["us"]
    // });

    // placeInstance.on("change", (e) => {
    //     const { name: city, administrative: state } = e.suggestion;
    //     this.setState({
    //         cityState: `${city}, ${state}`,
    //         city: city,
    //         state: state,
    //         error: {
    //             ...this.state.error,
    //             cityState: ''
    //         },
    //         valid: {
    //             ...this.state.valid,
    //             cityState: true
    //         }
    //     });
    // });
  }
  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.checkValidation(name, value)
    );
  };

  checkValidation = (name, value) => {
    let error = this.state.error;
    let valid = this.state.valid;

    switch (name) {
      case "companyName":
        valid.companyName = value.length > 0;
        error.companyName = valid.companyName ? "" : "Company name Required";
        break;
      case "companyType":
        valid.companyType = value.length > 0;
        error.companyType = valid.companyType ? "" : "Company type Required";
        break;
      case "cityState":
        valid.cityState = value.length > 0;
        error.cityState = valid.cityState ? "" : "City,State Required";
        break;
      case "einNumber":
        valid.einNumber = value.length > 0;
        error.einNumber = valid.einNumber ? "" : "EIN number Required";
        break;
      case "fName":
        valid.fName = value.length > 0;
        error.fName = valid.fName ? "" : "First Name Required";
        break;
      case "lName":
        valid.lName = value.length > 0;
        error.lName = valid.lName ? "" : "Last Name Required";
        break;

      case "email":
        const emailValid = emailValidation(value);
        valid.email = emailValid.status;
        error.email = emailValid.status ? "" : emailValid.message;
        break;
      case "password":
        const passValid = passwordValidation(value);
        valid.password = passValid.status;
        error.password = valid.password ? "" : passValid.message;
        break;
      case "confirmPass":
        const confValid = confirmPassValidation(this.state.password, value);
        valid.confirmPass = confValid.status;
        error.confirmPass = valid.confirmPass ? "" : confValid.message;
        break;
      default:
        break;
    }
    this.setState({
      error,
      valid,
    });
  };
  termsHandler = (e) => {
    const checked = e.target.checked;
    this.setState({
      acceptTerms: checked,
    });
  };
  submitFun = async (e) => {
    try {
      e.preventDefault();
      const { valid, acceptTerms } = this.state;
      if (
        !valid.companyName ||
        !valid.companyType ||
        !valid.einNumber ||
        !valid.fName ||
        !valid.lName ||
        !valid.cityState ||
        !valid.email ||
        !valid.password ||
        !valid.confirmPass
      ) {
        toasterError("All Field required.");
        return;
      }

      if (!acceptTerms) {
        toasterError("please select terms and coditions");
        return;
      }

      const formData = new FormData();
      formData.append("companyName", this.state.companyName);
      formData.append("companyType", this.state.companyType);
      formData.append("einNumber", this.state.einNumber);
      formData.append("firstName", this.state.fName);
      formData.append("lastName", this.state.lName);
      formData.append("city", this.state.city);
      formData.append("state", this.state.state);
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);

      const response = await apiHelper(
        "company/register",
        "POST",
        formData,
        null
      );
      const res = response.data;

      if (res.status) {
        localStorage.setItem("userToken", res.data);
        //toasterSuccess(res.message);
        this.props.history.push("/profile");
      } else {
        toasterError(res.message);
      }
    } catch (e) {
      toasterError(e.message);
    }
  };

  noRedirect = (e) => {
    e.preventDefault();
  };
  render() {
    const ranges = [
      {
        value: "nonprofit",
        label: "Non-Profit Theatre",
      },
      {
        value: "forProfit",
        label: "For-Profit Theatre",
      },
      {
        value: "filmAndTelevision",
        label: "TV & Film",
      },
      {
        value: "talentAgency",
        label: "Talent Agency",
      },
    ];
    // companyType,
    const {
      companyName,
      cityState,
      einNumber,
      fName,
      lName,
      email,
      password,
      confirmPass,
      valid,
      acceptTerms,
    } = this.state;
    let disabled = false;
    if (
      !valid.companyName ||
      !valid.companyType ||
      !valid.einNumber ||
      !valid.fName ||
      !valid.lName ||
      !valid.cityState ||
      !valid.email ||
      !valid.password ||
      !valid.confirmPass ||
      !acceptTerms
    ) {
      disabled = true;
    }

    let btnClass =
      "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary";
    if (disabled) {
      btnClass += " Mui-disabled Mui-disabled";
    }
    return (
      <div id="fuse-layout">
        <div className="sc-Axmtr hvJMgY">
          <div className="main12">
            <div
              className="jss12 mt-0"
              style={{
                marginBottom: "64px",
                height: "100%",
                minHeight: "100vh",
              }}
            >
              <div className="sc-AxjAm bcMPWx jss217">
                <div className="sc-AxiKw eSbheu">
                  {/* <img src={ARLogo} alt="logo" /> */}
                  <div className="heading-txt">
                    <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-colorInherit">
                      Welcome to Audition Revolution!
                    </h3>
                  </div>
                  <div>
                    <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorInherit">
                      Sign In Or Create New Account!
                    </h6>
                  </div>
                </div>
                <div className="MuiPaper-root MuiCard-root sc-AxirZ bJCmFu MuiPaper-elevation1">
                  <div className="MuiCardContent-root">
                    <h6 className="MuiTypography-root MuiTypography-h6">
                      CREATE AN ACCOUNT
                    </h6>
                    <form
                      action="#"
                      name="registerForm"
                      onSubmit={this.submitFun}
                    >
                      <h1>Company Info</h1>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Company Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="companyName"
                            required=""
                            type="text"
                            className="form-control"
                            value={companyName}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Company<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <select
                            name="companyType"
                            onChange={this.inputHandler}
                            className="form-control"
                          >
                            <option value="">Company Type</option>
                            {ranges.length > 0 ? (
                              ranges.map((range, i) => (
                                <option key={i} value={range.value}>
                                  {range.label}
                                </option>
                              ))
                            ) : (
                              <option>Add your info here!</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          City, State
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                          {/* <input id="address-input" name="cityState" placeholder="" required="" onChange={this.inputHandler} type="city" className="form-control" value={cityState} style={{ position: 'relative', verticalAlign: 'top' }} /> */}
                          <Autocomplete
                            style={{
                              position: "relative",
                              verticalAlign: "top",
                            }}
                            name="cityState"
                            required=""
                            value={cityState}
                            type="city"
                            className="form-control"
                            id="address-input"
                            placeholder=""
                            onChange={this.inputHandler}
                            apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                            onPlaceSelected={(place) => {
                              var formatted_address =
                                place.formatted_address.split(",");
                              this.setState({
                                cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                city: formatted_address[0],
                                state: formatted_address[1],
                                error: {
                                  ...this.state.error,
                                  cityState: "",
                                },
                                valid: {
                                  ...this.state.valid,
                                  cityState: true,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          EIN Number<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="einNumber"
                            required=""
                            type="text"
                            onChange={this.inputHandler}
                            className="form-control"
                            value={einNumber}
                          />
                        </div>
                      </div>
                      <h1>User Info</h1>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          First Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="fName"
                            required=""
                            onChange={this.inputHandler}
                            type="text"
                            className="form-control"
                            value={fName}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Last Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="lName"
                            required=""
                            onChange={this.inputHandler}
                            type="text"
                            className="form-control"
                            value={lName}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Email<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="email"
                            required=""
                            type="email"
                            onChange={this.inputHandler}
                            className="form-control"
                            value={email}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Password<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="password"
                            required=""
                            type="password"
                            onChange={this.inputHandler}
                            className="form-control"
                            value={password}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Password (Confirm)<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="confirmPass"
                            required=""
                            onChange={this.inputHandler}
                            type="password"
                            className="form-control"
                            value={confirmPass}
                          />
                        </div>
                      </div>
                      <div className="MuiFormControl-root">
                        <label className="MuiFormControlLabel-root">
                          <span
                            className="MuiButtonBase-root MuiIconButton-root jss337 MuiCheckbox-root MuiCheckbox-colorSecondary MuiIconButton-colorSecondary"
                            aria-disabled="false"
                          >
                            <span className="MuiIconButton-label custom-checkBox">
                              <input
                                className="jss340 cus-check"
                                id="termsAndConditions"
                                name="acceptTermsConditions"
                                type="checkbox"
                                data-indeterminate="false"
                                onChange={this.termsHandler}
                              />
                              <span className="checkmark"></span>
                            </span>
                          </span>
                          <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                            <span>
                              I read and accept{" "}
                              <a href="/#" onClick={this.noRedirect}>
                                terms and conditions
                              </a>
                            </span>
                          </span>
                        </label>
                      </div>
                      <button
                        className={btnClass}
                        tabIndex="-1"
                        type="submit"
                        id="createAccount"
                        aria-label="Register"
                        disabled={disabled}
                      >
                        <span className="MuiButton-label">
                          CREATE AN ACCOUNT
                        </span>
                      </button>
                    </form>
                    <div className="no-account">
                      <span className="font-medium">
                        Already have an account?
                      </span>
                      <a className="font-medium" href="/login">
                        Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="jss11">
                            <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters" style={{ padding: '0px' }}>
                                <div className="sc-AxheI eXzlnr">
                                    <p className="MuiTypography-root MuiTypography-body2">Audition Revolution.v2 Beta</p>
                                    <p className="MuiTypography-root MuiTypography-body2">Contact Support: support@auditionrevolution.com</p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((props) => <CompanyRegisterComponent {...props} />);
