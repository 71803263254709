import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './PrivateRoute';
import AdminPrivateRoute from './AdminPrivateRoute';

// users components
import UserLoginComponent from './../users/components/UserLoginComponent';
import CompanyRegisterComponent from './../users/components/CompanyRegisterComponent';
import ActorRegisterComponent from './../users/components/ActorRegisterComponent';
import SettingsComponent from './../users/components/SettingsComponent';
// import ProfileComponent from './../users/components/ProfileComponent';
import UserPasswordReset from './../users/components/UserPasswordReset';
import ResetUserPasswordComponent from './../users/components/ResetUserPasswordComponent';
import VerifyUserComponent from './../users/components/ProfileComponent/VerifyUserComponent';
import SearchActorComponent from '../users/components/CompanyComponent/SearchActorComponent';
import CompanyProfileTagComponent from '../users/components/CompanyComponent/CompanyProfileTagComponent';
import ActorProfileDetailComponent from '../users/components/CompanyActorProfileComponent';
// admin componets
import AdminLoginComponent from './../admin/components/AdminLoginComponent';
import DashboardComponent from './../admin/components/DashboardComponent';
import CompaniesComponent from './../admin/components/CompaniesComponent';
import ActorsComponent from './../admin/components/ActorsComponent';
import ActorDetailsComponent from './../admin/components/ActorsComponent/ActorDetails';
import CompanyDetailsComponent from './../admin/components/CompaniesComponent/CompanyDetails';
import AdminUsersComponent from './../admin/components/AdminUsersComponent';
import AdminChangePasswordComponent from './../admin/components/ChangePasswordComponent';
import CommonComponent from '../users/components/CommonComponent';
import MyActorComponent from '../users/components/MyActorComponent';
import CompanySingleTag from '../users/components/CompanyComponent/CompanySingleTag';
import HomeLandingComponent from '../users/components/HomeLanding/HomeLandingComponent';
import ServiceDirectoryComponent from '../users/components/ServiceDirectoryComponent';
import AdminServiceDirectoryComponent from '../admin/components/ServiceDirectoryComponent';
import SearchServiceDirectoryComponent from '../users/components/SearchServiceDirectoryComponent';

class AllRouters extends Component {
    render() {
        const userToken = localStorage.getItem('userToken');
        const { location: { pathname, search } } = this.props;

        if (pathname === '/profile/tag') {
            if (!search) {
                return <Redirect to="/profile/tags" />
            }
        }

        return (
            <>
                {/* // <div id="wrapper">
                    //     {token && <Header />} */}
                <Switch>
                    {/* <Route exact path="/">
                        {userToken ? <Redirect to="/profile" /> : <Redirect to="/" />}
                    </Route> */}
                    {/* <Route path="/admin" exact component={AdminLoginComponent} /> */}
                    <Route path="/" exact component={HomeLandingComponent} />
                    <Route path="/admin" exact>
                        {localStorage.getItem('token') ? <Redirect to="/admin/dashboard" /> : <AdminLoginComponent />}
                    </Route>
                    <Route path="/login" exact component={UserLoginComponent} />
                    <Route path="/register" exact component={ActorRegisterComponent} />
                    <Route path="/register-company" exact component={CompanyRegisterComponent} />
                    <Route path="/service-directory" exact component={ServiceDirectoryComponent} />

                    <Route path="/passwordReset/:passwordToken" exact component={ResetUserPasswordComponent} />
                    <Route path="/passwordReset" exact component={UserPasswordReset} />
                    <Route path="/auth/verify/:id" exact component={VerifyUserComponent} />
                    <PrivateRoute path="/profile" exact component={CommonComponent} />

                    <PrivateRoute path="/search/actor" exact component={SearchActorComponent} />
                    <PrivateRoute path="/profile/service-directory" exact component={SearchServiceDirectoryComponent} />

                    <PrivateRoute path="/profile/tags" exact component={CompanyProfileTagComponent} />


                    <PrivateRoute path="/profile/tag" exact component={CompanySingleTag} />
                    <PrivateRoute path="/profile/settings" exact component={SettingsComponent} />
                    <PrivateRoute path="/profile/:id" exact component={ActorProfileDetailComponent} />
                    <PrivateRoute path="/actors" exact component={MyActorComponent} />

                    <AdminPrivateRoute path="/admin/dashboard" exact component={DashboardComponent} />
                    <AdminPrivateRoute path="/admin/companies" exact component={CompaniesComponent} />
                    <AdminPrivateRoute path="/admin/actors" exact component={ActorsComponent} />
                    <AdminPrivateRoute path="/admin/users" exact component={AdminUsersComponent} />
                    <AdminPrivateRoute path="/admin/actor/details/:id" exact component={ActorDetailsComponent} />
                    <AdminPrivateRoute path="/admin/company/details/:id" exact component={CompanyDetailsComponent} />
                    <AdminPrivateRoute path="/admin/changepassword" exact component={AdminChangePasswordComponent} />
                    <AdminPrivateRoute path="/admin/servicedirectory" exact component={AdminServiceDirectoryComponent} />
                    {/* <PrivateRoute path="/profile" exact component={ProfileComponent} /> */}
                </Switch>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                />
            </>
            // </div>
        );
    }
}

export default withRouter(AllRouters);