import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from './../../../helper/api.helper';
import { toasterSuccess, toasterError } from './../../../helper/toaster.helper';
import SampleCSV from './../../../assets/sample/upload_company_actors_sample.csv';
// import { jwtDecode } from './../../../helper/jwt.helper';
 
class UploadActor extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            filesArr: null

        }
    }

    uploadActorSubmit = async () => {
        try {

            const file = this.state.filesArr;
            let formData = new FormData();

            formData.append('actorFile', file);

            const response = await apiHelper('company/uploadActor', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                //toasterSuccess(res.message);
                window.location.reload();

            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    uploadFiles = (e) => {
        const file = e.target.files[0];
        this.setState({
            filesArr: file
        })
    }
    render() {
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Upload Actor CSV</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-wrap">
                            <div className="file-div">
                                <input id="uploadFil" type="file" name="file" onChange={this.uploadFiles} />
                            </div>
                        </div>
                        <div style={{width:'100%', marginTop:'20px'}}>
                            <a href={SampleCSV} download>Download Sample CSV</a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={this.uploadActorSubmit}>Submit</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withRouter(props => <UploadActor {...props} />);