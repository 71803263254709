import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { jwtDecode } from '../../../helper/jwt.helper';
import ChangePasswordComponent from './ChangePasswordComponent';
import UpdateCompanyProfileComponent from './UpdateCompanyProfileComponent';
class SettingsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userType: null
        }
    }
    componentDidMount() {
        const userToken = localStorage.getItem('userToken');
        const tokenData = jwtDecode(userToken);

        if (userToken && tokenData) {
            this.setState({
                userType: tokenData.userType,
            })
        }
    }

    render() {
        if (this.state.userType && this.state.userType.includes("theatre")) {
            return (

                <section className="bg_steps">
                    <div className="container">
                        <div className="row">
                            <UpdateCompanyProfileComponent />
                            <ChangePasswordComponent />
                        </div>
                    </div>
                </section>
            );
        } else {
            return (

                <section className="bg_steps">
                    <div className="container">
                        <div className="row">
                            <ChangePasswordComponent type="actor" />
                        </div>
                    </div>
                </section>
            );
        }
    }
}

export default withRouter(props => <SettingsComponent {...props} />);