import React, { Component } from "react";
import { withRouter } from "react-router";
import Pagination from "react-bootstrap/Pagination";
import * as ACTION_TYPES from "./../../../redux/actions/type";
import { connect } from "react-redux";
import { apiHelper } from "../../../helper/api.helper";
import { toasterError } from "../../../helper/toaster.helper";
import ReportModal from "./ReportModal";
import CreatableSelect from "react-select/creatable";
import { servicesHelper } from "../../../helper/service.helper";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
class SearchServiceDirectoryComponent extends Component {
  _unmounted = false;
  headers = {
    Authorization: localStorage.getItem("userToken"),
  };
  constructor(props) {
    super(props);

    const { totalRecords = null, pageLimit = 10, pageNeighbours = 0 } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 10;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = {
      isSearchLoader: false,
      currentPage: 1,
      perPage: 10,
      serviceDirectoryData: [],
      totalServiceDirectory: 0,
      searchText: null,
      searchValue: null,
      isReportModal: false,
      reportData: null,
      services: "",
      noRecordMsg: "Search to find services near you!",
    };
  }
  componentDidMount() {
    const {
      updateSearchFunction,
      searchValueFunction,
      searchDataValueFunction,
      pageUpdateFunction,
      totalCountUpdateFunction,
    } = this.props;
    updateSearchFunction([]);
    searchValueFunction("");
    searchDataValueFunction("");
    pageUpdateFunction(0);
    totalCountUpdateFunction(0);
  }
  submitSearch = (e) => {
    e.preventDefault();
    this.setState({
      isSearchLoader: true,
    });

    const {
      updateSearchFunction,
      searchValueFunction,
      searchDataValueFunction,
      pageUpdateFunction,
      totalCountUpdateFunction,
    } = this.props;
    updateSearchFunction(this.state.serviceDirectoryData);
    searchValueFunction(this.state.searchText);
    searchDataValueFunction(this.state.searchValue);
    pageUpdateFunction(this.state.currentPage);
    totalCountUpdateFunction(this.state.totalServiceDirectory);

    this.getServiceDirectoryData(1, this.state.searchText,this.state.searchValue);
  };
  getServiceDirectoryData = async (page, searchText,searchValue) => {
    const { perPage } = this.state;

    const startIndex = page * perPage - perPage;
    const endIndex = startIndex + perPage;

    const formData = new FormData();
    formData.append("searchText", searchText);
    formData.append("searchValue", searchValue);

    const response = await apiHelper(
      "service-directory/search",
      "POST",
      formData,
      this.headers
    );
    const res = response.data;
    if (res.status) {
      const data = res.data;
      this.totalRecords = data.length;
      this.setState({
        serviceDirectoryData: data.slice(startIndex, endIndex),
        totalServiceDirectory: data.length,
        isSearchLoader: false,
      });
      this.props.updateSearchFunction(data.slice(startIndex, endIndex));
    } else {
      // toasterError(res.message);
      this.setState({
        serviceDirectoryData: [],
        totalServiceDirectory: 0,
        isSearchLoader: false,
        noRecordMsg:"No services were found that match your selections. Please try your search again."
      });
      this.props.updateSearchFunction([]);
    }
  };
  openReportModal = (row) => {
    this.setState({
      isReportModal: true,
      reportData: row,
    });
  };
  closeReportModal = () => {
    this.setState({
      isReportModal: false,
      reportData: null,
    });
  };
  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  selectHandler = (optionSelected) => {
    this.setState(
      {
        services: optionSelected.value,
        searchValue: optionSelected.value,
      }
      );
  };

  fetchPageNumbers = () => {
    const totalPages = Math.ceil(this.totalRecords / this.pageLimit);
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };
  phoneNumber = (phoneNumber) => {
    const phoneString = phoneNumber.toString().split("");
    const areaCode = phoneString.slice(0, 3).join("");
    const phone1 = phoneString.slice(3, 6).join("");
    const phone2 = phoneString.slice(6, 10).join("");
    return `(${areaCode}) ${phone1}-${phone2}`;
  };
  paginationPage = (page) => {
    this.setState({
      currentPage: page,
    });
    this.getServiceDirectoryData(page, this.state.searchText,this.state.searchValue);
  };
  renderPagination = (page, index) => {
    if (page === LEFT_PAGE) {
      return (
        <Pagination.Prev key={index} onClick={this.prevPage}>
          Prev
        </Pagination.Prev>
      );
    }

    if (page === RIGHT_PAGE) {
      return (
        <Pagination.Next key={index} onClick={this.nextPage}>
          Next
        </Pagination.Next>
      );
    }

    return (
      <Pagination.Item
        key={index}
        active={page === this.state.currentPage}
        onClick={() => this.paginationPage(page)}
      >
        {page}
      </Pagination.Item>
    );
  };

  render() {
    const { isSearchLoader, serviceDirectoryData, noRecordMsg } = this.state;
    const pages = this.fetchPageNumbers();
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12"></div>
            <div className="search-wrap">
              <h2 className="search_title">Service Directory Search</h2>
              <div className="card-wrap">
                <form action="/" method="post" onSubmit={this.submitSearch}>
                <div className="search-block">

                <div className="input_group">
                          <CreatableSelect
                            // isMulti
                            name="searchValue"
                            placeholder="I am looking for"
                            onChange={this.selectHandler}
                            options={servicesHelper}
                            className="search-input"
                            isValidNewOption={() => false}
                          />
                        </div>
                        </div>
                  <div className="search-block" style={{padding: "8px"}}>
                    <div className="input_group">
                      <input
                        type="text"
                        name="searchText"
                        value={this.state.searchText}
                        onChange={this.inputHandler}
                        placeholder="Search by Keyword"
                        className="search-input"
                      />
                      <span className="search_icon">
                        <i className="fa fa-search"></i>
                      </span>
                 
                    </div>
                    {/* <hr className="horz-hr" /> */}
                    
                    <button type="submit" className="btn btn-primary" >
                      Search
                    </button>
                  </div>
                </form>
              </div>

              <div className="actor-wrap">
                <div className="title-wrap">
                  <p className="tite">Found Search Directory</p>
                  <p className="lab-badg">
                    {this.state.totalServiceDirectory} Service Directory
                  </p>
                </div>
                {isSearchLoader ? (
                  <div className="loader_wrap">
                    <div className="loader_box"></div>
                  </div>
                ) : (
                  <ul className="actor_list">
                    {this.state.serviceDirectoryData.length > 0 ? (
                      this.state.serviceDirectoryData.map(
                        (item, i) =>
                          item && (
                            <li key={i}>
                              <div className="actor-list-details">
                                <div className="contents">
                                  <h6 className="actor-title">
                                  {item.companyName && item.companyName}
                                  </h6>
                                  <div className="visible-mob">
                                    <p className="email-field">
                                      Email:{" "}
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </p>
                                  </div>
                                  <div className="visible-mob">
                                    <p className="email-field">
                                      Email:{" "}
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </p>
                                    {item.phone ? (
                                      <p>
                                        Phone: {this.phoneNumber(item.phone)}
                                      </p>
                                    ) : null}
                                    <p className="email-field">
                                      Website:{" "}
                                      <a
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        href={`//${item.website}`}
                                      >
                                        {item.website}
                                      </a>
                                    </p>
                                  </div>
                                  <p className="address-actor">
                                    {item.city && item.city},{" "}
                                    {item.state && item.state}
                                  </p>
                                  <p className="address-actor">
                                    {/* {item.companyName && "Company: "+item.companyName} */}
                                    {item.firstName && "Contact: "+item.firstName}{" "}
                                    {item.lastName && item.lastName}{" "}
                                  </p>
                                  <p className="address-actor" style={{display:"flex", flexWrap: "wrap", alignItems:"center"}}>
                                    {item.services && item.services.length > 0
                                      ? "Services :"
                                      : null}
                                    {item.services && item.services.length > 0
                                      ? item.services.map((info, index) => (
                                          <span
                                            key={index}
                                            className="actor-tag"
                                          >
                                            {" "}
                                            {info.service}
                                          </span>
                                        ))
                                      : null}
                                  </p>
                                  <p className="address-actor">
                                    About: {item.about}
                                  </p>
                                </div>
                              </div>
                              <div className="contact-info hide-mob">
                                <p>
                                  Email:{" "}
                                  <a href={`mailto:${item.email}`}>
                                    {item.email}
                                  </a>
                                </p>
                                {item.phone ? (
                                  <p>Phone: {this.phoneNumber(item.phone)}</p>
                                ) : null}
                                <p>
                                  Website:{" "}
                                  <a
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    href={`//${item.website}`}
                                  >
                                    {item.website}
                                  </a>
                                </p>
                                <div className="group-btn">
                                  <button
                                    type="button"
                                    className="btn_default2"
                                    onClick={() => this.openReportModal(item)}
                                    style={{textTransform:"capitalize"}}
                                  >
                                    Report This Service
                                  </button>
                                </div>
                              </div>
                            </li>
                          )
                      )
                    ) : (
                      <li className="no-record">{noRecordMsg}</li>
                    )}
                  </ul>
                )}
                {this.state.serviceDirectoryData.length > 0 ? (
                  <div className="page-wrap">
                    {!this.totalRecords || this.totalPages === 1 ? null : (
                      <Pagination>
                        {/* {this.state.currentPage > 1 ? <Pagination.Prev onClick={this.prevPage} /> : null} */}
                        {pages.map((page, index) =>
                          this.renderPagination(page, index)
                        )}
                        {/* {this.state.currentPage < totalPages ? <Pagination.Next onClick={this.nextPage} /> : null} */}
                      </Pagination>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {this.state.isReportModal ? (
          <ReportModal
            show={this.state.isReportModal}
            onHide={this.closeReportModal}
            data={this.state.reportData}
          />
        ) : null}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  searchServiceDirectoryData: state.company.searchServiceDirectoryData,
  searchServiceDirectoryValue: state.company.searchServiceDirectoryValue,
  currentServiceDirectoryPage: state.company.currentServiceDirectoryPage,
  totalServiceDirectoryCount: state.company.totalServiceDirectoryCount,
});

const mapDispatchToProps = (dispatch) => ({
  updateSearchFunction: (data) =>
    dispatch({
      type: ACTION_TYPES.UPDATE_SERVICE_DIRECTORY_SEARCH_DATA,
      payload: data,
    }),
  searchValueFunction: (search) =>
    dispatch({
      type: ACTION_TYPES.UPDATE_SERVICE_DIRECTORY_SEARCH_VALUE,
      payload: search,
    }),
    searchDataValueFunction: (search) =>
    dispatch({
      type: ACTION_TYPES.UPDATE_SERVICE_DIRECTORY_SEARCH_VALUE,
      payload: search,
    }),
  pageUpdateFunction: (page) =>
    dispatch({
      type: ACTION_TYPES.UPDATE_SERVICE_DIRECTORY_SEARCH_CURRENTPAGE,
      payload: page,
    }),
  totalCountUpdateFunction: (count) =>
    dispatch({
      type: ACTION_TYPES.TOTAL_SERVICE_DIRECTORY_COUNT,
      payload: count,
    }),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchServiceDirectoryComponent)
);
