import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CompanyProfileComponent from './CompanyProfileComponent';
import VerifyComponent from './../ProfileComponent/VerifyComponent';
import { jwtDecode } from '../../../helper/jwt.helper';
import './CompanyComponent.css';

class CompanyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
        }
    }

    componentDidMount() {
        const userToken = localStorage.getItem('userToken');
        const tokenData = jwtDecode(userToken);

        if (userToken && tokenData) {
            this.setState({
                status: Number(tokenData.status)
            })
        }
    }
    render() {
        return (
            <>
                {this.state.status === 0 || this.state.status === 3 ?
                    <VerifyComponent type="theatre" status={this.state.status} />
                    :
                    <CompanyProfileComponent />
                }

            </>
        );
    }
}

export default withRouter(props => <CompanyComponent {...props} />);