import React, { Component } from 'react';
import { withRouter } from 'react-router';
import CompanyTagsComponent from './../CompanyComponent/CompanyTagsComponent';

class CompanyProfileTagComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{marginBottom:'58px'}}>
                            <CompanyTagsComponent />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <CompanyProfileTagComponent {...props} />);