import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from '../../../helper/api.helper';
import CreatableSelect from 'react-select/creatable';
import { jwtDecode } from '../../../helper/jwt.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';

class AddActorTag extends Component {

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            tagText: null,
            tagArray: [],
            tagActorArray: []
        }
    }

    componentDidMount() {
        const selectedTag = localStorage.getItem('actor_tagSelected')
        if(selectedTag){
            this.setState({
                tagText:JSON.parse(selectedTag)
            })
        }
        this.getActorTags();
        this.getCompanyTags();
    }

    getCompanyTags = async () => {
        const response = await apiHelper('company/getCompanyTags', 'GET', null, this.headers);
        const res = response.data;

        if (res.status) {
            const data = jwtDecode(res.data);
            this.setState({
                tagArray: data.map(info => ({ value: info.tagName, label: info.tagName }))
            })
        }
    }
    getActorTags = async () => {
        const response = await apiHelper(`company/getActorTags/${this.props.id}`, 'GET', null, this.headers);
        const res = response.data;

        if (res.status) {
            const data = jwtDecode(res.data);
            console.log(data);
            this.setState({
                tagActorArray: data.tagsData
            })
        }
    }

    tagSubmit = async (e) => {
        // console.log('submit');
        try {
            e.preventDefault();
            const formData = new FormData();
            if(this.state && this.state.tagText && this.state.tagText.value){
                formData.append('text', this.state.tagText.value);
                formData.append('forId', this.props.id);
                const response = await apiHelper('company/addActorTag', 'POST', formData, this.headers);
                const res = response.data;
    
                if (res.status) {
                    this.props.reloadTagsFun(true);
                    this.getActorTags();
                    this.getCompanyTags();
                    localStorage.removeItem('actor_tagSelected')
                    this.setState({
                        tagText: null
                    })
                    this.props.onHide();
                    //toasterSuccess(res.message);
                } else {
                    toasterError(res.message);
                }
            }else{
                this.props.reloadTagsFun(true);
                this.getActorTags();
                this.getCompanyTags();

            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    closeTagModal = () => {
        this.setState({
            isTagModal: false
        })
    }

    selectHandler = (optionSelected) => {
        localStorage.setItem('actor_tagSelected', JSON.stringify(optionSelected))
        this.setState({
            tagText: optionSelected
        })
    }

    deteleActorTags = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/deleteTagActor', 'POST', formData, this.headers);
            const res = response.data;
            this.getCompanyTags();
            if (res.status) {
                const { tagActorArray } = this.state;
                const updatedTagActorArray = tagActorArray.filter(info => info.id !== id);
                this.setState({
                    tagActorArray: updatedTagActorArray
                })
                this.props.reloadTagsFun(true);
                //toasterSuccess(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {
        const { tagText, tagArray, tagActorArray } = this.state;
        // const disabled = !valid.tagText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Tag for</h3>
                    </Modal.Header>
                    <form action="/" method="POST" onSubmit={this.tagSubmit}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Tags are private and are not shared with actor or other users</label>
                                    <div className="form-group">
                                        <CreatableSelect
                                            isClearable
                                            onChange={this.selectHandler}
                                            options={tagArray}
                                            value={tagText}
                                        />
                                    </div>
                                    <ul className="acto-li">
                                        {tagActorArray.length > 0 ? tagActorArray.map((info, index) =>
                                            <li key={index}>
                                                <span>{info.tag}</span>
                                                <button className="btn-dele" onClick={() => this.deteleActorTags(info.id)}><i className="fa fa-times"></i></button>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={!tagText}>Apply Tag</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    reloadTagsFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_TAG_API, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(AddActorTag));