import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { passwordValidation, confirmPassValidation } from '../../../helper/validation.helper';
class ChangePasswordComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            error: {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            },
            valid: {
                currentPassword: false,
                newPassword: false,
                confirmNewPassword: false
            }
        }
    }
    onSubmitChangePassword = async (e) => {
        e.preventDefault();
        const { currentPassword, newPassword, confirmNewPassword, valid } = this.state;
        const validField = valid.currentPassword || valid.newPassword || valid.confirmNewPassword

        if (validField) {
            const formData = new FormData();
            formData.append('currentPassword', currentPassword);
            formData.append('newPassword', newPassword);
            formData.append('confirmNewPassword', confirmNewPassword);

            const response = await apiHelper('user/changePassword', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                    error: {
                        currentPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    },
                    valid: {
                        currentPassword: false,
                        newPassword: false,
                        confirmNewPassword: false
                    }
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } else {
            toasterError("All fields is required.");
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }
    checkValidation = (name, value) => {
        let valid = this.state.valid;
        let error = this.state.error;

        switch (name) {
            case 'currentPassword':
                valid.currentPassword = value.length > 0;
                break;
            case 'newPassword':
                const passValid = passwordValidation(value);
                valid.newPassword = passValid.status;
                error.newPassword = valid.password ? "" : passValid.message;
                break;
            case 'confirmNewPassword':
                const confValid = confirmPassValidation(this.state.newPassword, value);
                valid.confirmNewPassword = confValid.status;
                error.confirmNewPassword = valid.confirmNewPassword ? "" : confValid.message;
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    render() {
        const { currentPassword, newPassword, confirmNewPassword, valid, error } = this.state;
        const disabled = !valid.currentPassword || !valid.newPassword || !valid.confirmNewPassword
        let centerClass = "col-sm-6";
        if(this.props.type && this.props.type === "actor"){
            centerClass += " col-sm-offset-3";
        }
        return (
            <div className={centerClass}>
                <div className="steps_wrap">
                    <div className="step_conetnt" style={{ paddingTop: '0px' }}>
                        <div className="form_erap">
                            <h2 style={{ margin: '0 0 30px', textAlign: 'center' }}>Change Password</h2>

                            <div className="form-group">
                                <input type="password" name="currentPassword" className="form-control" value={currentPassword} onChange={this.inputHandler} placeholder="Current Password" />
                                {error.currentPassword !== "" ? <p className='error-text'>{error.currentPassword}</p> : null}
                            </div>
                            <div className="form-group">
                                <input type="password" name="newPassword" className="form-control" value={newPassword} onChange={this.inputHandler} placeholder="New Password" />
                                {error.newPassword !== "" ? <p className='error-text'>{error.newPassword}</p> : null}
                            </div>
                            <div className="form-group">
                                <input type="password" name="confirmNewPassword" className="form-control" value={confirmNewPassword} onChange={this.inputHandler} placeholder="Confirm New Password" />
                                {error.confirmNewPassword !== "" ? <p className='error-text'>{error.confirmNewPassword}</p> : null}
                            </div>
                        </div>
                    </div>
                    <div className="btn-gr">
                        <button type="button" aria-label="Next" disabled={disabled} onClick={this.onSubmitChangePassword} className="btn btn-primary">Change password</button>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(props => <ChangePasswordComponent {...props} />);