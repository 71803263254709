import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';

class ProfilePicComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }

    // profileImg = localStorage.getItem('profileImg');

    constructor(props) {
        super(props);
        this.state = {
            isDelete: true,
            profileImg:props.userImg
        }
    }
    componentDidMount() {
        if (this.props.userImg !== '') {
            this.setState({
                isDelete: false,
                profileImg:this.props.userImg
            })
        }
    }

    uploadImg = async (e) => {
        try {
            const file = e.target.files[0];
            let formData = new FormData();

            formData.append('profilePic', file);
            const response = await apiHelper('actor/savestep3', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                // console.log(decode);
                this.props.updateUserImg(decode.imgPic);
                // localStorage.setItem('profileImg', decode.imgPic)
                this.setState({
                    isDelete: false,
                    profileImg:decode.imgPic
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    deleteProfilePic = async () => {
        try {

            const response = await apiHelper('actor/deleteProfilePic', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                // localStorage.removeItem('profileImg');
                this.props.updateUserImg('');
                this.setState({
                    isDelete: true
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    render() {

        const { isDelete, profileImg } = this.state;

        return (
            <>
                {!isDelete ?
                    <div className="profile-up">
                        <h5>Free Accounts Currently Support A Maximum of 1 Image</h5>
                        <p>Unlimited Image Upload Available Soon</p>
                        <div className="image-div">
                            <img src={profileImg} alt="profile" />
                        </div>
                        <div className="btns-groups">
                            <button type="button" disabled className="btn-default2">Current Image</button>
                            <button type="button" className="btn-default2" onClick={this.deleteProfilePic}>Delete Image</button>
                        </div>
                    </div>
                    :
                    <div className="upload-section">
                        <input type="file" id="profileImg" name="files" onChange={this.uploadImg} />
                        <label htmlFor="profileImg">
                            <i className="fa fa-cloud-upload"></i>
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </label>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = state=>({
    userImg:state.userImg.userImg
})

const mapDispatchToProps = dispatch=>({
    updateUserImg:(img)=>dispatch({type:ACTION_TYPES.USER_IMAGE_UPDATE, payload:img})
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePicComponent));