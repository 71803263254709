import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { apiHelper } from '../../../helper/api.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { toasterError } from '../../../helper/toaster.helper';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';

const CSVheaders2 = [
    { label: 'Tag Name', key: "tag" },
    { label: 'First Name', key: "firstName" },
    { label: 'Last Name', key: "lastName" },
    { label: 'Email', key: "email" },
    { label: 'Phone', key: "phoneNumber" },
    { label: 'Website', key: "website" },
    { label: 'City', key: "city" },
    { label: 'State', key: "state" },
    { label: 'Height', key: "heightInches" },
    // { label: 'Height', key: "heightInches" },
    { label: 'Eye Color', key: "eyeColor" },
    { label: 'Hair Color', key: "hairColor" },
    { label: 'Unions', key: "unions" },
    { label: 'Age Range', key: "ageRange" },
    { label: 'Vocal Range', key: "vocalRange" },
    { label: 'Gender', key: "genderArray" },
    { label: 'Ethnicity', key: "ethnicity" },
    { label: 'Notes', key: "notes" },
]
class CompanyDetailsComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            companyDetails: null,
            companyNoteData: [],
            companyTagsData: [],
            CSVData: [],
            fileName: ''
        }

        this.csvLinkEl = React.createRef();
    }

    componentDidMount() {
        this.getCompanyDetails();
        this.getCompanyNotes();
        this.getCompanyTags();
    }

    getCompanyTags = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);
            const response = await apiHelper('admin/company/getCompanyTags', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    companyTagsData: data
                })
            } else {
                // toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    getCompanyNotes = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);
            const response = await apiHelper('admin/company/getCompanyNotes', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    companyNoteData: data
                })
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getCompanyDetails = async () => {
        try {

            const formData = new FormData();
            formData.append('id', this.state.id);

            const response = await apiHelper('admin/company/getCompanyDetails', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                console.log(data);
                this.setState({
                    companyDetails: data
                })
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getTagInfo = async (row) => {
        try {
            // console.log(row);
            const formData = new FormData();
            formData.append('id', this.state.id);
            formData.append('tagName', row.tagName);

            const response = await apiHelper('admin/company/getTagDetails', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const datas = jwtDecode(res.data);
                // console.log(datas);
                // return datas;
                this.setState({
                    CSVData: datas
                })
                // return datas
                return Promise.resolve(datas)

            } else {
                // toasterError(res.message);
                // toCsv([]);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    actionFormat = (cell, row) => {
        // console.log(data);
        console.log(row.tagName);
        return (
            <>
                <button type="button" className="btn btn-success btnexport" onClick={() => this.downloadReport(row)} style={{ marginRight: '10px' }}>
                    <i className="fa fa-download"></i>
                </button>
                <CSVLink headers={CSVheaders2} data={this.state.CSVData} filename={`${row.tagName}.csv`} ref={this.csvLinkEl} />
                {/* <CSVLink
                    headers={CSVheaders}
                    filename={`${row.tagName}.csv`}
                    data={this.state.CSVData}
                    ref={this.csvLinkEl}
                /> */}
            </>
        )
    }

    downloadReport = async (row) => {
        const data = await this.getTagInfo(row)
        //console.log(data);
        this.setState({
            CSVData: data,
            fileName: row.tagName
        }, () => {
            setTimeout(() => {
                this.csvLinkEl.current.link.click();
            })
        })
    }

    render() {
        // const { CSVData } = this.state;
        // console.log(CSVData);
        const columns = [
            { dataField: 'text', text: 'Text' },
            { dataField: 'name', text: 'For' }
        ]
        const columns2 = [
            { dataField: 'tagName', text: 'Tag name' },
            { dataField: 'action', text: 'Action', formatter: (cell, row) => this.actionFormat(cell, row) }
        ]
        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="panel panel-profile">
                                <div className="clearfix">
                                    <div className="profile-left relative">
                                        <div className="profile-detail">
                                            <div className="profile-info">
                                                <h4 className="heading">Company Information</h4>
                                                <ul className="list-unstyled list-justify">
                                                    <li>Company Name <span>{this.state.companyDetails && this.state.companyDetails.companyname ? this.state.companyDetails.companyname : '--'}</span></li>
                                                    <li>Company Type <span>{this.state.companyDetails && this.state.companyDetails.companytype ? this.state.companyDetails.companytype : '--'}</span></li>
                                                    <li>EIN Number <span>{this.state.companyDetails && this.state.companyDetails.einnumber ? this.state.companyDetails.einnumber : '--'}</span></li>
                                                    <li>Address <span>{this.state.companyDetails && this.state.companyDetails.companyaddress ? this.state.companyDetails.companyaddress : '--'}</span></li>
                                                </ul>
                                            </div>
                                            <div className="profile-info">
                                                <h4 className="heading">User Information</h4>
                                                <ul className="list-unstyled list-justify">
                                                    <li>Name <span>{this.state.companyDetails && this.state.companyDetails.firstName} {this.state.companyDetails && this.state.companyDetails.lastName}</span></li>
                                                    <li>Email <span>{this.state.companyDetails && this.state.companyDetails.email}</span></li>
                                                    <li>Mobile <span>{this.state.companyDetails && this.state.companyDetails.phoneNumber ? this.state.companyDetails.phoneNumber : '--'}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-right">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="panel">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">Recent Notes</h3>
                                                        <BootstrapTable
                                                            keyField='text' classes="table table-striped" data={this.state.companyNoteData} columns={columns}
                                                            pagination={paginationFactory()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-right">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="panel">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">My Tags</h3>
                                                        <ul className="table_ul">
                                                            <li className="header_li">
                                                                <div className="tag_name_title">
                                                                    <p>{'Tag name'}</p>
                                                                </div>
                                                                <div className="btn_wrap_action">
                                                                    <p>Action</p>
                                                                </div>
                                                            </li>
                                                            {this.state.companyTagsData.length > 0 ?
                                                                this.state.companyTagsData.map((info, i) => <li key={i}>
                                                                    <div className="tag_name">
                                                                        <p>{info.tagName}</p>
                                                                    </div>
                                                                    <div className="btn_wrap">
                                                                        <button type="button" className="btn btn-success btnexport" onClick={() => this.downloadReport(info)} style={{ marginRight: '10px' }}>
                                                                            <i className="fa fa-download"></i>
                                                                        </button>
                                                                        {console.log(info.tagName)}
                                                                        <CSVLink headers={CSVheaders2} data={this.state.CSVData} filename={`${this.state.fileName}.csv`} ref={this.csvLinkEl} />
                                                                    </div>
                                                                </li>)
                                                                :
                                                                <li>No data found.</li>
                                                            }
                                                        </ul>
                                                        {/* <ToolkitProvider
                                                            keyField="id"
                                                            data={this.state.companyTagsData}
                                                            columns={columns2}
                                                            // exportCSV
                                                        >
                                                            {
                                                                props => (
                                                                    <BootstrapTable { ...props.baseProps } keyField='tagName' classes="table table-striped" pagination={paginationFactory()} />

                                                                )
                                                            }
                                                        </ToolkitProvider> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </>
        );
    }
}

export default withRouter(props => <CompanyDetailsComponent {...props} />);