import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class FooterUser extends Component {
    render() {
        const token = localStorage.getItem('userToken');

        const { location: { pathname } } = this.props;
        const splitPath = pathname.split('/');
        let isUser = false;
        if (splitPath[1] !== 'admin') {
            isUser = true;
        }
        return (
            isUser && token &&
            <footer className="footer-bgg">
                <div className="footer-wrap">
                    <p className="company-nam">Audition Revolution.v2 Beta</p>
                    <p className="copy-right">Contact Support: support@auditionrevolution.com</p>
                </div>
            </footer>
        );
    }
}

export default withRouter(props=><FooterUser {...props}/>);;