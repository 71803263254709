import * as ACTION_TYPES from '../actions/type';

const initialState = {
    userImg:''
}

const UserimgReducers = (state=initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.USER_IMAGE_UPDATE:
            return{
                ...state,
                userImg:action.payload
            }
        default:
            return state;
    }
}

export default UserimgReducers;