import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from '../../../helper/api.helper';
import CreatableSelect from 'react-select/creatable';
import { jwtDecode } from '../../../helper/jwt.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';

class AddTagComponent extends Component {

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            tagText: null,
            tagArray: []
        }
    }

    componentDidMount() {
        this.getTags();
    }

    getTags = async () => {
        const response = await apiHelper('company/getCompanyTags', 'GET', null, this.headers);
        const res = response.data;

        if (res.status) {
            const data = jwtDecode(res.data);
            this.setState({
                tagArray: data.map(info => ({ value: info.tagName, label: info.tagName }))
            })
        }
    }

    tagSubmit = async () => {
        console.log();
        try {
            const formData = new FormData();
            formData.append('text', this.state.tagText.value);
            formData.append('forId', this.props.id);

            const response = await apiHelper('company/addActorTag', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                this.props.onHide();
                this.props.reloadTagsFun(true);
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    closeTagModal = () => {
        this.setState({
            isTagModal: false
        })
    }

    selectHandler = (optionSelected) => {
        this.setState({
            tagText: optionSelected
        })
    }

    render() {
        const { tagText, tagArray } = this.state;
        // const disabled = !valid.tagText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Tag for</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Tags are private and are not shared with actor or other users</label>
                                <div className="form-group">
                                    <CreatableSelect
                                        isClearable
                                        onChange={this.selectHandler}
                                        options={tagArray}
                                        value={tagText}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={!tagText} onClick={this.tagSubmit}>Apply Tag</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    reloadTagsFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_TAG_API, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(AddTagComponent));