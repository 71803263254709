import React, { Component, useState, useEffect } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import AddNotesComponent from './AddNotesComponent';
import AddTagComponent from './AddTagComponent';
// import { useTable } from 'react-table'
import { toasterError } from '../../../helper/toaster.helper';
import EditTagComponent from './EditTagModal';
import { apiHelper } from '../../../helper/api.helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components'
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import update from 'immutability-helper'
import { jwtDecode } from '../../../helper/jwt.helper';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const dataArray = [
    { id: 1, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 1', lname: 'Doe', email: 'john@mailinator.com' },
    { id: 2, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 2', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 3, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 3', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 4, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 4', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 5, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 5', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 6, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 6', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 7, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 7', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 8, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 8', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 9, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 9', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 10, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 10', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 11, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 11', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 12, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 12', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 13, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 13', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 14, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 14', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 15, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 15', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 16, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 16', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 17, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 17', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 19, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 18', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 20, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 19', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 21, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 20', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 22, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 21', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 23, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 22', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 24, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 23', lname: 'Doe', email: 'johnTest@mailinator.com' },
    { id: 25, img: 'https://audition-revolution.s3.us-east-2.amazonaws.com/audition-revolution/53723d8c-2243-440d-8b05-3650031b31d2.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA23MHTORGRI6OTBFV%2F20211015%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20211015T072319Z&X-Amz-Expires=300&X-Amz-Signature=bf671eb7e1bd2e2b485e8a3d4165664c81ca1161ef241245a000aa79b8f921de&X-Amz-SignedHeaders=host', fname: 'John 24', lname: 'Doe', email: 'johnTest@mailinator.com' },
]

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>

            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                className="form-control"
                placeholder={`${count} records...`}
            />
        </span>
    )
}

const Table = ({ mainLoader, classs, columns, data, moveHRow, fetchData, isRedorder, reorderFunc, saveReorderFun, reorderSaveLoader, pageCount: controlledPageCount }) => {
    // console.log(data);
    const [records, setRecords] = React.useState([])
    const [loader, setLoader] = React.useState(false)

    // const getRowId = React.useCallback(row => {
    //   console.log(row);
    //   return row.id
    // }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }

    } = useTable({
        data: records,
        columns,
        initialState: { pageIndex: 0 },
        manualPagination: true,
        pageCount: controlledPageCount,
        // getRowId,
    }, useGlobalFilter, useSortBy, usePagination)

    useEffect(() => {
        if (data && data.length > 0) {
            setRecords(data);
        }
    }, [data])

    useEffect(() => {
        // console.log(mainLoader);
        setLoader(mainLoader)
    }, [mainLoader])

    useEffect(() => {
        // console.log('working...')
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    const rowHandler = (result) => {
        // console.log(result);
        const { source, destination } = result;
        if (!destination) return;
        const dragRecord = records[source.index];
        const hoverIndex = destination.index;
        const dragIndex = source.index
        const upd = update(records, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
            ],
        });

        // console.log(upd);
        moveHRow(upd);

        setRecords(upd);
    }

    // console.log(pageOptions);

    return (
        <>
            <div className="panel-heading">
                <div style={{ maxWidth: '300px' }}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={''}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                <div className="right">
                    {isRedorder ?
                        <button type="button" className="btns btn-transprent" onClick={saveReorderFun} disabled={reorderSaveLoader}>Save changes {reorderSaveLoader ? <i className="fa fa-spinner fa-spin"></i> : null}</button>
                        :
                        <button type="button" className="btns btn-transprent" onClick={reorderFunc}>Reorder</button>
                    }
                </div>
            </div>
            <table {...getTableProps()} className={classs}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th className={isRedorder ? "display-show" : "display-none"}></th>
                            {headerGroup.headers.map(column => {
                                // console.log(column);
                                return (
                                    !column.sortBy ?
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}

                                        </th>
                                        :

                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {column.isSorted ?
                                                <span style={{ marginLeft: '10px' }}>
                                                    {column.isSortedDesc
                                                        ? <i className="fa fa-sort-desc"></i>
                                                        : <i className="fa fa-sort-asc"></i>

                                                    }
                                                </span>
                                                :
                                                null
                                            }
                                        </th>

                                )
                            })}
                        </tr>
                    ))}
                </thead>
                {loader ?
                    <tbody>
                        <tr>
                            <td colSpan="5">
                                <div className="text-center" style={{ paddingBottom: '15px' }}>
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: '34px' }}></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    :
                    <DragDropContext onDragEnd={rowHandler}>
                        <Droppable droppableId="table-body">
                            {(provided, snapshot) => (
                                <tbody {...getTableBodyProps()} {...provided.droppableProps} ref={provided.innerRef}>
                                    {rows.map(
                                        (row, index) => {
                                            prepareRow(row);

                                            return (<Draggable draggableId={row.id} key={index} index={row.index}>
                                                {(provided, snapshot) => {

                                                    return <tr
                                                        key={index}
                                                        index={row.id}
                                                        // style={{ opacity }} 
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                        isDragging={snapshot.isDragging}
                                                    >
                                                        <td className={`box-drag ${isRedorder ? 'display-show' : 'display-none'}`} {...provided.dragHandleProps}><i className="fa fa-arrows"></i></td>
                                                        {row.cells.map(cell => {
                                                            // console.log(cell);
                                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                }}
                                            </Draggable>
                                            )
                                        }
                                    )}
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
            </table>
            <div className="pagination" style={{ width: '100%', margin: '0' }}>
                <div className="pull-right">
                    <span style={{ marginRight: '15px' }}>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    {' '}
                    <button type="button" className="pagination-btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button type="button" className="pagination-btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button type="button" className="pagination-btn" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button type="button" className="pagination-btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                </div>
                {/* <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '} */}
                <div className="pull-left" style={{ width: '100px' }}>
                    <select
                        value={pageSize}
                        className="form-control"
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    )
}

function CompanySingleTag(props) {

    const [data, setData] = useState([]);
    const [tagName, setTagName] = useState('');
    const [tagNameInput, setTagNameInput] = useState('');
    const [toggleEditTagModal, setToggleEditTagModal] = useState(false);
    const [tagNameLoader, setTagNameLoader] = useState(false);
    const [mainLoader, setMainLoader] = useState(false);
    const [isNoteModal, setIsNoteModal] = useState(false);
    const [isReorderd, setIsReorderd] = useState(false);
    const [noteModalData, setNoteModalData] = useState(null);
    const [isTagModal, setIsTagModal] = useState(false);
    const [tagModalData, setTagModalData] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [reorderSave, setReorderSave] = useState(false);
    const [page, setPage] = useState(0);
    const fetchIdRef = React.useRef(0)

    useEffect(() => {
        const { location: { search } } = props;
        const query = new URLSearchParams(search);
        const tagname = query.get('tag');
        setTagName(tagname);

        const getTagData = async (tagname) => {
            setMainLoader(true)
            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }
            const response = await apiHelper(`company/getSingleTags?tagName=${tagname}`, 'GET', null, headers)
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                setData(decode);
                setMainLoader(false)
            } else {
                setMainLoader(false)
                setData([])
            }
        }

        getTagData(tagname);
    }, [])

    const openEditTagModal = () => {
        setToggleEditTagModal(true);
        setTagNameInput(tagName);
    }

    const closeEditTagModal = () => {
        setToggleEditTagModal(false);
        setTagNameInput('');
    }


    const fetchData = React.useCallback(({ pageSize, pageIndex }) => {


        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current


        // Set the loading state
        // setLoading(true)

        // We'll even set a delay to simulate a server here
        setTimeout(() => {
            // Only update the data if this is the latest fetch
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setData(data.slice(startRow, endRow))

                // Your server could send back total page count.
                // For now we'll just fake it, too
                setPage(Math.ceil(data.length / pageSize))

                // setLoading(false)
            }
        }, 1000)
    }, [])

    // console.log(++fetchIdRef.current);

    const actionFormat = (cell, row) => {
        return (
            <div className="group-btn text-center">
                {/* onClick={() => this.deleteActor(row)} */}
                {/* <button type="button" className="btn btn-danger" style={{ marginRight: '10px' }}>Delete</button> */}
                <button type="button" onClick={() => openTagModal(cell)} className="btn_default2 border-gray">ADD TAG</button>{' '}
                <button type="button" onClick={() => openNoteModal(cell)} className="btn_default2">ADD NOTES</button>
            </div>
        )
    }

    const imgFormatter = (cell, row) => {
        // console.log(cell);
        return <img src={cell.value} alt={cell.row.fname} style={{ width: '35px', height: '35px', borderRadius: '50%' }} />
    }

    const rediractFormatter = (cell, row) => {

        return <Link to={`/profile/${cell?.row?.original?.id}`}>{cell.value}</Link>
    }

    const openNoteModal = ({ row: { original } }) => {
        setIsNoteModal(true);
        setNoteModalData(original);
        setUser_id(original.id)
    }

    const closeNoteModal = () => {
        setIsNoteModal(false)
        setNoteModalData(null);
        setUser_id(null)
    }

    const openTagModal = ({ row: { original } }) => {
        setIsTagModal(true)
        // console.log(original);
        setTagModalData(original);
        setUser_id(original.id)
    }

    const closeTagModal = () => {
        setIsTagModal(false)
        setTagModalData(null);
        setUser_id(null)
    }

    const updateData = (data) => {
        // console.log(data);
        setData(data);
    }

    const saveTagOrder = async () => {
        setReorderSave(true);
        const headers = {
            'Authorization': localStorage.getItem('userToken')
        }
        if (data && data.length > 0) {
            let formData = new FormData();
            formData.append('tagsArray', JSON.stringify(data));

            const response = await apiHelper('company/reOrderSingleTags', 'PUT', formData, headers)
            const res = response.data;
            if (res.status) {
                setIsReorderd(false);
                setReorderSave(false);

            } else {
                setReorderSave(false);
                toasterError(res.message);
            }
        } else {
            setIsReorderd(false);
            setReorderSave(false);
        }

    }

    const saveTagName = async (text) => {
        // console.log(text);
        try {
            setTagNameLoader(true);
            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }
            let formData = new FormData();
            formData.append('newTagName', text.tagName)
            formData.append('oldTagName', tagName)
            const response = await apiHelper('company/updateTagName', 'PUT', formData, headers);
            const res = response.data;
            if (res.status) {
                setTagName(text.tagName);
                setTagNameLoader(false)
                setToggleEditTagModal(false);
            } else {
                setTagNameLoader(false)
                toasterError(res.message);
            }
        } catch (err) {
            setTagNameLoader(false)
            toasterError(err.message);
        }
    }


    // console.log(props);

    const { location: { search } } = props;
    const query = new URLSearchParams(search);
    const tagname = query.get('tag');

    if (!tagname) {
        return <Redirect to="/profile/tags" />
    }

    const columns12 = [
        {
            Header: 'User',
            accessor: 'profilePic',
            Cell: imgFormatter,
            sortBy: false
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: rediractFormatter,
            sortBy: true
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: rediractFormatter,
            sortBy: true
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: rediractFormatter,
            sortBy: true
        },
        {
            Header: (cell) => {
                // console.log(cell);
                return <div className="text-center">Action</div>
            },
            accessor: 'action',
            Cell: actionFormat,
            sortBy: false
        }
    ]

    // console.log(data);

    return (
        <>
            <div className="">
                <div className="main-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="header_wrap">
                                    <div className="page_header">
                                        <h4>{tagName} <i className="fa fa-pencil" onClick={openEditTagModal} style={{ cursor: 'pointer', fontSize: '18px' }}></i></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    {/* <div className="panel-heading">
                                        <h3 className="panel-title">{tagName}</h3>
                                        
                                    </div> */}
                                    <div className="panel-body no-padding">
                                        <Styles>
                                            <div className="table-responsive">
                                                <Table mainLoader={mainLoader} reorderFunc={() => setIsReorderd(true)} saveReorderFun={saveTagOrder} reorderSaveLoader={reorderSave} classs="table table-striped table-custom" columns={columns12} data={data} moveHRow={updateData} isRedorder={isReorderd} pageCount={page} fetchData={fetchData} />
                                            </div>
                                        </Styles>
                                    </div>
                                    {isNoteModal ? <AddNotesComponent show={isNoteModal} id={user_id} onHide={closeNoteModal} /> : null}
                                    {isTagModal ? <AddTagComponent show={isTagModal} id={user_id} onHide={closeTagModal} /> : null}
                                    {toggleEditTagModal ?
                                        <EditTagComponent loader={tagNameLoader} show={toggleEditTagModal} tagname={tagName} onHide={closeEditTagModal} submitTagName={saveTagName} />
                                        :
                                        null
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    );
}

export default withRouter(CompanySingleTag);