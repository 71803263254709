import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { apiHelper } from './../../../helper/api.helper';
import { toasterSuccess, toasterError } from './../../../helper/toaster.helper';
import { jwtDecode } from './../../../helper/jwt.helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddUserModelComponent from './../AdminUsersComponent/AddUserModelComponent';

class AdminUsersComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isUserModal: false
        }
    }
    openUserModal = (e) => {
        e.preventDefault();
        this.setState({
            isUserModal: true
        })
    }

    closeUserModal = () => {
        this.setState({
            isUserModal: false
        })
    }
    componentDidMount() {
        this._unmounted = true;
        this.getAdminUsers();
    }
    componentWillUnmount() {
        this._unmounted = false;
    }
    getAdminUsers = async () => {
        try {
            const response = await apiHelper('admin/users/getAllUsers', 'GET', null, this.headers);
            let res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        data: data
                    })
                    // toasterSuccess(res.message);
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    deleteUser = async (row) => {
        try {
            if (window.confirm("Are you sure want to delete this user?")) {
                const formData = new FormData();
                formData.append('id', row.id);
                const response = await apiHelper('admin/users/deleteUser', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    const darr = this.state.data.filter((info) => info.id !== row.id);
                    this.setState({
                        data: darr
                    })
                    //toasterSuccess(res.message);
                } else {
                    toasterError(res.message);
                }
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    actionFormat = (cell, row) => {
        return (
            <>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteUser(row)} style={{ marginRight: '10px' }}>Delete</button>
            </>
        )
    }
    render() {
        const { SearchBar } = Search;
        // console.log(this.state.data);
        const columns = [
            { dataField: 'firstName', text: 'First Name' },
            { dataField: 'lastName', text: 'Last Name' },
            { dataField: 'username', text: 'User Name' },
            { dataField: 'action', text: 'Action', formatter: this.actionFormat },
        ]
        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="d-flex justify-flex-end">
                                {/* <h3 className="page-title">Skills List</h3> */}
                                <a href="/#" onClick={this.openUserModal} className="btn btn-primary">Add User</a>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">User List</h3>
                                        </div>
                                        <div className="panel-body no-padding">
                                            <ToolkitProvider
                                                keyField='id' data={this.state.data} columns={columns}
                                                search
                                            >
                                                {
                                                    props => (
                                                        <div>
                                                            <div className="customSearch">
                                                                <SearchBar {...props.searchProps} />
                                                            </div>
                                                            <BootstrapTable
                                                                {...props.baseProps} classes="table table-striped" pagination={paginationFactory()}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                {this.state.isUserModal ? <AddUserModelComponent show={this.state.isUserModal} onHide={this.closeUserModal} /> : null}
            </>
        );
    }
}

export default withRouter(props => <AdminUsersComponent {...props} />);