import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { jwtDecode } from './../helper/jwt.helper';

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('token');
    let tokenDecode = token ? jwtDecode(token) : null;

    return <Route
        {...rest}
        render={props => {
            return token ?
                tokenDecode && tokenDecode.id ? <Component {...props} /> : <Redirect to="/admin" />
                :
                <Redirect to="/admin" />
        }}
    />
}

export default AdminPrivateRoute;