import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { apiHelper } from './../../../helper/api.helper';
import { toasterError, toasterSuccess } from './../../../helper/toaster.helper';
import { jwtDecode } from './../../../helper/jwt.helper';
import './MyActorComponent.css';
import AddCompanyActor from './../CompanyComponent/AddCompanyActor';
import EditCompanyActor from './../CompanyComponent/EditCompanyActor';
import UploadActor from './../CompanyComponent/UploadActor';
class MyActorComponent extends Component {

    _unmounted = false;
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isAddCompanyModal: false,
            isUploadActorModal: false,
            isEditCompanyModal: false,
            singleActorData: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getCompaniesData();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getCompaniesData = async () => {
        try {
            const response = await apiHelper('company/getCompanyActor', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        data: data.actorData
                    })
                    // toasterSuccess(res.message);
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    deleteCompanyActor = async (row) => {
        try {
            if (window.confirm("Are you sure want to delete this actor?")) {
                const formData = new FormData();
                formData.append('id', row.id);

                const response = await apiHelper('company/deleteCompanyActor', 'POST', formData, this.headers);
                const res = response.data;

                if (res.status) {
                    const darr = this.state.data.filter((info) => info.id !== row.id);
                    this.setState({
                        data: darr
                    })
                    //toasterSuccess(res.message);
                } else {
                    toasterError(res.message);
                }
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    actionFormat = (cell, row) => {
        return (
            <div className="text-center">
                <button type="button" className="btn btn-success" onClick={() => this.editMyActor(row)} style={{ marginRight: '10px' }}>Edit</button>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteCompanyActor(row)} style={{ marginRight: '10px' }}>Delete</button>
                <button type="button" onClick={() => this.viewCompanyDetails(row.id)} className="btn btn-primary">View</button>
            </div>
        )
    }
    bodyCenter = (cell, row) => {
        return <div className="text-center">{cell}</div>
    }
    headerCenter = (columns) => {
        // return columns.text
        return <div className="text-center">
            {columns.text}
            {columns.sort ?
                <span className="order">
                    <span className="dropdown">
                        <span className="caret"></span>
                    </span>
                    <span className="dropup">
                        <span className="caret"></span>
                    </span>
                </span>
                :
                null
            }
        </div>
    }
    editMyActor = (row) => {
        this.setState({
            isEditCompanyModal: true,
            singleActorData: row
        })
    }
    closeEditActorModel = () => {
        this.setState({
            isEditCompanyModal: false,
            singleActorData: null
        })
    }

    viewCompanyDetails = (id) => {
        this.props.history.push(`/profile/${id}`)
    }
    openAddCompanyModal = () => {
        this.setState({
            isAddCompanyModal: true
        })
    }

    closeAddCompanyModal = () => {
        this.setState({
            isAddCompanyModal: false
        })
    }
    openUploadActorModal = () => {
        this.setState({
            isUploadActorModal: true
        })
    }

    closeUploadActorModal = () => {
        this.setState({
            isUploadActorModal: false
        })
    }
    render() {

        const { SearchBar } = Search;

        const columns = [
            { dataField: 'firstName', text: 'FirstName', sort: true, headerFormatter: this.headerCenter, formatter: this.bodyCenter },
            { dataField: 'lastName', text: 'LastName', sort: true, headerFormatter: this.headerCenter, formatter: this.bodyCenter },
            // {dataField: 'displayName', text: 'DisplayName', sort: true },
            { dataField: 'email', text: 'Email', sort: true, headerFormatter: this.headerCenter, formatter: this.bodyCenter },
            { dataField: 'phoneNumber', text: 'PhoneNumber', sort: true, headerFormatter: this.headerCenter, formatter: this.bodyCenter },
            // {dataField: 'status', text: 'Status', formatter: this.statusFormat, sort: true },
            { dataField: 'action', text: 'Action', formatter: this.actionFormat, headerFormatter: this.headerCenter },
        ]

        return (
            <section className="bg-myactor h-100">
                <div className="container">
                    <div className="header_wrap">
                        <div className="page_header">
                            <h4>My Actors</h4>
                        </div>
                    </div>
                    <div className="table_wrap">
                        <ToolkitProvider
                            keyField='id' data={this.state.data} columns={columns}
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <div className="searc_withbutton">
                                            <div className="customSearch">
                                                <SearchBar {...props.searchProps} />
                                            </div>
                                            <div className="btns_group">
                                                <button type="button" onClick={this.openAddCompanyModal} className="btn btn-primary">Add Actor</button>
                                                {/* <button type="button" onClick={this.openUploadActorModal} className="btn btn-warning">Upload Actor</button> */}
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps} classes="table table-striped" pagination={paginationFactory()}
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
                {this.state.isAddCompanyModal ? <AddCompanyActor show={this.state.isAddCompanyModal} onHide={this.closeAddCompanyModal} /> : null}
                {this.state.isUploadActorModal ? <UploadActor show={this.state.isUploadActorModal} onHide={this.closeUploadActorModal} /> : null}
                {this.state.isEditCompanyModal ? <EditCompanyActor data={this.state.singleActorData} show={this.state.isEditCompanyModal} onHide={this.closeEditActorModel} /> : null}
            </section>
        );
    }
}

export default withRouter(props => <MyActorComponent {...props} />);