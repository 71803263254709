import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from '../../../helper/api.helper';
import * as validationHelper from '../../../helper/validation.helper';
import { toasterError } from '../../../helper/toaster.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';
import Autocomplete from "react-google-autocomplete";
import './ProfileModel.css';

class ProfileModel extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: props.data.firstName,
            lastName: props.data.lastName,
            phoneNumber: props.data.phoneNumber,
            cityState: `${props.data.city},${props.data.state}`,
            city: props.data.city,
            state: props.data.state,
            heightFeet: props.data.heightFeet,
            feetInches: props.data.feetInches,
            hairColor: props.data.hairColor,
            eyeColor: props.data.eyeColor,
            website: props.data.website,
            representation: props.data.representation,
            error: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                cityState: '',
                city: '',
                state: '',
                heightFeet: '',
                feetInches: '',
                hairColor: "",
                eyeColor: "",
                website: "",
                representation: ""
            },
            valid: {
                firstName: (props.data.firstName !== ''),
                lastName: (props.data.lastName !== ''),
                phoneNumber: (props.data.phoneNumber !== ''),
                cityState: (props.data.city !== '' || props.data.state !== ''),
                city: (props.data.city !== ''),
                state: (props.data.state !== ''),
                heightFeet: (props.data.heightFeet >= 0) ? true : false,
                feetInches: (props.data.feetInches >= 0) ? true : false,
                hairColor: (props.data.hairColor !== ''),
                eyeColor: (props.data.eyeColor !== ''),
                website: false,
                representation: false
            }
        }
    }
    componentDidMount() {
        // this.renderCityInput();
    }
    // renderCityInput = () => {
    //     const placeInstance = places({
    //         appId: "plL5W1957CCG",
    //         apiKey: "8f309db6542eab1fb7c18b83fcff357c",
    //         container: document.querySelector("#address2")
    //     });

    //     placeInstance.configure({
    //         language: "en",
    //         type: this.props.type,
    //         countries: ["us"]
    //     });

    //     placeInstance.on("change", (e) => {
    //         console.log(e.suggestion);
    //         const { name: city, administrative: state } = e.suggestion;
    //         this.setState({
    //             cityState: `${city}, ${state}`,
    //             city: city,
    //             state: state,
    //             error: {
    //                 ...this.state.error,
    //                 cityState: ''
    //             },
    //             valid: {
    //                 ...this.state.valid,
    //                 cityState: true
    //             }
    //         });

    //     });
    // }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'firstName':
                valid.firstName = value.length > 0;
                error.firstName = valid.firstName ? "" : "First name is required!";
                break;
            case 'lastName':
                valid.lastName = value.length > 0;
                error.lastName = valid.lastName ? "" : "Last name is required!";
                break;
            case 'phoneNumber':
                const regNumber = new RegExp(/^[0-9\b]+$/);
                if (value.length > 0) {

                    if (regNumber.test(value)) {
                        valid.phoneNumber = true;
                        error.phoneNumber = "";
                    } else {
                        valid.phoneNumber = false;
                        error.phoneNumber = "Phone number is not valid";
                    }

                } else {
                    valid.phoneNumber = false;
                    error.phoneNumber = "Phone number is required!";
                }
                break;
            case 'heightFeet':
                valid.heightFeet = value >= 0 && value !== "" ? true : false;
                error.heightFeet = valid.heightFeet ? "" : "Height Feet number is required!";
                break;
            case 'feetInches':
                valid.feetInches = value >= 0 && value !== "" ? true : false;
                error.feetInches = valid.feetInches ? "" : "Height Inches is required!";
                break;
            case 'hairColor':
                valid.hairColor = value.length > 0;
                error.hairColor = valid.hairColor ? "" : "Hire Color is required!";
                break;
            case 'eyeColor':
                valid.eyeColor = value.length > 0;
                error.eyeColor = valid.eyeColor ? "" : "Eye Color is required!";
                break;
            case 'website':
                const response = validationHelper.isUrlValid(value);
                valid.website = response.status;
                error.website = response.status ? "" : response.message;
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    updateProfile = async () => {
        try {
            const { firstName, lastName, phoneNumber, cityState, heightFeet, feetInches, city, state, hairColor, eyeColor, website, representation, valid } = this.state;

            const validField = valid.firstName || valid.lastName || valid.phoneNumber || valid.cityState || valid.heightFeet || valid.feetInches || valid.hairColor || valid.eyeColor;

            if (validField) {
                const formData = new FormData();

                let Height = (heightFeet * 12);
                Height = (Height + Number(feetInches));

                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('phoneNumber', phoneNumber);
                formData.append('cityState', cityState);
                formData.append('city', city);
                formData.append('state', state);
                formData.append('heightInches', Height);
                formData.append('hairColor', hairColor);
                formData.append('eyeColor', eyeColor);
                formData.append('website', website);
                formData.append('representation', representation);

                const response = await apiHelper('actor/savestep1', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    //toasterSuccess(res.message);
                    this.props.userUpdateFun(true);
                    this.props.onHide();
                    //window.location.reload();
                } else {
                    toasterError(res.message);
                }
            } else {
                toasterError("All field is required!");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    render() {
        const { firstName, lastName, phoneNumber, cityState, heightFeet, feetInches, hairColor, eyeColor, website, representation, valid, error } = this.state;
        const disabled = !valid.firstName || !valid.lastName || !valid.phoneNumber || !valid.cityState || !valid.heightFeet || !valid.feetInches || !valid.hairColor || !valid.eyeColor;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Update User</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.inputHandler} placeholder="First Name" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.inputHandler} placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" name="phoneNumber" className="form-control" value={phoneNumber} onChange={this.inputHandler} placeholder="Phone" />
                                    {error.phoneNumber !== "" ? <p className="error-text">{error.phoneNumber}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>City, State</label>
                                    {/* <input type="text" name="cityState" id="address2" className="form-control" value={cityState} onChange={this.inputHandler} placeholder="City,State" /> */}
                                    <Autocomplete
                                        name="cityState"
                                        value={cityState}
                                        type="city"
                                        className="form-control"
                                        id="address-input"
                                        placeholder="City,State"
                                        onChange={this.inputHandler}
                                        apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                                        onPlaceSelected={(place) => {
                                        var formatted_address = place.formatted_address.split(",");
                                        this.setState({
                                            cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                            city: formatted_address[0],
                                            state: formatted_address[1],
                                            error: {
                                            ...this.state.error,
                                            cityState: "",
                                            },
                                            valid: {
                                            ...this.state.valid,
                                            cityState: true,
                                            },
                                        });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Height Feet</label>
                                    <input type="number" pattern="[0-9]*" inputMode="numeric" name="heightFeet" className="form-control" value={heightFeet} onChange={this.inputHandler} placeholder="Height Feet" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Height Inches</label>
                                    <input type="number" pattern="[0-9]*" inputMode="numeric" name="feetInches" className="form-control" value={feetInches} onChange={this.inputHandler} placeholder="Height Inches" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Hair Color</label>
                                    <select name="hairColor" className="form-control" value={hairColor} onChange={this.inputHandler}>
                                        <option value="">Select Hair Color</option>
                                        <option value="black">Black</option>
                                        <option value="brown">Brown</option>
                                        <option value="red">Red</option>
                                        <option value="blonde">Blonde</option>
                                        <option value="gray">Gray</option>
                                        <option value="white">White</option>
                                        <option value="other">Other</option>
                                        <option value="unknown">Unknown</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Eye Color</label>
                                    <select name="eyeColor" className="form-control" value={eyeColor} onChange={this.inputHandler}>
                                        <option value="">Select Eye Color</option>
                                        <option value="brown">Brown</option>
                                        <option value="hazel">Hazel</option>
                                        <option value="blue">Blue</option>
                                        <option value="green">Green</option>
                                        <option value="gray">Gray</option>
                                        <option value="amber">Amber</option>
                                        <option value="other">Other</option>
                                        <option value="unknown">Unknown</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Website</label>
                                    <input type="text" name="website" className="form-control" value={website} onChange={this.inputHandler} placeholder="Website" />
                                    {error.website !== "" ? <p className="error-text">{error.website}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Representation</label>
                                    <input type="text" name="representation" className="form-control" value={representation} onChange={this.inputHandler} placeholder="Representation" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.updateProfile}>Save</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    userUpdateFun: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_USER_INFO, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(ProfileModel));