import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { apiHelper } from './../../../helper/api.helper';
import { toasterError, toasterSuccess } from './../../../helper/toaster.helper';
import './../../../assets/vendor/chartist/css/chartist-custom.css';
import { jwtDecode } from './../../../helper/jwt.helper';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import DashboardTableComponent from './DashboardTableComponent';

class DashboardComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            actorData: [],
            companyData: [],
            totalCompany: 0,
            recentCompany: 0,
            totalActor: 0,
            recentActor: 0,
            startDate: moment().subtract(1, "day").format("YYYY-MM-DD H:mm"),
            endDate: new Date()
        }
    }
    componentDidMount() {

        this._unmounted = true;
        const startDate = moment().subtract(1, "day").format("YYYY-MM-DD H:mm");
        const endDate = moment(new Date(), "YYYY-MM-DD H:mm").format("YYYY-MM-DD H:mm");

        this.getRecentActorData(startDate, endDate);
        this.getRecentCompanyData(startDate, endDate);
        this.getCompanyState(startDate, endDate);
        this.getActorState(startDate, endDate);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }
    getActorState = async (startDate, endDate) => {
        try {
            // const headers = {
            //     "Authorization": localStorage.getItem('token')
            // }
            let formData = new FormData();
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);

            const response = await apiHelper('admin/actor/getActorState', 'POST', formData, this.headers);
            let res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        totalActor: data.totalactor,
                        recentActor: data.recentactor,
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getCompanyState = async (startDate, endDate) => {
        try {

            let formData = new FormData();
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);

            const response = await apiHelper('admin/company/getCompanyState', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        totalCompany: data.totalcompany,
                        recentCompany: data.recentcompany,
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getRecentActorData = async (startDate, endDate) => {
        try {
            // const headers = {
            //     'Authorization': localStorage.getItem('token')
            // }
            let formData = new FormData();
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);
            const response = await apiHelper('admin/actor/getRecentActor', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        actorData: data
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getRecentCompanyData = async (startDate, endDate) => {
        try {

            let formData = new FormData();
            formData.append('startDate', startDate);
            formData.append('endDate', endDate);

            const response = await apiHelper('admin/company/getRecentCompany', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        companyData: data
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    companyStatusFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.status} onChange={(e) => this.changeCompanystatus(e, row)}>
            <option value="0">Pending</option>
            <option value="1">Active</option>
            <option value="2">Deactive</option>
            <option value="3">Verified</option>
        </select>
        )
    }
    actorStatusFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.status} onChange={(e) => this.changeActorstatus(e, row)}>
            <option value="0">Pending</option>
            <option value="1">Active</option>
            <option value="2">Deactive</option>
        </select>
        )
    }
    changeActorstatus = async (e, row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('status', e.target.value);

            const response = await apiHelper('admin/actor/changeActorStatus', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    changeCompanystatus = async (e, row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('status', e.target.value);

            const response = await apiHelper('admin/company/changeCompanyStatus', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    actorActionFormat = (cell, row) => {
        return (
            <>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteActor(row)} style={{ marginRight: '10px' }}>Delete</button>
                <button type="button" onClick={() => this.viewActorDetails(row.id)} className="btn btn-primary">View</button>
            </>
        )
    }
    companyActionFormat = (cell, row) => {
        return (
            <>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteCompany(row)} style={{ marginRight: '10px' }}>Delete</button>
                <button type="button" onClick={() => this.viewCompanyDetails(row.id)} className="btn btn-primary">View</button>
            </>
        )
    }
    deleteActor = async (row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            const response = await apiHelper('admin/actor/deleteActor', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const darr = this.state.actorData.filter((info) => info.id !== row.id);
                this.setState({
                    actorData: darr
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    deleteCompany = async (row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            const response = await apiHelper('admin/company/deleteCompany', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const darr = this.state.companyData.filter((info) => info.id !== row.id);
                this.setState({
                    companyData: darr
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    viewActorDetails = (id) => {
        this.props.history.push(`/admin/actor/details/${id}`)
    }
    viewCompanyDetails = (id) => {
        this.props.history.push(`/admin/company/details/${id}`)
    }
    filterDate = (event, picker) => {
        const start = moment(picker.startDate._d, 'YYYY-MM-DD H:mm').format('YYYY-MM-DD H:mm');
        const end = moment(picker.endDate._d, 'YYYY-MM-DD H:mm').format('YYYY-MM-DD H:mm');
        this.setState({
            startDate: start,
            endDate: end
        });
        this.getRecentActorData(start, end);
        this.getRecentCompanyData(start, end);
        this.getCompanyState(start, end);
        this.getActorState(start, end);
    }
    redirectUrl = (url)=>{
        const {history} = this.props;
        history.push(url);
    }
    render() {

        const columns = [
            { dataField: 'firstName', text: 'FirstName' },
            { dataField: 'lastName', text: 'LastName' },
            { dataField: 'email', text: 'Email' },
            { dataField: 'phoneNumber', text: 'PhoneNumber' },
            { dataField: 'status', text: 'Status', formatter: this.actorStatusFormat },
            { dataField: 'action', text: 'Action', formatter: this.actorActionFormat },
        ]

        const comapnyColumns = [
            { dataField: 'firstName', text: 'FirstName' },
            { dataField: 'lastName', text: 'LastName' },
            { dataField: 'displayName', text: 'DisplayName' },
            { dataField: 'email', text: 'Email' },
            { dataField: 'phoneNumber', text: 'PhoneNumber' },
            { dataField: 'status', text: 'Status', formatter: this.companyStatusFormat },
            { dataField: 'action', text: 'Action', formatter: this.companyActionFormat },
        ]

        const actorListData = {
            title: 'Actors List',
            columns,
            data: this.state.actorData,
        }

        const companyListData = {
            title: 'Company List',
            columns: comapnyColumns,
            data: this.state.companyData,
        }

        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 pull-right margin-b-20">
                                    <DateRangePicker
                                        initialSettings={{
                                            startDate: this.state.startDate, endDate: this.state.endDate, timePicker: true, timePicker24Hour: true, timePickerIncrement: 30, locale: {
                                                format: 'YYYY-MM-DD H:mm'
                                            }, maxDate: new Date()
                                        }} onApply={this.filterDate}
                                    >
                                        <input className="form-control" />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="panel panel-headline">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Overview</h3>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="metric" style={{cursor:'pointer'}} onClick={()=>this.redirectUrl('/admin/companies')}>
                                                <span className="icon"><i className="fa fa-building"></i></span>
                                                <p>
                                                    <span className="number">{this.state.totalCompany}</span>
                                                    <span className="title">All Companies</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="metric" style={{cursor:'pointer'}} onClick={()=>this.redirectUrl('/admin/actors')}>
                                                <span className="icon"><i className="fa fa-film"></i></span>
                                                <p>
                                                    <span className="number">{this.state.totalActor}</span>
                                                    <span className="title">All Actors</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="metric" style={{cursor:'pointer'}} onClick={()=>this.redirectUrl('/admin/companies')}>
                                                <span className="icon"><i className="fa fa-building"></i></span>
                                                <p>
                                                    <span className="number">{this.state.recentCompany}</span>
                                                    <span className="title">Recent Companies</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="metric" style={{cursor:'pointer'}} onClick={()=>this.redirectUrl('/admin/actors')}>
                                                <span className="icon"><i className="fa fa-film"></i></span>
                                                <p>
                                                    <span className="number">{this.state.recentActor}</span>
                                                    <span className="title">Recent Actors</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <DashboardTableComponent tableData={actorListData} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <DashboardTableComponent tableData={companyListData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </>
        );
    }
}
export default withRouter(props => <DashboardComponent {...props} />);