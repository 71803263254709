import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';
import { jwtDecode } from '../../../helper/jwt.helper';

class AddActorNote extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            editData: null,
            noteText: '',
            actorName: this.props.actorName,
            noteData: [],
            error: {
                noteText: ''
            },
            valid: {
                noteText: false
            }
        }
    }
    componentDidMount() {
        const tagOld = localStorage.getItem('actor_note');
        if (tagOld) {
            this.setState({
                noteText: tagOld,
                error: {
                    noteText: tagOld !== "" ? "" : "Note is required!"
                },
                valid: {
                    noteText: tagOld !== '' ? true : false
                }
            })
        }
        this.getActorNote();
    }
    getActorNote = async () => {
        try {
            const response = await apiHelper(`company/getActorNote/${this.props.id}`, 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    noteData: data.noteData
                })
                // toasterSuccess(res.message);
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        localStorage.setItem('actor_note', value)
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'noteText':
                valid.noteText = value.length > 0;
                error.noteText = valid.noteText ? "" : "Note is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }

    noteSubmit = async (e) => {
        try {
            e.preventDefault();
            const { noteText } = this.state;

            const formData = new FormData();
            formData.append('id', this.props.id);
            formData.append('noteText', noteText);

            const response = await apiHelper('company/addNotes', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                this.props.onHide();
                this.props.reloadNoteFun(true);
                localStorage.removeItem('actor_note')
                this.setState({
                    noteText: ''
                })
                this.getActorNote();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    closeNoteModal = () => {
        this.setState({
            isNoteModal: false
        })
    }
    deteleActorNote = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/deleteActorNote', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const { noteData } = this.state;
                const updateNoteData = noteData.filter(info => info.id !== id);
                this.props.reloadNoteFun(true);
                this.setState({
                    noteData: updateNoteData
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    editFunction = (data) => {
        // console.log(data);
        this.setState({
            editData: data,
            isEdit: true,
            noteText: data.text
        })
    }

    closeEditFunction = () => {
        this.setState({
            editData: null,
            isEdit: false
        })
    }

    updateSubmit = async (e) => {
        try {
            e.preventDefault();
            const { noteText, editData, noteData } = this.state;

            const formData = new FormData();
            formData.append('id', editData.id);
            formData.append('noteText', noteText);

            const response = await apiHelper('company/notes/update', 'PUT', formData, this.headers);
            const res = response.data;

            if (res.status) {
                // this.props.onHide();
                this.props.reloadNoteFun(true);
                const updateNoteData = noteData.map((info)=>{
                    if(info.id === editData.id){
                        info.text = noteText
                    }
                    return info;
                })
                this.setState({
                    noteText: '',
                    noteData:updateNoteData,
                    isEdit: false,
                    editData: null
                })
                this.getActorNote();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { noteText, noteData, actorName, valid } = this.state;
        // console.log(noteData);
        const disabled = !valid.noteText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Notes on {actorName}</h3>
                    </Modal.Header>
                    <form action="/" method="POST" onSubmit={this.state.isEdit ? this.updateSubmit : this.noteSubmit}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="noteText" className="form-control" value={noteText} onChange={this.inputHandler} placeholder="Add Notes here..." />
                                    </div>
                                </div>
                                {!this.state.isEdit ?
                                    <div className="col-sm-12">
                                        <ul className="note-list">
                                            {noteData.length > 0 ? noteData.map((info, index) =>
                                                <li key={index}>
                                                    <div className="note-content">
                                                        <p>{info.text} <span>{info.to_char} on Profile</span></p>
                                                    </div>
                                                    <button type="button" className="btn-dele" style={{ marginRight: '5px' }} onClick={() => this.editFunction(info)}><i className="fa fa-pencil"></i></button>
                                                    <button type="button" className="btn-dele" onClick={() => this.deteleActorNote(info.id)}><i className="fa fa-times"></i></button>
                                                </li>

                                            )
                                                :
                                                null
                                            }
                                        </ul>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.props.onHide}>CLOSE</button>
                            {/* {this.state.isEdit ? <button type="button" className="btn btn-default" onClick={this.closeEditFunction}>CLOSE EDIT</button> : null} */}
                            <button type="submit" className="btn btn-primary" disabled={disabled}>{this.state.isEdit ? 'UPDATE NOTE' : 'ADD NOTE'}</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    reloadNoteFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_NOTES_API, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(AddActorNote));