import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';

class SkillsComponent extends Component {
    _unmounted = false;

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            skillsArray: [],
        }
    }
    componentDidMount() {
        this._unmounted = true;
        this.getActorSkills(this.props.match.params.id);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getActorSkills = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/getActorSkills', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        skillsArray: data.skillData
                    })
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { skillsArray } = this.state;
        return (
            <div>
                <div className="titl-wrp">
                    <h4 className="res-tit">Special Skills</h4>
                </div>
                <div className="table-viwe">
                    <UlLi data={skillsArray} />
                </div>
            </div>
        );
    }
}

const UlLi = ({ data }) => {
    const [records, setRecords] = React.useState([]);

    React.useEffect(() => {
        if (records.length === 0) {
            setRecords(data)
        }
    }, [records, data])


    const renderCard = (record, index) => {
        return (<LICompo key={index} index={index} id={record.id} text={record.text} />);
    };

    return (<>
        <ul>{records.length > 0 ? records.map((record, i) => renderCard(record, i)) : <li></li>}</ul>
    </>);

}


const LICompo = (props) => {
    return (<li>
        <span>{props.text}
        </span>
    </li>);
}

export default withRouter(props => <SkillsComponent {...props} />);