import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class HomeFooter extends Component {
    render() {
        return (
            <footer className="footer-landing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h5>Our Mission</h5>
                            <p>To empower performing arts industry creatives to pursue their passions and thrive - through innovative technology, community and kindness.</p>
                        </div>
                        <div className="col-md-4">
                            <h5>Committed to Security</h5>
                            <p>Audition Revolution, through continuous development, exceeds industry security standards to ensure your data is safe and secure.</p>
                            <p>All companies, before being granted system access, are verified through our User Direct Verification Process for the safety of our talent.</p>
                        </div>
                        <div className="col-md-4">
                            <h5>Have questions? Contact Us!</h5>
                            <ul>
                                <li>Phone: <a href="tel:">678-960-8960</a></li>
                                <li>Email: <a href="mailto:support@auditionrevolution.com">support@auditionrevolution.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="foo-btm">
                    <div className="container">
                        <div className="fflex">
                            <p>Copyright ©  {moment().format('YYYY')}  auditionrevolution.com All rights reserved.</p>
                            <ul className="foo-list">
                                <li><Link to="/login">Company</Link></li>
                                <li><Link to="/login">Actor</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default HomeFooter;