import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './ActorProfileComponent.css';
import ResumeComponent from './ResumeComponent';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import ProfileModel from './ProfileModel';
import BreakdownModel from './BreakdownModel';
import ProfilePicComponent from './ProfilePicComponent';
import { connect } from 'react-redux';
import Carousel, { Modal, ModalGateway } from "react-images";
import * as ACTION_TYPES from './../../../redux/actions/type';
const DefaultProfilePic = "https://image.shutterstock.com/z/stock-vector-default-avatar-profile-icon-grey-photo-placeholder-518740741.jpg";

const Breakdown = ({ title, breakdownArray, width, defaultArray }) => {

    let newArray = [];

    for (var i = 0; i < defaultArray.length; i++) {
        if (breakdownArray.length > 0) {
            breakdownArray.find(info => {
                if (info === defaultArray[i]) {
                    newArray.push(info);
                }
            });
        }
    }


    return (
        <>
            <p>{title}: </p>
            <div className="break-down">
                <div className="flet-wrp">
                    {newArray.map(
                        (a, i) =>
                            a && (
                                <div key={`${i}${a}`} className="custom-chip">
                                    <span>{a}</span>
                                </div>
                            )
                    )}
                </div>
            </div>
        </>
    );
};


const styleFn = (styleObj) => ({ ...styleObj, zIndex: 100 });

const LightboxModal = props => {
    const { open, handleClose, images, currentIndex } = props;
    return (
        <ModalGateway>
            {open ? (
                <Modal
                    styles={{ blanket: styleFn, positioner: styleFn }}
                    onClose={handleClose}
                >
                    <Carousel views={images} currentIndex={currentIndex} />
                </Modal>
            ) : null}
        </ModalGateway>
    );
};
class ActorProfileComponent extends Component {
    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            experienceList: null,
            tabsActive: 0,
            profileData: null,
            isProfileModal: false,
            isBreakdownModal: false,
            profileImg: props.userImg,
            profileImgModel: false
        }
    }
    openHeadShot = (url) => {
        this.setState({
            profileImgModel: true
        })

    }
    closeHeadShot = () => {
        this.setState({
            profileImgModel: false
        })
    }
    componentDidMount() {
        this._unmounted = true;
        this.getProfileData();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const { userImg } = this.props;
        if (prevProps.userImg !== userImg) {
            this.setState({
                profileImg: userImg
            });

            return true;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { userImg, userInfoUpdated } = this.props;
        if(prevProps.userInfoUpdated !== userInfoUpdated){
            this.getProfileData();
        }
        if (snapshot !== null) {
            // console.log(snapshot);
            // this.setState({
            //     profileImg:userImg
            // })
        }
    }

    // shouldComponentUpdate(nextProps){
    //     const { userImg } = this.props;
    //     if(nextProps.userImg !== userImg){
    //         this.setState({
    //             profileImg:userImg
    //         })
    //     }
    //     return true;
    // }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getProfileData = async () => {
        try {

            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }

            const response = await apiHelper('actor/getProfile', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                var result = data.experienceOrder.substring(1, data.experienceOrder.length - 1);
                // console.log(result.split(','));
                if (this._unmounted) {
                    if (data.profilePic) {
                        this.props.updateUserImg(data.profilePic)
                    }
                    this.setState({
                        profileData: data,
                        experienceList: result.split(',')
                    })
                }
                this.props.userUpdateFun(false);
                // toasterSuccess(res.message);
            } else {

                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    tabHandler = (tabVal) => {
        this.setState({
            tabsActive: tabVal
        })
    }
    inchToFeet = (val) => {
        const feet = Math.floor(val / 12);
        const inches = val % 12;
        return feet + " Feet " + inches + ' Inches';
    }
    inchToFeet2 = (val) => {
        const feet = Math.floor(val / 12);
        const inches = val % 12;
        return { feet: feet, inches: inches };
    }
    phoneNumber = (phoneNumber) => {
        const phoneString = phoneNumber.toString().split("");
        const areaCode = phoneString.slice(0, 3).join("");
        const phone1 = phoneString.slice(3, 6).join("");
        const phone2 = phoneString.slice(6, 10).join("");
        return `(${areaCode}) ${phone1}-${phone2}`;
    }
    openProfileModal = (e) => {
        e.preventDefault();
        // this.noRedirectHash();
        this.setState({
            isProfileModal: true
        })
    }

    closeProfileModal = () => {
        this.setState({
            isProfileModal: false
        })
    }

    openBreakdownModal = (e) => {
        e.preventDefault();
        // this.noRedirectHash();
        this.setState({
            isBreakdownModal: true
        })
    }

    closeBreakdownModal = () => {
        this.setState({
            isBreakdownModal: false
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    noRedirectHash = (e) => e.preventDefault();

    render() {

        const { tabsActive, profileImg, profileData } = this.state;
        // console.log(profileData);
        const BreakdownData = {
            "ageRange": (profileData && profileData.ageRange) ? profileData.ageRange : [],
            "ethnicity": (profileData && profileData.ethnicity) ? profileData.ethnicity : [],
            "gender": (profileData && profileData.gender) ? profileData.gender : [],
            "unions": (profileData && profileData.unions) ? profileData.unions : [],
            "vocalRange": (profileData && profileData.vocalRange) ? profileData.vocalRange : []
        }
        let heightFeet = 0;
        let feetInches = 0;

        if (profileData && profileData.heightInches) {
            const res = this.inchToFeet2(profileData.heightInches);
            heightFeet = res.feet;
            feetInches = res.inches;
        }
        const UserData = {
            "firstName": (profileData && profileData.firstName) ? profileData.firstName : '',
            "lastName": (profileData && profileData.lastName) ? profileData.lastName : '',
            "phoneNumber": (profileData && profileData.phoneNumber) ? profileData.phoneNumber : '',
            "city": (profileData && profileData.city) ? profileData.city : '',
            "state": (profileData && profileData.state) ? profileData.state : '',
            "heightFeet": heightFeet,
            "feetInches": feetInches,
            "hairColor": (profileData && profileData.hairColor) ? profileData.hairColor : '',
            "eyeColor": (profileData && profileData.eyeColor) ? profileData.eyeColor : '',
            "website": (profileData && profileData.website) ? profileData.website : '',
            "representation": (profileData && profileData.representation) ? profileData.representation : ''
        }
        const unionDefaultArray = ['Non-Union', 'AEA', 'AEA-EMC', 'Other'];
        const vocalRangeDefaultArray = ['Soprano', 'Mezzo Soprano Belter', 'Mezzo Soprano', 'Alto', 'Tenor', 'Baritenor', 'Baritone', 'Bass'];
        const ethnicityDefaultArray = ['African American', 'Asian', 'Caucasian', 'Hispanic', 'Latino', 'Native American', 'Alaskan Native', 'Hawaiian', 'Middle Eastern', 'North African', 'Multi-Cultural'];
        const genderDefaultArray = ['Female', 'Male', 'Non-Binary', 'Other'];
        const ageRangeDefaultArray = ['Child', 'Teen', 'Teens-20', '20s-30s', '30s-40s', '40s-50s', '50s-60s', '60s-70s', 'Over 70'];
        return (
            this.state.profileData &&
            <>
                <div className="profile-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="profile-pic" style={{marginTop:'30px'}}>
                                    <img data-cy="profile-picture" src={profileImg !== '' ? profileImg : DefaultProfilePic} alt="user" onClick={this.openHeadShot} />
                                </div>
                            </div>
                            <div className="col-sm-8 ">
                                <div className="flex-bloc">
                                    <div className="profile-info2 p-2">
                                        <h2 className="title-name title-edit"><span>{this.state.profileData && this.state.profileData.firstName} {this.state.profileData && this.state.profileData.lastName}</span><a href="/#" onClick={this.openProfileModal} title="Update General Attributes"><i className="fa fa-edit"></i></a></h2>
                                        <div className="visible-mob">
                                            {this.state.profileData && this.state.profileData.representation ? <h6 className="title-address">Representation: {this.state.profileData.representation}</h6> : null}
                                            <h6 className="title-address">Email: <a href={`mailto:${this.state.profileData.email}`} style={{color:'#29b6f6'}}>{this.state.profileData.email}</a></h6>
                                            <h6 className="title-address">Phone: {this.state.profileData && this.state.profileData.phoneNumber ? this.phoneNumber(this.state.profileData.phoneNumber) : ''}</h6>
                                        </div>
                                        <h6 className="title-address">{this.state.profileData && this.state.profileData.city}, {this.state.profileData && this.state.profileData.state}</h6>
                                        <h6 className="title-address">{this.state.profileData && this.state.profileData.heightInches ? this.inchToFeet(this.state.profileData.heightInches) : '--'}</h6>
                                        <h6 className="title-address">Eye: {this.state.profileData && this.state.profileData.eyeColor}, Hair: {this.state.profileData && this.state.profileData.hairColor}</h6>
                                        <div className="visible-mob">
                                            {this.state.profileData && this.state.profileData.website ? <h6 className="title-address">
                                                <a
                                                    target={"_blank"}
                                                    rel="noopener noreferrer"
                                                    href={this.state.profileData.website}
                                                    style={{color:'#29b6f6'}}
                                                >
                                                    Personal Site
                                                </a>
                                            </h6> : null}
                                        </div>
                                    </div>
                                    <div className="representation2 p-2 hide-mob">
                                        {this.state.profileData && this.state.profileData.representation ? <h6 className="title-address">Representation: {this.state.profileData.representation}</h6> : null}
                                        <h6 className="title-address">Email: <a href={`mailto:${this.state.profileData.email}`} style={{color:'#29b6f6'}}>{this.state.profileData.email}</a></h6>
                                        <h6 className="title-address">Phone: {this.state.profileData && this.state.profileData.phoneNumber ? this.phoneNumber(this.state.profileData.phoneNumber) : ''}</h6>
                                        {this.state.profileData && this.state.profileData.website ? <h6 className="title-address">
                                            <a
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                href={this.state.profileData.website}
                                                style={{color:'#29b6f6'}}
                                            >
                                                Personal Site
                                            </a>
                                        </h6> : null}
                                    </div>
                                </div>
                                <div className="actor-wr">
                                    <h6 className="act-tit">Actor Breakdown   <a href="/#" onClick={this.openBreakdownModal} title="Update Your Character Types"><i className="fa fa-edit"></i></a></h6>
                                    <div className="flex-bloc justify-content-start align-start">
                                        <div className="rang-wrp">
                                            <div className="sect">
                                                <Breakdown title="Unions" breakdownArray={this.state.profileData ? this.state.profileData.unions : []} defaultArray={unionDefaultArray} />
                                            </div>
                                            <div className="sect">
                                                <Breakdown title="Age Range" breakdownArray={this.state.profileData ? this.state.profileData.ageRange : []} defaultArray={ageRangeDefaultArray} />
                                            </div>
                                            <div className="sect">
                                                <Breakdown title="Vocal Range" breakdownArray={this.state.profileData ? this.state.profileData.vocalRange : []} defaultArray={vocalRangeDefaultArray} />
                                            </div>

                                        </div>

                                        <div className="rang-wrp">
                                            <div className="sect">
                                                <Breakdown title="Gender" breakdownArray={this.state.profileData ? this.state.profileData.gender : []} defaultArray={genderDefaultArray} />
                                            </div>

                                            <div className="sect">
                                                <Breakdown title="Ethnicity" breakdownArray={this.state.profileData ? this.state.profileData.ethnicity : []} defaultArray={ethnicityDefaultArray} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-sm-12">
                                <div className="lik-werp">
                                    <h6>Actor Breakdown</h6>
                                    <p><a href="/#" onClick={this.openBreakdownModal}>Update Your Character Types</a></p>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="lik-werp">
                                    <h6>Update User</h6>
                                    <p><a href="/#" onClick={this.openProfileModal}>Update General Attributes</a></p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div >

                <div className="tab-blos">
                    <ul className="tbs-ul">
                        <li className={tabsActive === 0 ? "active" : ""} onClick={() => this.tabHandler(0)}>General Resume</li>
                        <li className={tabsActive === 1 ? "active" : ""} onClick={() => this.tabHandler(1)}>Photos</li>
                    </ul>
                    <div className="blo-tabs tab-resume">
                        {tabsActive === 0 ? <ResumeComponent experienceList={this.state.experienceList} /> : null}
                        {tabsActive === 1 ? <ProfilePicComponent profilePic={this.state.profileData.profilePic ? this.state.profileData.profilePic : null} /> : null}
                    </div>
                </div>
                {this.state.isProfileModal ? <ProfileModel show={this.state.isProfileModal} onHide={this.closeProfileModal} data={UserData} /> : null}
                <BreakdownModel show={this.state.isBreakdownModal} onHide={this.closeBreakdownModal} data={BreakdownData} />
                <LightboxModal open={this.state.profileImgModel} handleClose={this.closeHeadShot} images={[{ src: profileImg !== '' ? profileImg : DefaultProfilePic }]} currentIndex={0} />
            </>
        );
    }
}

const mapStateToProps = state => ({
    userImg: state.userImg.userImg,
    userInfoUpdated:state.profileData.userInfoUpdated
});

const mapDispatchToProps = dispatch => ({
    updateUserImg: (img) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: img }),
    userUpdateFun: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_USER_INFO, payload: bool })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActorProfileComponent));