import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
// import { apiHelper } from '../../../helper/api.helper';
import { toasterError } from '../../../helper/toaster.helper';

// const headers = {
//     'Authorization': localStorage.getItem('userToken')
// }
class ExperienceModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            experienceType: '',
            project: '',
            role: '',
            company: '',
            director: '',
            error: {
                experienceType: '',
                project: '',
                role: '',
                company: '',
                director: ''
            },
            valid: {
                experienceType: false,
                project: false,
                role: false,
                company: false,
                director: false
            }
        }
    }

    componentDidMount() {
        const { isEdit } = this.props;
        if (isEdit) {
            this.updateField();
        }
    }

    componentDidUpdate(prevProps) {
        const { show, isEdit } = this.props;
        if (show !== prevProps.show) {
            if (show) {
                if (!isEdit) {
                    this.setState({
                        experienceType: '',
                        project: '',
                        role: '',
                        company: '',
                        director: '',
                        error: {
                            experienceType: '',
                            project: '',
                            role: '',
                            company: '',
                            director: ''
                        },
                        valid: {
                            experienceType: false,
                            project: false,
                            role: false,
                            company: false,
                            director: false
                        }
                    })
                }
            }
        }
    }

    updateField = () => {
        const data = this.props.data;
        const { row: { values }, type } = data;
        // console.log(data);
        this.setState({
            experienceType: type,
            project: values.project,
            role: values.role,
            company: values.company,
            director: values.director,
            error: {
                experienceType: '',
                project: '',
                role: '',
                company: '',
                director: ''
            },
            valid: {
                experienceType: type ? true : false,
                project: values.project ? true : false,
                role: values.role ? true : false,
                company: values.company ? true : false,
                director: values.director ? true : false
            }
        })
    }

    experienceSubmit = async (e) => {
        try {
            e.preventDefault();

            const { project, role, company, director, experienceType, valid } = this.state;
            const { isEdit, data } = this.props;
            let formdata = null;
            if (isEdit) {
                formdata = { id: data?.row?.original?.id, userId: data?.row?.original?.userId, project, role, company, director, experienceType };
            } else {
                formdata = { project, role, company, director, experienceType };
            }
            const validField = valid.project || valid.role || valid.company || valid.director;

            if (validField) {

                this.props.submitFun(formdata);
            } else {
                toasterError("All fields is required.");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    experienceSaveSubmit = async (e) => {
        try {
            e.preventDefault();

            const { project, role, company, director, experienceType, valid } = this.state;

            let formdata = { isMore: true, project, role, company, director, experienceType };

            const validField = valid.project || valid.role || valid.company || valid.director;

            if (validField) {

                this.props.submitFun(formdata);
                this.setState({
                    experienceType: '',
                    project: '',
                    role: '',
                    company: '',
                    director: '',
                    error: {
                        experienceType: '',
                        project: '',
                        role: '',
                        company: '',
                        director: ''
                    },
                    valid: {
                        experienceType: false,
                        project: false,
                        role: false,
                        company: false,
                        director: false
                    }
                })
            } else {
                toasterError("All fields is required.");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'experienceType':
                valid.experienceType = value.length > 0;
                error.experienceType = valid.experienceType ? "" : "Experience type is required!";
                break;
            case 'project':
                valid.project = value.length > 0;
                error.project = valid.project ? "" : "Project is required!";
                break;
            case 'role':
                valid.role = value.length > 0;
                error.role = valid.role ? "" : "Role is required!";
                break;
            case 'company':
                valid.company = value.length > 0;
                error.company = valid.company ? "" : "Company is required!";
                break;
            case 'director':
                valid.director = value.length > 0;
                error.director = valid.director ? "" : "Director is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    render() {

        const { project, role, company, director, valid } = this.state;
        const disabled = !valid.project || !valid.role || !valid.company || !valid.director || !valid.experienceType;

        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>{`${this.props.isEdit ? 'Update' : 'Add'} An Experience`}</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="/" method="post">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Choose Experience Type<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="theatreExperience"><input type="radio" name="experienceType" id="theatreExperience" defaultValue="theatreExperience" checked={this.state.experienceType === 'theatreExperience'} onChange={this.inputHandler} /> Theatre</label>
                                            <label htmlFor="musicalTheatreExperience"><input type="radio" name="experienceType" id="musicalTheatreExperience" defaultValue="musicalTheatreExperience" checked={this.state.experienceType === 'musicalTheatreExperience'} onChange={this.inputHandler} /> Musical Theatre</label>
                                            <label htmlFor="operaExperience"><input type="radio" name="experienceType" id="operaExperience" defaultValue="operaExperience" checked={this.state.experienceType === 'operaExperience'} onChange={this.inputHandler} /> Opera</label>
                                            <label htmlFor="filmExperience"><input type="radio" name="experienceType" id="filmExperience" defaultValue="filmExperience" checked={this.state.experienceType === 'filmExperience'} onChange={this.inputHandler} /> Film</label>
                                            <label htmlFor="televisionExperience"><input type="radio" name="experienceType" id="televisionExperience" defaultValue="televisionExperience" checked={this.state.experienceType === 'televisionExperience'} onChange={this.inputHandler} /> Television</label>
                                            <label htmlFor="commercialExperience"><input type="radio" name="experienceType" id="commercialExperience" defaultValue="commercialExperience" checked={this.state.experienceType === 'commercialExperience'} onChange={this.inputHandler} /> Commercial</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="project" className="form-control" placeholder="Project Name*" value={project} onChange={this.inputHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="role" className="form-control" placeholder="Role Name*" value={role} onChange={this.inputHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="company" className="form-control" placeholder="Company Name*" value={company} onChange={this.inputHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="director" className="form-control" placeholder="Director*" value={director} onChange={this.inputHandler} />
                                    </div>
                                </div>
                            </div>

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.experienceSubmit}>{this.props.isEdit ? 'Update' : 'Save & Close'}</button>
                        {!this.props.isEdit ? <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.experienceSaveSubmit}>{'Save & Add More'}</button> : null}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withRouter(props => <ExperienceModel {...props} />);