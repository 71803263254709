import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";

class ServiceDirectoryViewModal extends Component {
  headers = {
    Authorization: localStorage.getItem("userToken"),
  };
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.data.firstName,
      about: props.data.about,
      city: props.data.city,
      companyName: props.data.companyName,
      email: props.data.email,
      lastName: props.data.lastName,
      phone: props.data.phone,
      state: props.data.state,
      status: props.data.status,
      website: props.data.website,
      services: props.data.services,
    };
  }

  closeServiceDirectoryView = () => {
    this.setState({
      isServiceDirectoryView: false,
    });
  };

  render() {
    const {
      about,
      city,
      companyName,
      email,
      firstName,
      lastName,
      phone,
      state,
      status,
      website,
      services
    } = this.state;
    return (
      <>
        <Modal
          show={this.props.show}
          className="file_modal"
          onHide={this.props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h3>Service Directory Details</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
                <div className="col-md-6">
                  <label>First Name : </label>
                  {firstName}
                </div>
                <div className="col-md-6">
                  <label>Last Name : </label>
                  {lastName}
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <label>Company Name : </label>
                  {companyName}
                </div>
                <div className="col-md-6">
                  <label>Email : </label>
                  {email}
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <label>Phone : </label>
                  {phone}
                </div>
                <div className="col-md-6">
                  <label>website : </label>
                  {website}
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <label>City : </label>
                  {city}
                </div>
                <div className="col-md-6">
                  <label>State : </label>
                  {state}
              </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                  <label>Services : </label>
                  {
                    <div className="break-down">
                    {services.map(
                        (a, i) =>
                            a && (
                                <div
                                    key={`${i}`}
                                >
                                    <div className="custom-chip" style={{marginLeft:"7px"}}>
                                        <span>{a.service}</span>
                                    </div>
                                </div>
                            )
                    )}
                </div>
                  }
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                  <label>About : </label>
                  {about}
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.props.onHide}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default withRouter((props) => <ServiceDirectoryViewModal {...props} />);
