import * as ACTION_TYPES from '../actions/type';

const initialState = {
    searchData: [],
    searchValue: '',
    // searchType: '0',
    currentPage: 0,
    isViewProfile: false,
    totalActorCount: 0,
    unionStatus: [],
    gender: [],
    age: [],
    ethnicity: [],
    voiceType: [],
    tag: '',
    keywordType: '',
    keyword: '',
    keywordType2: '',
    keyword2: '',
}

const CompanyReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_DATA:
            return {
                ...state,
                searchData: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload
            }
        // case ACTION_TYPES.UPDATE_COMPANY_SEARCH_TYPE:
        //     return {
        //         ...state,
        //         searchType: action.payload
        //     }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_CURRENTPAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case ACTION_TYPES.IS_COMPANY_VIEW_ACTOR:
            return {
                ...state,
                isViewProfile: action.payload
            }
        case ACTION_TYPES.TOTAL_ACTOR_COUNT:
            return {
                ...state,
                totalActorCount: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_UNIONSTATUS:
            return {
                ...state,
                unionStatus: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_GENDER:
            return {
                ...state,
                gender: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_AGE:
            return {
                ...state,
                age: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_ETHNICITY:
            return {
                ...state,
                ethnicity: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_VOICETYPE:
            return {
                ...state,
                voiceType: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_TAG:
            return {
                ...state,
                tag: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD_TYPE:
            return {
                ...state,
                keywordType: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD:
            return {
                ...state,
                keyword: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD_TYPE2:
            return {
                ...state,
                keywordType2: action.payload
            }
        case ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD2:
            return {
                ...state,
                keyword2: action.payload
            }
        default:
            return state;
    }
}

export default CompanyReducers;