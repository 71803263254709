import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { apiHelper } from './../../../helper/api.helper';
import { toasterError, toasterSuccess } from './../../../helper/toaster.helper';
import { jwtDecode } from './../../../helper/jwt.helper';
import Modal from 'react-bootstrap/Modal';
import SampleCSV from './../../../assets/sample/upload_company_actors_sample.csv';

class CompaniesComponent extends Component {
    headers = {
        'Authorization': localStorage.getItem('token')
    }
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isUploadModal: false,
            user_id: '',
            filesArr: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getCompaniesData();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getCompaniesData = async () => {
        try {
            const response = await apiHelper('admin/company/getAllCompany', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                console.log(data);
                if (this._unmounted) {
                    this.setState({
                        data: data
                    })
                    // toasterSuccess(res.message);
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    statusFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.status} onChange={(e) => this.changeCompanystatus(e, row)}>
            <option value="0">Pending</option>
            <option value="1">Active</option>
            <option value="2">Deactive</option>
            <option value="3">Verified</option>
        </select>
        )
    }
    changeType = async (e, row) => {
        const formData = new FormData();
        formData.append('id', row.id);
        formData.append('type', e.target.value);

        const response = await apiHelper('admin/company/changeType', 'POST', formData, this.headers);
        const res = response.data;
        if (res.status) {
            const targetVal = e.target.value;
            const companyArray = this.state.data;
            const updatedArray = companyArray.map((info) => {
                if (info.id === row.id) {
                    info.userType = targetVal
                }
                return info;
            })
            this.setState({
                data: updatedArray
            })
            //toasterSuccess(res.message);
        } else {
            toasterError(res.message);
        }
    }
    changeCompanystatus = async (e, row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('status', e.target.value);

            const response = await apiHelper('admin/company/changeCompanyStatus', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const targetVal = e.target.value;
                const companyArray = this.state.data;
                const updatedArray = companyArray.map((info) => {
                    if (info.id === row.id) {
                        info.status = targetVal
                    }
                    return info;
                })
                this.setState({
                    data: updatedArray
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    actionFormat = (cell, row) => {
        return (
            <>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteCompany(row)} style={{ marginRight: '10px' }}>Delete</button>
                <button type="button" onClick={() => this.viewCompanyDetails(row.id)} className="btn btn-primary" style={{ marginRight: '10px' }}>View</button>
                <button type="button" className="btn btn-success" onClick={() => this.openFileModal(row.id)}><i className="fa fa-paperclip"></i></button>
            </>
        )
    }
    typeFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.userType} onChange={(e) => this.changeType(e, row)}>
            <option value="{actor,theatre}">Actor/Company</option>
            <option value="{theatre}">Company</option>
        </select>
        )
    }
    openFileModal = (id) => {
        this.setState({
            isUploadModal: true,
            user_id: id
        })
    }

    uploadFiles = (e) => {
        const file = e.target.files[0];
        this.setState({
            filesArr: file
        })
    }

    fileSubmit = async () => {
        try {

            const file = this.state.filesArr;
            let formData = new FormData();
            formData.append('id', this.state.user_id);
            formData.append('actorFile', file);

            const response = await apiHelper('admin/company/uploadActor', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.setState({
                    isUploadModal: false
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }

    closeUploadModal = () => {
        this.setState({
            isUploadModal: false
        })
    }
    deleteCompany = async (row) => {
        try {
            if (window.confirm("Are you sure want to delete this company?")) {
                const formData = new FormData();
                formData.append('id', row.id);
                const response = await apiHelper('admin/company/deleteCompany', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    const darr = this.state.data.filter((info) => info.id !== row.id);
                    this.setState({
                        data: darr
                    })
                    //toasterSuccess(res.message);
                } else {
                    toasterError(res.message);
                }
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    viewCompanyDetails = (id) => {
        this.props.history.push(`/admin/company/details/${id}`)
    }
    render() {
        const { SearchBar } = Search;

        const columns = [
            { dataField: 'firstName', text: 'FirstName' },
            { dataField: 'lastName', text: 'LastName' },
            // { dataField: 'displayName', text: 'DisplayName' },
            { dataField: 'email', text: 'Email' },
            // { dataField: 'phoneNumber', text: 'PhoneNumber' },
            { dataField: 'userType', text: 'Type', formatter: this.typeFormat },
            { dataField: 'status', text: 'Status', formatter: this.statusFormat },
            { dataField: 'action', text: 'Action', formatter: this.actionFormat },
        ]
        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Companies List</h3>
                                            {/* <div className="right">
                                                <button type="button" className="btn-toggle-collapse"><i className="lnr lnr-chevron-up"></i></button>
                                                <button type="button" className="btn-remove"><i className="lnr lnr-cross"></i></button>
                                            </div> */}
                                        </div>
                                        <div className="panel-body no-padding">
                                            <ToolkitProvider
                                                keyField='id' data={this.state.data} columns={columns}
                                                search
                                            >
                                                {
                                                    props => (
                                                        <div>
                                                            {/* <h3>Input something at below input field:</h3> */}
                                                            <div className="customSearch">
                                                                <SearchBar {...props.searchProps} />
                                                            </div>
                                                            <BootstrapTable
                                                                {...props.baseProps} classes="table table-striped" pagination={paginationFactory()}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <Modal show={this.state.isUploadModal} className="file_modal" onHide={this.closeUploadModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Upload Actor CSV</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-wrap">
                            <div className="file-div">
                                <input id="uploadFil" type="file" name="file" onChange={this.uploadFiles} />
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <a href={SampleCSV} download>Download Sample CSV</a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.closeUploadModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.fileSubmit}>Save</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default withRouter(props => <CompaniesComponent {...props} />);