import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableCompoent from './../ProfileComponent/TableCompoent';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import TrainingComponent from './TrainingComponent';
import SkillsComponent from './SkillsComponent';

class ResumeComponent extends Component {

    _unmounted = false;
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            listOftables: [],
            theatreExperience: [],
            musicalTheatreExperience: [],
            filmExperience: [],
            televisionExperience: [],
            commercialExperience: [],
            operaExperience: []
        }
    }

    componentDidMount() {
        this._unmounted = true;

        this.getExperience(this.props.match.params.id);
    }

    getExperience = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/getActorExperience', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        theatreExperience: data.theatreExperience,
                        musicalTheatreExperience: data.musicalTheatreExperience,
                        filmExperience: data.filmExperience,
                        televisionExperience: data.televisionExperience,
                        commercialExperience: data.commercialExperience,
                        operaExperience: data.operaExperience
                    });
                    this.updateListOrderArray();
                }
                // toasterSuccess(res.message);
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    updateListOrderArray = () => {
        const listOftables = this.props.experienceList.map(info => {
            let dataArr = [];
            let titleTable = '';
            switch (info) {
                case 'theatreExperience':
                    titleTable = 'Theatre';
                    dataArr = this.state.theatreExperience;
                    break;
                case 'musicalTheatreExperience':
                    titleTable = 'Musical Theatre';
                    dataArr = this.state.musicalTheatreExperience;
                    break;
                case 'operaExperience':
                    titleTable = 'Opera';
                    dataArr = this.state.operaExperience;
                    break;
                case 'filmExperience':
                    titleTable = 'Film';
                    dataArr = this.state.filmExperience;
                    break;
                case 'televisionExperience':
                    titleTable = 'Television';
                    dataArr = this.state.televisionExperience;
                    break;
                case 'commercialExperience':
                    titleTable = 'Commercial';
                    dataArr = this.state.commercialExperience;
                    break;
                default:
                    break;
            }
            return {
                type: info,
                tableTitle: titleTable,
                isOrdering: false,
                columns: [
                    { Header: 'Project', accessor: 'project' },
                    { Header: 'Role', accessor: 'role' },
                    { Header: 'Company', accessor: 'company' },
                    { Header: 'Director', accessor: 'director' }
                ],
                data: dataArr
            }
        })
        this.setState({
            listOftables: listOftables
        })
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    render() {
        const { listOftables } = this.state;

        return (
            <div className="resu-wr">
                <div className="resum-blo">
                    <div>
                        <div className="col-sm-8">
                            <div className="titl-wrp">
                                <h4 className="res-tit">Experience</h4>
                            </div>
                            <DragDropContext>
                                <Droppable>
                                    {(provided, snapshot) => (
                                        <ul className="layout_tables" {...provided.droppableProps} ref={provided.innerRef}>
                                            {listOftables.length > 0 ?
                                                listOftables.map((list, i) =>
                                                    list.data.length > 0 ?
                                                        <li key={i}>
                                                            <LICompo
                                                                index={i}
                                                                id={list.type}
                                                                dataArray={list}

                                                            />
                                                        </li>
                                                        :
                                                        null
                                                )
                                                :
                                                <li>No record found.</li>
                                            }
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="col-sm-4">
                            <TrainingComponent />
                            <SkillsComponent />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}



const LICompo = ({ dataArray }) => {
    return (
        <>
            <div className="table-viwe">
                <div className="table-header">
                    <h5 className="table-title">{dataArray.tableTitle}</h5>
                </div>
                <TableCompoent columns={dataArray.columns} data={dataArray.data} isRedorder={dataArray.isOrdering} tableType={dataArray.type} />
            </div>
        </>);
}

export default withRouter(props => <ResumeComponent {...props} />);