import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import VerifyComponent from './VerifyComponent';
import ActorProfileComponent from './ActorProfileComponent';
import ProfileSteps from './ProfileSteps';
import './ProfileComponent.css';
import { jwtDecode } from '../../../helper/jwt.helper';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError } from '../../../helper/toaster.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';

class ProfileComponent extends Component {
    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            status: '0',
            activeStep: 0,
            stepCompleted: false,
            experienceOrder: [],
            profileData: null
            // userType: null,
        }
    }

    // UNSAFE_componentWillMount(){
    //     this.getProfileData();
    //     const userToken = localStorage.getItem('userToken');
    //     const tokenData = jwtDecode(userToken);

    //     if (userToken && tokenData) {
    //         this.setState({
    //             status: tokenData.status,
    //             // userType: tokenData.userType,
    //         })
    //     }
    // }

    componentDidMount() {
        this._unmounted = true;
        this.getProfileData();
        const userToken = localStorage.getItem('userToken');
        const tokenData = jwtDecode(userToken);
        if (userToken && tokenData) {
            if (this._unmounted) {
                this.setState({
                    status: Number(tokenData.status),
                    // userType: tokenData.userType,
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getProfileData = async () => {
        try {

            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }

            const response = await apiHelper('actor/getProfile', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                // console.log(data);
                if (this._unmounted) {
                    this.props.updateProfileData(data);
                    this.setState({
                        profileData: data
                    })
                }
                if (data.profilePic) {
                    this.props.updateUserImg(data.profilePic)
                }

                if (!data.firstName || !data.lastName || !data.phoneNumber || !data.city || !data.state || (data.heightInches === "" || data.heightInches < 0) || !data.hairColor || !data.eyeColor) {
                    if (this._unmounted) {
                        this.setState({
                            activeStep: 0
                        })
                    }
                } else if (data.unions.length <= 0 || data.vocalRange.length <= 0 || data.gender.length < 0 || data.ethnicity.length <= 0 || data.ageRange.length <= 0) {
                    if (this._unmounted) {
                        this.setState({
                            activeStep: 1
                        })
                    }
                } else if (!data.profilePic) {
                    if (this._unmounted) {
                        this.setState({
                            activeStep: 2
                        })
                    }
                } else {
                    if (this._unmounted) {
                        this.setState({
                            activeStep: 0,
                            stepCompleted: true
                        })
                    }
                }

            } else {

                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }


    }
    render() {
        // console.log(this.state.activeStep);
        return (
            <>
                {this.state.status === 0 ?
                    <VerifyComponent type="actor" status={this.state.status} />
                    :
                    this.state.stepCompleted ?
                        <ActorProfileComponent />
                        :
                        <ProfileSteps currentStep={this.state.activeStep} />
                }

            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateUserImg: (img) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: img }),
    updateProfileData: (data) => dispatch({ type: ACTION_TYPES.USER_PROFILE_DATA, payload: data })
})

export default withRouter(connect(null, mapDispatchToProps)(ProfileComponent));