import React, { Component } from 'react';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
import * as places from "places.js";
import { apiHelper } from '../../../helper/api.helper';
import { toasterError } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import * as validationHelper from '../../../helper/validation.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { withRouter } from 'react-router-dom';
//import { Row, Col } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";

class ProfileSteps extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);

        this.state = {
            activeStep: props.currentStep,
            isStepCompleted: false,
            profileData: this.props.profileData
        }
    }

    stepsCompleted = () => {
        this.setState({
            isStepCompleted: true
        })
    }

    static getDerivedStateFromProps(props, state) {

        if (!state.isStepCompleted) {
            state.activeStep = props.currentStep;
        }
        // console.log(props);
        return true;
    }

    componentDidMount() {
        this.setState({
            activeStep: this.props.currentStep
        })
    }

    onNextHandler = () => {
        this.stepsCompleted();
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }))
    }

    onPrevHandler = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }))
    }

    render() {

        const { activeStep } = this.state;
        // console.log(this.props.data);
        const finishIcon = <i className="fa fa-check"></i>;
        return (
            <section className="bg_steps">
                <div className="container">
                    <div className="steps_wrap">
                        <Steps current={activeStep} size="big" direction="horizontal" icons={{ finish: finishIcon }}>
                            <Steps.Step />
                            <Steps.Step />
                            <Steps.Step />
                            <Steps.Step />
                        </Steps>
                        {activeStep === 0 && <Step1 onNext={this.onNextHandler} data={this.props.profileData} />}
                        {activeStep === 1 && <Step2 onPrev={this.onPrevHandler} onNext={this.onNextHandler} data={this.props.profileData} />}
                        {activeStep === 2 && <Step3 onPrev={this.onPrevHandler} onNext={this.onNextHandler} updateUserImg={this.props.updateUserImg} data={this.props.profileData} />}
                        {activeStep === 3 && <Step4 onPrev={this.onPrevHandler} onNext={this.onNextHandler} />}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    profileData: state.profileData.profileData
})

const mapDispatchToProps = dispatch => ({
    updateUserImg: (img) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: img })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileSteps));

class Step1 extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            cityState: '',
            city: '',
            state: '',
            heightFeet: '',
            feetInches: '',
            hairColor: "",
            eyeColor: "",
            website: "",
            representation: "",
            error: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                cityState: '',
                heightFeet: 0,
                feetInches: 0,
                hairColor: "",
                eyeColor: "",
                website: "",
                representation: ""
            },
            valid: {
                firstName: false,
                lastName: false,
                phoneNumber: false,
                cityState: false,
                heightFeet: false,
                feetInches: false,
                hairColor: false,
                eyeColor: false,
                website: false,
                representation: false
            }
        }
    }

    componentDidMount() {
        // console.log(this.props.data);
        // this.renderCityInput();
        this.updatePreData();
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data) {
            this.updatePreData();
        }
    }

    inchToFeet = (val) => {
        const feet = Math.floor(val / 12);
        const inches = val % 12;
        return { feet: feet, inches: inches };
    }

    updatePreData = () => {
        const data = this.props.data;
        if (data) {
            const res = this.inchToFeet((data.heightInches) ? data.heightInches : 0);
            this.setState({
                firstName: (data.firstName) ? data.firstName : '',
                lastName: (data.lastName) ? data.lastName : '',
                phoneNumber: (data.phoneNumber) ? data.phoneNumber : '',
                cityState: (data.city || data.state) ? `${data.city},${data.state}` : '',
                city: (data.city) ? data.city : '',
                state: (data.state) ? data.state : '',
                heightFeet: (res.feet) ? res.feet : 0,
                feetInches: (res.inches) ? res.inches : 0,
                hairColor: (data.hairColor) ? data.hairColor : '',
                eyeColor: (data.eyeColor) ? data.eyeColor : '',
                website: (data.website) ? data.website : '',
                representation: (data.representation) ? data.representation : '',
                error: {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    cityState: '',
                    heightFeet: '',
                    feetInches: '',
                    hairColor: "",
                    eyeColor: "",
                    website: "",
                    representation: ""
                },
                valid: {
                    firstName: (data.firstName) ? true : false,
                    lastName: (data.lastName) ? true : false,
                    phoneNumber: (data.phoneNumber) ? true : false,
                    cityState: (data.city || data.state) ? true : false,
                    heightFeet: (res.feet >= 0) ? true : false,
                    feetInches: (res.inches >= 0) ? true : false,
                    hairColor: (data.hairColor) ? true : false,
                    eyeColor: (data.eyeColor) ? true : false,
                    website: (data.website) ? true : false,
                    representation: (data.representation) ? true : false
                }
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'firstName':
                valid.firstName = value.length > 0;
                error.firstName = valid.firstName ? "" : "First name is required!";
                break;
            case 'lastName':
                valid.lastName = value.length > 0;
                error.lastName = valid.lastName ? "" : "Last name is required!";
                break;
            case 'phoneNumber':
                const regNumber = new RegExp(/^[0-9\b]+$/);
                if (value.length > 0) {

                    if (regNumber.test(value)) {
                        valid.phoneNumber = true;
                        error.phoneNumber = "";
                    } else {
                        valid.phoneNumber = false;
                        error.phoneNumber = "Phone number is not valid";
                    }

                } else {
                    valid.phoneNumber = false;
                    error.phoneNumber = "Phone number is required!";
                }
                // valid.phoneNumber = value.length > 0 ? regNumber.test(value) : false;
                // error.phoneNumber = valid.phoneNumber ? "" : "Phone number is required!";
                break;
            case 'heightFeet':
                valid.heightFeet = value >= 0 && value !== "" ? true : false;
                error.heightFeet = valid.heightFeet ? "" : "Height Feet number is required!";
                break;
            case 'feetInches':
                valid.feetInches = value >= 0 && value !== "" ? true : false;
                error.feetInches = valid.feetInches ? "" : "Height Inches is required!";
                break;
            case 'hairColor':
                valid.hairColor = value.length > 0;
                error.hairColor = valid.hairColor ? "" : "Hire Color is required!";
                break;
            case 'eyeColor':
                valid.eyeColor = value.length > 0;
                error.eyeColor = valid.eyeColor ? "" : "Eye Color is required!";
                break;
            case 'website':
                const response = validationHelper.isUrlValid(value);
                valid.website = response.status;
                error.website = response.status ? "" : response.message;
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }

    // renderCityInput = () => {
    //     const placeInstance = places({
    //         appId: "plL5W1957CCG",
    //         apiKey: "8f309db6542eab1fb7c18b83fcff357c",
    //         container: document.querySelector("#address-input")
    //     });

    //     placeInstance.configure({
    //         language: "en",
    //         type: this.props.type,
    //         countries: ["us"]
    //     });

    //     placeInstance.on("change", (e) => {
    //         const { name: city, administrative: state } = e.suggestion;

    //         this.setState({
    //             cityState: `${city}, ${state}`,
    //             city: city,
    //             state: state,
    //             error: {
    //                 ...this.state.error,
    //                 cityState: ''
    //             },
    //             valid: {
    //                 ...this.state.valid,
    //                 cityState: true
    //             }
    //         });
    //     });
    // }


    onSubmitStep1 = async (e) => {
        // console.log('test');
        try {
            e.preventDefault();

            const { firstName, lastName, phoneNumber, cityState, city, state, heightFeet, feetInches, hairColor, eyeColor, website, representation, valid } = this.state;

            const validField = valid.firstName || valid.lastName || valid.phoneNumber || valid.cityState || valid.heightFeet || valid.feetInches || valid.hairColor || valid.eyeColor;
            // console.log(validField);
            if (validField) {
                const formData = new FormData();

                let Height = (heightFeet * 12);
                Height = (Height + Number(feetInches));

                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('phoneNumber', phoneNumber);
                formData.append('cityState', cityState);
                formData.append('city', city);
                formData.append('state', state);
                formData.append('heightInches', Height);
                formData.append('hairColor', hairColor);
                formData.append('eyeColor', eyeColor);
                formData.append('website', website);
                formData.append('representation', representation);

                const response = await apiHelper('actor/savestep1', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    this.props.onNext();
                } else {
                    toasterError(res.message);
                }
            } else {
                toasterError("All field is required!");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }


    render() {
        // console.log(this.props.data);
        const { firstName, lastName, phoneNumber, cityState, heightFeet, feetInches, hairColor, eyeColor, website, representation, valid, error } = this.state;

        const disabled = !valid.firstName || !valid.lastName || !valid.phoneNumber || !valid.cityState || !valid.heightFeet || !valid.feetInches || !valid.hairColor || !valid.eyeColor;
   
        return <div className="step_conetnt">
            <div className="form_erap">
                <h2>Let's fill out your profile</h2>
                <p>To activate your account - and make your profile searchable to casting directors - please complete the following  profile information.</p>
                {/* <form action="/" method="POST"> */}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>First Name<strong>*</strong></label>
                            <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.inputHandler} placeholder="First Name" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Last Name<strong>*</strong></label>
                            <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.inputHandler} placeholder="Last Name" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Phone Number<strong>*</strong></label>
                            <input type="text" name="phoneNumber" className="form-control" value={phoneNumber} onChange={this.inputHandler} placeholder="Phone" />
                            {error.phoneNumber !== "" ? <p className="error-text">{error.phoneNumber}</p> : null}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>City, State<strong>*</strong></label>
                            {/* <input type="text" name="cityState" id="address-input" className="form-control" value={cityState} onChange={this.inputHandler} placeholder="City,State" /> */}
                            <Autocomplete
                            name="cityState"
                            value={cityState}
                            type="text"
                            className="form-control"
                            id="address-input"
                            placeholder="City,State"
                            onChange={this.inputHandler}
                            apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                            onPlaceSelected={(place) => {
                              var formatted_address =
                                place.formatted_address.split(",");
                              this.setState({
                                cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                city: formatted_address[0],
                                state: formatted_address[1],
                                error: {
                                  ...this.state.error,
                                  cityState: "",
                                },
                                valid: {
                                  ...this.state.valid,
                                  cityState: true,
                                },
                              });
                            }}
                          />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Height Feet<strong>*</strong></label>
                            <input type="number" pattern="[0-9]*" inputmode="numeric" name="heightFeet" className="form-control" value={heightFeet} onChange={this.inputHandler} placeholder="Height Feet" />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Height Inches<strong>*</strong></label>
                            <input type="number" pattern="[0-9]*" inputmode="numeric" name="feetInches" className="form-control" value={feetInches} onChange={this.inputHandler} placeholder="Height Inches" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Hair Color<strong>*</strong></label>
                            <select name="hairColor" className="form-control" value={hairColor} onChange={this.inputHandler}>
                                <option value="">Select Hair Color</option>
                                <option value="black">Black</option>
                                <option value="brown">Brown</option>
                                <option value="red">Red</option>
                                <option value="blonde">Blonde</option>
                                <option value="gray">Gray</option>
                                <option value="white">White</option>
                                <option value="other">Other</option>
                                <option value="unknown">Unknown</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>Eye Color<strong>*</strong></label>
                            <select name="eyeColor" className="form-control" value={eyeColor} onChange={this.inputHandler}>
                                <option value="">Select Eye Color</option>
                                <option value="brown">Brown</option>
                                <option value="hazel">Hazel</option>
                                <option value="blue">Blue</option>
                                <option value="green">Green</option>
                                <option value="gray">Gray</option>
                                <option value="amber">Amber</option>
                                <option value="other">Other</option>
                                <option value="unknown">Unknown</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Website</label>
                            <input type="text" name="website" className="form-control" value={website} onChange={this.inputHandler} placeholder="Website" />
                            {error.website !== "" ? <p className="error-text">{error.website}</p> : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Representation</label>
                            <input type="text" name="representation" className="form-control" value={representation} onChange={this.inputHandler} placeholder="Representation" />
                        </div>
                    </div>
                </div>
                {/* </form> */}
            </div>
            <div className="btn-gr">
                <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmitStep1} disabled={disabled}>Next</button>
            </div>
        </div >
    }
}

class Step2 extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            unionStatus: [],
            gender: [],
            age: [],
            ethnicity: [],
            voiceType: []
        }
    }
    componentDidMount() {
        this.props.data && this.updatePreData();
    }
    updatePreData = () => {
        const data = this.props.data;
        console.log(data.unions);
        this.setState({
            unionStatus: data.unions,
            gender: data.gender,
            age: data.ageRange,
            ethnicity: data.ethnicity,
            voiceType: data.vocalRange
        })
    }
    inputHandler = (e) => {
        const { name, value, checked } = e.target;
        console.log(value);
        switch (name) {
            case 'unionStatus':
                let uniStatus = this.state.unionStatus;
                const index = uniStatus.indexOf(value);
                if (index > -1) {
                    if (!checked) uniStatus.splice(index, 1);
                } else {
                    uniStatus.push(value);
                }
                console.log(uniStatus);
                this.setState({
                    unionStatus: uniStatus
                })
                break;
            case 'gender':
                let genderStatus = this.state.gender;
                const genderIndex = genderStatus.indexOf(value);
                if (genderIndex > -1) {
                    if (!checked) genderStatus.splice(genderIndex, 1);
                } else {
                    genderStatus.push(value);
                }
                console.log(genderStatus);
                this.setState({
                    gender: genderStatus
                })
                break;
            case 'age':
                let ageStatus = this.state.age;
                const ageIndex = ageStatus.indexOf(value);
                if (ageIndex > -1) {
                    if (!checked) ageStatus.splice(ageIndex, 1);
                } else {
                    ageStatus.push(value);
                }
                this.setState({
                    age: ageStatus
                })
                break;
            case 'ethnicity':
                let ethnicityStatus = this.state.ethnicity;
                const ethnicityIndex = ethnicityStatus.indexOf(value);
                if (ethnicityIndex > -1) {
                    if (!checked) ethnicityStatus.splice(ethnicityIndex, 1);
                } else {
                    ethnicityStatus.push(value);
                }
                this.setState({
                    ethnicity: ethnicityStatus
                })
                break;
            case 'voiceType':
                let voiceTypeStatus = this.state.voiceType;
                const voiceTypeIndex = voiceTypeStatus.indexOf(value);
                if (voiceTypeIndex > -1) {
                    if (!checked) voiceTypeStatus.splice(voiceTypeIndex, 1);
                } else {
                    voiceTypeStatus.push(value);
                }
                this.setState({
                    voiceType: voiceTypeStatus
                })
                break;
            default:
                break;
        }
    }

    onSubmit = async (e) => {

        const { unionStatus, gender, age, ethnicity, voiceType } = this.state;

        if (unionStatus.length === 0) {
            return toasterError('Union Status is required');
        }
        if (gender.length === 0) {
            return toasterError('Gender is required');
        }
        if (age.length === 0) {
            return toasterError('Age is required');
        }
        if (ethnicity.length === 0) {
            return toasterError('Ethnicity is required');
        }
        if (voiceType.length === 0) {
            return toasterError('Voice type is required');
        }

        try {
            const formData = new FormData();
            formData.append('unionStatus', unionStatus);
            formData.append('gender', gender);
            formData.append('age', age);
            formData.append('ethnicity', ethnicity);
            formData.append('voiceType', voiceType);

            const response = await apiHelper('actor/savestep2', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.props.onNext();
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { unionStatus, gender, age, ethnicity, voiceType, valid, error } = this.state;

        // const disabled = valid.unionStatus.length > 0 || valid.gender.length > 0 || valid.age.length > 0 || valid.ethnicity.length > 0 || valid.voiceType.length > 0;

        return <div className="step_conetnt">
            <div className="form_erap">
                <h2>Let's fill out your profile</h2>
                <p>To activate your account - and make your profile searchable to casting directors - please complete the following  profile information.</p>
                <form action="/" method="post">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="label-title">Union Status<strong>*</strong></label>
                                <div className="input_groups">
                                    <label htmlFor="non-union"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Non-Union")} defaultValue="Non-Union" id="non-union" onChange={this.inputHandler} /> Non-Union</label>
                                    <label htmlFor="aea"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA")} defaultValue="AEA" id="AEA" onChange={this.inputHandler} /> AEA</label>
                                    <label htmlFor="aea-emc"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA-EMC")} id="aea-emc" defaultValue="AEA-EMC" onChange={this.inputHandler} /> AEA-EMC</label>
                                    <label htmlFor="unionOther"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Other")} id="unionOther" defaultValue="Other" onChange={this.inputHandler} /> Other</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="label-title">Gender<strong>*</strong></label>
                                <div className="input_groups">
                                    <label htmlFor="female"><input type="checkbox" name="gender" checked={gender.includes("Female")} defaultValue="Female" id="female" onChange={this.inputHandler} /> Female</label>
                                    <label htmlFor="male"><input type="checkbox" name="gender" checked={gender.includes("Male")} defaultValue="Male" id="male" onChange={this.inputHandler} /> Male</label>
                                    <label htmlFor="non-binary"><input type="checkbox" name="gender" checked={gender.includes("Non-Binary")} defaultValue="Non-Binary" id="non-binary" onChange={this.inputHandler} /> Non-Binary</label>
                                    <label htmlFor="genderOther"><input type="checkbox" name="gender" checked={gender.includes("Other")} defaultValue="Other" id="genderOther" onChange={this.inputHandler} /> Other</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="label-title">Age<strong>*</strong></label>
                                <div className="input_groups">
                                    <label htmlFor="child"><input type="checkbox" name="age" defaultValue="Child" checked={age.includes("Child")} onChange={this.inputHandler} id="child" /> Child</label>
                                    <label htmlFor="teen"><input type="checkbox" name="age" defaultValue="Teen" checked={age.includes("Teen")} onChange={this.inputHandler} id="teen" /> Teen</label>
                                    <label htmlFor="teens20"><input type="checkbox" name="age" defaultValue="Teens-20" checked={age.includes("Teens-20")} onChange={this.inputHandler} id="teens20" /> Teens-20</label>
                                    <label htmlFor="20s30s"><input type="checkbox" name="age" defaultValue="20s-30s" checked={age.includes("20s-30s")} onChange={this.inputHandler} id="20s30s" /> 20s-30s</label>
                                    <label htmlFor="30s40s"><input type="checkbox" name="age" defaultValue="30s-40s" checked={age.includes("30s-40s")} onChange={this.inputHandler} id="30s40s" /> 30s-40s</label>
                                    <label htmlFor="40s50s"><input type="checkbox" name="age" defaultValue="40s-50s" checked={age.includes("40s-50s")} onChange={this.inputHandler} id="40s50s" /> 40s-50s</label>
                                    <label htmlFor="50s60s"><input type="checkbox" name="age" defaultValue="50s-60s" checked={age.includes("50s-60s")} onChange={this.inputHandler} id="50s60s" /> 50s-60s</label>
                                    <label htmlFor="60s70s"><input type="checkbox" name="age" defaultValue="60s-70s" checked={age.includes("60s-70s")} onChange={this.inputHandler} id="60s70s" /> 60s-70s</label>
                                    <label htmlFor="over70"><input type="checkbox" name="age" defaultValue="Over 70" checked={age.includes("Over 70")} onChange={this.inputHandler} id="over70" /> Over 70</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="label-title">Ethnicity<strong>*</strong></label>
                                <div className="input_groups">
                                    <label htmlFor="africanamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("African American")} defaultValue="African American" onChange={this.inputHandler} id="africanamerican" /> African American</label>
                                    <label htmlFor="asian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Asian")} defaultValue="Asian" onChange={this.inputHandler} id="asian" /> Asian</label>
                                    <label htmlFor="caucasian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Caucasian")} defaultValue="Caucasian" onChange={this.inputHandler} id="caucasian" /> Caucasian</label>
                                    <label htmlFor="hispanic"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hispanic")} defaultValue="Hispanic" onChange={this.inputHandler} id="hispanic" /> Hispanic</label>
                                    <label htmlFor="latino"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Latino")} defaultValue="Latino" onChange={this.inputHandler} id="latino" /> Latino</label>
                                    <label htmlFor="nativeamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Native American")} defaultValue="Native American" onChange={this.inputHandler} id="nativeamerican" /> Native American</label>
                                    <label htmlFor="alaskannative"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Alaskan Native")} defaultValue="Alaskan Native" onChange={this.inputHandler} id="alaskannative" /> Alaskan Native</label>
                                    <label htmlFor="hawaiian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hawaiian")} defaultValue="Hawaiian" onChange={this.inputHandler} id="hawaiian" /> Hawaiian</label>
                                    <label htmlFor="middleeastern"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Middle Eastern")} defaultValue="Middle Eastern" onChange={this.inputHandler} id="middleeastern" /> Middle Eastern</label>
                                    <label htmlFor="northafrican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("North African")} defaultValue="North African" onChange={this.inputHandler} id="northafrican" /> North African</label>
                                    <label htmlFor="multicultural"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Multi-Cultural")} defaultValue="Multi-Cultural" onChange={this.inputHandler} id="multicultural" /> Multi-Cultural</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="label-title">Voice Type<strong>*</strong></label>
                                <div className="input_groups">
                                    <label htmlFor="soprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Soprano")} defaultValue="Soprano" onChange={this.inputHandler} id="soprano" /> Soprano</label>
                                    <label htmlFor="mezzosopranobelter"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano Belter")} defaultValue="Mezzo Soprano Belter" onChange={this.inputHandler} id="mezzosopranobelter" /> Mezzo Soprano Belter</label>
                                    <label htmlFor="mezzosoprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano")} defaultValue="Mezzo Soprano" onChange={this.inputHandler} id="mezzosoprano" /> Mezzo Soprano</label>
                                    <label htmlFor="alto"><input type="checkbox" name="voiceType" checked={voiceType.includes("Alto")} defaultValue="Alto" onChange={this.inputHandler} id="alto" /> Alto</label>
                                    <label htmlFor="tenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Tenor")} defaultValue="Tenor" onChange={this.inputHandler} id="tenor" /> Tenor</label>
                                    <label htmlFor="baritenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritenor")} defaultValue="Baritenor" onChange={this.inputHandler} id="baritenor" /> Baritenor</label>
                                    <label htmlFor="baritone"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritone")} defaultValue="Baritone" onChange={this.inputHandler} id="baritone" /> Baritone</label>
                                    <label htmlFor="bass"><input type="checkbox" name="voiceType" checked={voiceType.includes("Bass")} defaultValue="Bass" onChange={this.inputHandler} id="bass" /> Bass</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="btn-gr">
                <button type="button" aria-label="Next" className="btn btn-default" style={{ marginRight: '10px' }} onClick={this.props.onPrev}>Back</button>
                <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmit}>Next</button>
            </div>
        </div>
    }
}

class Step3 extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            filesArr: null,
            profilePic: null,
            isChanage: false,
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                profilePic: this.props.data.profilePic
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data) {
            this.setState({
                profilePic: data.profilePic
            })
        }
    }
    // const Step3 = ({ onNext, onPrev }) => {

    //const [file, setFile] = useState('');

    // const onSubmit = (e) => {
    //     onNext();
    // }
    onSubmit = async (e) => {
        try {
            if (this.state.profilePic && !this.state.isChanage) {
                this.props.onNext();
            } else {
                const file = this.state.filesArr;
                let formData = new FormData();
                formData.append('profilePic', file);

                const response = await apiHelper('actor/savestep3', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    const decode = jwtDecode(res.data);
                    // console.log(decode);
                    this.setState({
                        profilePic: decode.imgPic
                    })
                    this.props.updateUserImg(decode.imgPic)
                    // localStorage.setItem('profileImg', decode.imgPic)
                    this.props.onNext();
                } else {
                    toasterError(res.message);
                }
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    uploadFiles = (e) => {
        const file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (event) {
            // console.log(event.target.result);
            this.setState({
                profilePic: event.target.result
            })
        }.bind(this);
        // console.log(url)
        // console.log(URL.createObjectURL(file));

        this.setState({
            filesArr: file,
            isChanage: true
        })
    }

    //console.log(file);
    render() {
        // console.log(this.state.profilePic);
        // console.log(this.props);

        return <div className="step_conetnt">
            <div className="form_erap">
                <h2>Upload your headshot!</h2>
                {this.state.profilePic ? <div style={{ width: '100px', height: '100px', overflow: 'hidden' }}><img style={{ width: '100%' }} src={this.state.profilePic} id="userImg" alt={'user'} /></div> : null}
                <div className="file-wra">
                    <input type="file" name="file" className="form-control-file" onChange={this.uploadFiles} />
                </div>
            </div>
            <div className="btn-gr">
                <button type="button" aria-label="Next" className="btn btn-default" style={{ marginRight: '10px' }} onClick={this.props.onPrev}>Back</button>
                <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmit}>Next</button>
            </div>
        </div>
    }
}
const Step4 = ({ onNext, onPrev }) => {

    const onSubmit = (e) => {
        // onNext();
        window.location.reload();
    }
    return <div className="step_conetnt">
        <div className="form_erap">
            <h2>Your Audition Revolution profile is now active!</h2>
            <p>Check it out! And make sure to fill out resume sections of your profile - experience, training, and special skills. Keeping this updated ensures that casting directors have the most up-to-date information for you!</p>
            <h3>Welcome Back to Audition Revolution!</h3>
        </div>
        <div className="btn-gr">
            <button type="button" aria-label="Next" className="btn btn-default" style={{ marginRight: '10px' }} onClick={onPrev}>Back</button>
            <button type="button" aria-label="Next" className="btn btn-primary" onClick={onSubmit}>Finish</button>
        </div>
    </div>
}