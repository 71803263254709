import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
class DashboardTableComponent extends Component {
    render() {
        const { SearchBar } = Search;
        const { title, columns, data } = this.props.tableData;

        return (
            <>
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">{title}</h3>
                    </div>
                    <div className="panel-body no-padding">
                        <ToolkitProvider
                            keyField='id' data={data} columns={columns}
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <div className="customSearch">
                                            <SearchBar {...props.searchProps} />
                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps} classes="table table-striped" pagination={paginationFactory()}
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>

            </>
        );
    }
}

export default DashboardTableComponent;