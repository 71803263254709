import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { withRouter } from "react-router";
import CreatableSelect from "react-select/creatable";
import { apiHelper } from "../../../helper/api.helper";
import { toasterError, toasterSuccess } from "../../../helper/toaster.helper";
import {
  aboutValidation,
  emailValidation,
} from "../../../helper/validation.helper";
import "./ServiceDirectoryComponent.css";
import { servicesHelper } from "../../../helper/service.helper";

class ServiceDirectoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      cityState: "",
      city: "",
      state: "",
      fName: "",
      lName: "",
      email: "",
      phone: "",
      website: "",
      services: "",
      about: "",

      error: {
        companyName: "",
        cityState: "",
        fName: "",
        lName: "",
        email: "",
        password: "",
        confirmPass: "",
        phone: "",
        website: "",
        services: "",
        about: "",
      },
      valid: {
        companyName: false,
        cityState: false,
        fName: false,
        lName: false,
        confirmPass: false,
        phone: false,
        website: false,
        services: false,
        about: false,
      },
    };
  }

  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.checkValidation(name, value)
    );
  };
  selectHandler = (optionSelected) => {
    this.setState(
      {
        services: optionSelected,
      },
      () => this.checkValidation("services", optionSelected)
    );
  };

  checkValidation = (name, value) => {
    let error = this.state.error;
    let valid = this.state.valid;

    switch (name) {
      case "fName":
        valid.fName = value.length > 0;
        error.fName = valid.fName ? "" : "First Name Required";
        break;
      case "lName":
        valid.lName = value.length > 0;
        error.lName = valid.lName ? "" : "Last Name Required";
        break;
      case "email":
        const emailValid = emailValidation(value);
        valid.email = emailValid.status;
        error.email = emailValid.status ? "" : emailValid.message;
        break;
      case "companyName":
        valid.companyName = value.length > 0;
        error.companyName = valid.companyName ? "" : "Company name Required";
        break;
      case "website":
        valid.website = value.length > 0;
        error.website = valid.website ? "" : "Website Required";
        break;
      case "cityState":
        valid.cityState = value.length > 0;
        error.cityState = valid.cityState ? "" : "City,State Required";
        break;
      case "services":
        valid.services = value.length > 0;
        error.services = valid.services ? "" : "City,State Required";
        break;
      case "about":
        const aboutValid = aboutValidation(value);
        valid.about = aboutValid.status;
        error.about = aboutValid.status ? "" : aboutValid.message;
        break;

      default:
        break;
    }
    this.setState({
      error,
      valid,
    });
  };

  submitFun = async (e) => {
    try {
      e.preventDefault();
      const { valid } = this.state;
      if (
        !valid.companyName ||
        !valid.fName ||
        !valid.lName ||
        !valid.cityState ||
        !valid.email ||
        !valid.website ||
        !valid.services ||
        !valid.about
      ) {
        toasterError("Please fill required fields.");
        return;
      }

      const formData = new FormData();
      formData.append("companyName", this.state.companyName);
      formData.append("firstName", this.state.fName);
      formData.append("lastName", this.state.lName);
      formData.append("city", this.state.city);
      formData.append("state", this.state.state);
      formData.append("email", this.state.email);
      formData.append("website", this.state.website);
      formData.append("services", JSON.stringify(this.state.services));
      formData.append("about", this.state.about);
      formData.append("phone", this.state.phone);

      const response = await apiHelper(
        "service-directory/register",
        "POST",
        formData,
        null
      );
      const res = response.data;

      if (res.status) {
        toasterSuccess(res.message);
        this.setState({
          companyName: "",
          cityState: "",
          city: "",
          state: "",
          fName: "",
          lName: "",
          email: "",
          phone: "",
          website: "",
          services: [],
          about: "",
        });
      } else {
        toasterError(res.message);
      }
    } catch (e) {
      toasterError(e.message);
    }
  };

  noRedirect = (e) => {
    e.preventDefault();
  };
  render() {
    const {
      companyName,
      cityState,
      fName,
      lName,
      email,
      valid,
      phone,
      website,
      about,
    } = this.state;
    let disabled = false;
    if (
      !valid.companyName ||
      !valid.fName ||
      !valid.lName ||
      !valid.cityState ||
      !valid.email ||
      !valid.website ||
      !valid.services ||
      !valid.about
    ) {
      disabled = true;
    }

    let btnClass =
      "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary";
    if (disabled) {
      btnClass += " Mui-disabled Mui-disabled";
    }

    return (
      <div id="fuse-layout">
        <div className="sc-Axmtr hvJMgY">
          <div className="main12">
            <div
              className="jss12 mt-0"
              style={{
                marginBottom: "64px",
                height: "100%",
                minHeight: "100vh",
              }}
            >
              <div className="sc-AxjAm bcMPWx jss217">
                <div className="sc-AxiKw eSbheu">
                  <div className="heading-txt">
                    <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-colorInherit">
                      Welcome to Audition Revolution!
                    </h3>
                  </div>
                  <div>
                    <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorInherit">
                      Sign In Or Create New Account!
                    </h6>
                  </div>
                </div>
                <div className="MuiPaper-root MuiCard-root sc-AxirZ bJCmFu MuiPaper-elevation1">
                  {/* <div className="form-title">
                    
                  </div> */}
                  <div className="MuiCardContent-root">
                    <h6 className="MuiTypography-root MuiTypography-h6" style={{ marginBottom: "10px" }}>
                      SERVICE DIRECTORY
                    </h6>
                    <p style={{ marginBottom: "20px" }}>
                      Do you or your company provide services for actors?
                      (Classes/Coaching, Photography, Rehearsal/Performance
                      Space, Accompaniment, Recording Services, etc.?) Then we
                      invite you to be a part of the Audition Revolution
                      Services Directory - for free! This directory of services
                      is searchable by AR actors and allows them to find YOU!
                      Sign up below to be included in the AR Services Directory.
                    </p>
                    <form
                      action="#"
                      name="registerForm"
                      onSubmit={this.submitFun}
                    >
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Contact First Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="fName"
                            required=""
                            onChange={this.inputHandler}
                            type="text"
                            className="form-control"
                            value={fName}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Contact Last Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="lName"
                            required=""
                            onChange={this.inputHandler}
                            type="text"
                            className="form-control"
                            value={lName}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Contact Email<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="email"
                            required=""
                            type="email"
                            onChange={this.inputHandler}
                            className="form-control"
                            value={email}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>Contact Phone</label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="phone"
                            required=""
                            type="text"
                            onChange={this.inputHandler}
                            className="form-control"
                            value={phone}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Company Name<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="companyName"
                            required=""
                            type="text"
                            className="form-control"
                            value={companyName}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          Website Link<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="website"
                            required=""
                            type="text"
                            className="form-control"
                            value={website}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label>
                          City, State
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                          <Autocomplete
                            style={{
                              position: "relative",
                              verticalAlign: "top",
                            }}
                            name="cityState"
                            required=""
                            value={cityState}
                            type="city"
                            className="form-control"
                            id="address-input"
                            placeholder=""
                            onChange={this.inputHandler}
                            apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                            onPlaceSelected={(place) => {
                              var formatted_address =
                                place.formatted_address.split(",");
                              this.setState({
                                cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                city: formatted_address[0],
                                state: formatted_address[1],
                                error: {
                                  ...this.state.error,
                                  cityState: "",
                                },
                                valid: {
                                  ...this.state.valid,
                                  cityState: true,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.0rem" }}
                      >
                        <label>
                          Services (Select all that apply)<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error">
                          <CreatableSelect
                            isMulti
                            onChange={this.selectHandler}
                            options={servicesHelper}
                            className="form-control-multi"
                            isValidNewOption={() => false}
                          />
                        </div>
                        <div style={{marginBottom: "5px"}}>
                      Have we missed a service?  <a href={"mailto:support@auditionrevolution.com"} style={{ marginBottom: '3px' }}> Let us know!</a>
                      </div>
                      </div>
                      
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "4.0rem" }}
                      >
                        <label>
                          About (Max 100 Words allowed)<span> *</span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <Form.Control
                            as="textarea"
                            name="about"
                            onChange={this.inputHandler}
                            value={about}
                            className="form-control"
                            rows={5}
                          />
                        </div>
                      </div>
                      <button
                        className={btnClass}
                        tabIndex="-1"
                        type="submit"
                        id="createAccount"
                        aria-label="Register"
                        disabled={disabled}
                      >
                        <span className="MuiButton-label">SUBMIT</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((props) => <ServiceDirectoryComponent {...props} />);
