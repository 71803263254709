import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError } from '../../../helper/toaster.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';

class BreakdownModel extends Component {
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            unionStatus: props.data.unions,
            gender: props.data.gender,
            age: props.data.ageRange,
            ethnicity: props.data.ethnicity,
            voiceType: props.data.vocalRange
        }
    }
    closeBreakdownModal = () => {
        this.setState({
            isBreakdownModal: false
        })
    }
    inputHandler = (e) => {
        const { name, value, checked } = e.target;
        switch (name) {
            case 'unionStatus':
                let uniStatus = this.state.unionStatus;
                const index = uniStatus.indexOf(value);
                if (index > -1) {
                    if (!checked) uniStatus.splice(index, 1);
                } else {
                    uniStatus.push(value);
                }
                this.setState({
                    unionStatus: uniStatus
                })
                break;
            case 'gender':
                let genderStatus = this.state.gender;
                const genderIndex = genderStatus.indexOf(value);
                if (genderIndex > -1) {
                    if (!checked) genderStatus.splice(genderIndex, 1);
                } else {
                    genderStatus.push(value);
                }
                this.setState({
                    gender: genderStatus
                })
                break;
            case 'age':
                let ageStatus = this.state.age;
                const ageIndex = ageStatus.indexOf(value);
                if (ageIndex > -1) {
                    if (!checked) ageStatus.splice(ageIndex, 1);
                } else {
                    ageStatus.push(value);
                }
                this.setState({
                    age: ageStatus
                })
                break;
            case 'ethnicity':
                let ethnicityStatus = this.state.ethnicity;
                const ethnicityIndex = ethnicityStatus.indexOf(value);
                if (ethnicityIndex > -1) {
                    if (!checked) ethnicityStatus.splice(ethnicityIndex, 1);
                } else {
                    ethnicityStatus.push(value);
                }
                this.setState({
                    ethnicity: ethnicityStatus
                })
                break;
            case 'voiceType':
                let voiceTypeStatus = this.state.voiceType;
                const voiceTypeIndex = voiceTypeStatus.indexOf(value);
                if (voiceTypeIndex > -1) {
                    if (!checked) voiceTypeStatus.splice(voiceTypeIndex, 1);
                } else {
                    voiceTypeStatus.push(value);
                }
                this.setState({
                    voiceType: voiceTypeStatus
                })
                break;
            default:
                break;
        }
    }
    updateBreakdown = async (e) => {

        const { unionStatus, gender, age, ethnicity, voiceType } = this.state;

        if (unionStatus.length === 0) {
            return toasterError('Union Status is required');
        }
        if (gender.length === 0) {
            return toasterError('Gender is required');
        }
        if (age.length === 0) {
            return toasterError('Age is required');
        }
        if (ethnicity.length === 0) {
            return toasterError('Ethnicity is required');
        }
        if (voiceType.length === 0) {
            return toasterError('Voice type is required');
        }

        try {
            const formData = new FormData();
            formData.append('unionStatus', unionStatus);
            formData.append('gender', gender);
            formData.append('age', age);
            formData.append('ethnicity', ethnicity);
            formData.append('voiceType', voiceType);

            const response = await apiHelper('actor/savestep2', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.props.userUpdateFun(true);
                this.props.onHide();
                //window.location.reload();
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { age, unionStatus, gender, ethnicity, voiceType } = this.state;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Actor Attributes</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="/" method="post">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Union Status<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="non-union"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Non-Union")} defaultValue="Non-Union" id="non-union" onChange={this.inputHandler} /> Non-Union</label>
                                            <label htmlFor="aea"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA")} defaultValue="AEA" id="AEA" onChange={this.inputHandler} /> AEA</label>
                                            <label htmlFor="aea-emc"><input type="checkbox" name="unionStatus" id="aea-emc" checked={unionStatus.includes("AEA-EMC")} defaultValue="AEA-EMC" onChange={this.inputHandler} /> AEA-EMC</label>
                                            <label htmlFor="unionOther"><input type="checkbox" name="unionStatus" id="unionOther" checked={unionStatus.includes("Other")} defaultValue="Other" onChange={this.inputHandler} /> Other</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Gender<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="female"><input type="checkbox" name="gender" checked={gender.includes("Female")} defaultValue="Female" id="female" onChange={this.inputHandler} /> Female</label>
                                            <label htmlFor="male"><input type="checkbox" name="gender" checked={gender.includes("Male")} defaultValue="Male" id="male" onChange={this.inputHandler} /> Male</label>
                                            <label htmlFor="non-binary"><input type="checkbox" name="gender" checked={gender.includes("Non-Binary")} defaultValue="Non-Binary" id="non-binary" onChange={this.inputHandler} /> Non-Binary</label>
                                            <label htmlFor="genderOther"><input type="checkbox" name="gender" checked={gender.includes("Other")} defaultValue="Other" id="genderOther" onChange={this.inputHandler} /> Other</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Age<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="child"><input type="checkbox" name="age" defaultValue="Child" checked={age.includes("Child")} onChange={this.inputHandler} id="child" /> Child</label>
                                            <label htmlFor="teen"><input type="checkbox" name="age" defaultValue="Teen" checked={age.includes("Teen")} onChange={this.inputHandler} id="teen" /> Teen</label>
                                            <label htmlFor="teens20"><input type="checkbox" name="age" defaultValue="Teens-20" checked={age.includes("Teens-20")} onChange={this.inputHandler} id="teens20" /> Teens-20</label>
                                            <label htmlFor="20s30s"><input type="checkbox" name="age" defaultValue="20s-30s" checked={age.includes("20s-30s")} onChange={this.inputHandler} id="20s30s" /> 20s-30s</label>
                                            <label htmlFor="30s40s"><input type="checkbox" name="age" defaultValue="30s-40s" checked={age.includes("30s-40s")} onChange={this.inputHandler} id="30s40s" /> 30s-40s</label>
                                            <label htmlFor="40s50s"><input type="checkbox" name="age" defaultValue="40s-50s" checked={age.includes("40s-50s")} onChange={this.inputHandler} id="40s50s" /> 40s-50s</label>
                                            <label htmlFor="50s60s"><input type="checkbox" name="age" defaultValue="50s-60s" checked={age.includes("50s-60s")} onChange={this.inputHandler} id="50s60s" /> 50s-60s</label>
                                            <label htmlFor="60s70s"><input type="checkbox" name="age" defaultValue="60s-70s" checked={age.includes("60s-70s")} onChange={this.inputHandler} id="60s70s" /> 60s-70s</label>
                                            <label htmlFor="over70"><input type="checkbox" name="age" defaultValue="Over 70" checked={age.includes("Over 70")} onChange={this.inputHandler} id="over70" /> Over 70</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Ethnicity<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="africanamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("African American")} defaultValue="African American" onChange={this.inputHandler} id="africanamerican" /> African American</label>
                                            <label htmlFor="asian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Asian")} defaultValue="Asian" onChange={this.inputHandler} id="asian" /> Asian</label>
                                            <label htmlFor="caucasian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Caucasian")} defaultValue="Caucasian" onChange={this.inputHandler} id="caucasian" /> Caucasian</label>
                                            <label htmlFor="hispanic"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hispanic")} defaultValue="Hispanic" onChange={this.inputHandler} id="hispanic" /> Hispanic</label>
                                            <label htmlFor="latino"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Latino")} defaultValue="Latino" onChange={this.inputHandler} id="latino" /> Latino</label>
                                            <label htmlFor="nativeamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Native American")} defaultValue="Native American" onChange={this.inputHandler} id="nativeamerican" /> Native American</label>
                                            <label htmlFor="alaskannative"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Alaskan Native")} defaultValue="Alaskan Native" onChange={this.inputHandler} id="alaskannative" /> Alaskan Native</label>
                                            <label htmlFor="hawaiian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hawaiian")} defaultValue="Hawaiian" onChange={this.inputHandler} id="hawaiian" /> Hawaiian</label>
                                            <label htmlFor="middleeastern"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Middle Eastern")} defaultValue="Middle Eastern" onChange={this.inputHandler} id="middleeastern" /> Middle Eastern</label>
                                            <label htmlFor="northafrican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("North African")} defaultValue="North African" onChange={this.inputHandler} id="northafrican" /> North African</label>
                                            <label htmlFor="multicultural"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Multi-Cultural")} defaultValue="Multi-Cultural" onChange={this.inputHandler} id="multicultural" /> Multi-Cultural</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="label-title">Voice Type<strong>*</strong></label>
                                        <div className="input_groups">
                                            <label htmlFor="soprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Soprano")} defaultValue="Soprano" onChange={this.inputHandler} id="soprano" /> Soprano</label>
                                            <label htmlFor="mezzosopranobelter"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano Belter")} defaultValue="Mezzo Soprano Belter" onChange={this.inputHandler} id="mezzosopranobelter" /> Mezzo Soprano Belter</label>
                                            <label htmlFor="mezzosoprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano")} defaultValue="Mezzo Soprano" onChange={this.inputHandler} id="mezzosoprano" /> Mezzo Soprano</label>
                                            <label htmlFor="alto"><input type="checkbox" name="voiceType" checked={voiceType.includes("Alto")} defaultValue="Alto" onChange={this.inputHandler} id="alto" /> Alto</label>
                                            <label htmlFor="tenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Tenor")} defaultValue="Tenor" onChange={this.inputHandler} id="tenor" /> Tenor</label>
                                            <label htmlFor="baritenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritenor")} defaultValue="Baritenor" onChange={this.inputHandler} id="baritenor" /> Baritenor</label>
                                            <label htmlFor="baritone"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritone")} defaultValue="Baritone" onChange={this.inputHandler} id="baritone" /> Baritone</label>
                                            <label htmlFor="bass"><input type="checkbox" name="voiceType" checked={voiceType.includes("Bass")} defaultValue="Bass" onChange={this.inputHandler} id="bass" /> Bass</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.updateBreakdown}>Save</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    userUpdateFun: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_USER_INFO, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(BreakdownModel));