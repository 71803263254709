import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { apiHelper } from '../../../helper/api.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import BootstrapTable from 'react-bootstrap-table-next';
import { toasterError } from '../../../helper/toaster.helper';
//import { useParams } from 'react-router-dom';
import Carousel, { Modal, ModalGateway } from "react-images";
import defaultProfilePic from './../../../assets/img/default-avatar-profile.jpg';


const Breakdown = ({ title, breakdownArray, width }) => {
    return (
        <>
            <p>{title}: </p>
            <div className="break-down">
                {breakdownArray.map(
                    (a, i) =>
                        a && (
                            <div
                                key={`${i}${a}`}
                            >
                                <div className="custom-chip">
                                    <span>{a}</span>
                                </div>
                            </div>
                        )
                )}
            </div>
        </>
    );
};
const styleFn = (styleObj) => ({ ...styleObj, zIndex: 100 });

const LightboxModal = props => {
    const { open, handleClose, images, currentIndex } = props;
    return (
        <ModalGateway>
            {open ? (
                <Modal
                    styles={{ blanket: styleFn, positioner: styleFn }}
                    onClose={handleClose}
                >
                    <Carousel views={images} currentIndex={currentIndex} />
                </Modal>
            ) : null}
        </ModalGateway>
    );
};
class ActorDetailsComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            actorDetails: null,
            trainingData: [],
            skillsData: [],
            experienceData: [],
            profileImgModel: false
        }
    }
    componentDidMount() {
        this.getActorDetails();
        this.getTrainingData();
        this.getActorSkills();
        this.getActorExperience();
    }
    openHeadShot = (url) => {
        this.setState({
            profileImgModel: true
        })

    }
    closeHeadShot = () => {
        this.setState({
            profileImgModel: false
        })
    }
    getActorExperience = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);

            const response = await apiHelper('admin/actor/getActorExperience', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    experienceData: data
                })
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getActorSkills = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);

            const response = await apiHelper('admin/actor/getActorSkills', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    skillsData: data
                })
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getTrainingData = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);

            const response = await apiHelper('admin/actor/getActorTraining', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    trainingData: data
                })
            } else {
                // toasterError(res.message)
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    getActorDetails = async () => {
        try {
            const formData = new FormData();
            formData.append('id', this.state.id);

            const response = await apiHelper('admin/actor/getActorDetails', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    actorDetails: data
                })

            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    inchToFeet = (val) => {
        const feet = Math.floor(val / 12);
        const inches = val % 12;
        return feet + " Feet " + inches + ' Inches';
    }
    render() {
        const columns = [
            { dataField: 'text', text: 'Text' }
        ]
        const columns2 = [
            { dataField: 'project', text: 'Project' },
            { dataField: 'role', text: 'Role' },
            { dataField: 'company', text: 'Company' },
            { dataField: 'director', text: 'Director' },
        ]
        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="panel panel-profile">
                                <div className="clearfix">
                                    <div className="profile-left relative">
                                        <div className="profile-header">
                                            <div className="overlay"></div>
                                            <div className="profile-main">
                                                <img src={this.state.actorDetails && this.state.actorDetails.profilePic ? this.state.actorDetails.profilePic : defaultProfilePic} className="img-profile" alt="Avatar" onClick={this.openHeadShot} />
                                            </div>
                                        </div>
                                        <div className="profile-detail">
                                            <div className="profile-info">
                                                <h4 className="heading">Basic Info</h4>
                                                <ul className="list-unstyled list-justify">
                                                    <li>Name <span>{this.state.actorDetails && this.state.actorDetails.firstName} {this.state.actorDetails && this.state.actorDetails.lastName}</span></li>
                                                    <li>Email <span>{this.state.actorDetails && this.state.actorDetails.email}</span></li>
                                                    <li>Mobile <span>{this.state.actorDetails && this.state.actorDetails.phoneNumber}</span></li>
                                                    <li>Eye Colour <span>{this.state.actorDetails && this.state.actorDetails.eyeColor}</span></li>
                                                    <li>Hair Colour <span>{this.state.actorDetails && this.state.actorDetails.hairColor}</span></li>
                                                    <li>
                                                        Height
                                                        <span>{this.state.actorDetails && this.state.actorDetails.heightInches ? this.inchToFeet(this.state.actorDetails.heightInches) : '--'}</span>
                                                    </li>
                                                    <li>Website <span><a href={this.state.actorDetails && this.state.actorDetails.website ? this.state.actorDetails.website : '#'}>{this.state.actorDetails && this.state.actorDetails.website ? this.state.actorDetails.website : '--'}</a></span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-right">
                                        <h4 className="heading">Actor Breakdown</h4>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Breakdown title="Age Range" breakdownArray={this.state.actorDetails ? this.state.actorDetails.ageRange : []} />
                                            </div>
                                            <div className="col-sm-6">
                                                <Breakdown title="Unions" breakdownArray={this.state.actorDetails ? this.state.actorDetails.unions : []} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Breakdown title="Gender" breakdownArray={this.state.actorDetails ? this.state.actorDetails.gender : []} />
                                            </div>
                                            <div className="col-sm-6">
                                                <Breakdown title="Vocal Range" breakdownArray={this.state.actorDetails ? this.state.actorDetails.vocalRange : []} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Breakdown title="Ethnicity" breakdownArray={this.state.actorDetails ? this.state.actorDetails.ethnicity : []} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="panel">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">Training</h3>
                                                        <BootstrapTable
                                                            keyField='id' classes="table table-striped" data={this.state.trainingData} columns={columns}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="panel">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">Special Skills</h3>
                                                        <BootstrapTable
                                                            keyField='id' classes="table table-striped" data={this.state.skillsData} columns={columns}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title">Experience</h3>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.experienceData && this.state.experienceData.theatreExperience ?
                                            this.state.experienceData.theatreExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Theatre</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.theatreExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }

                                        {this.state.experienceData && this.state.experienceData.musicalTheatreExperience ?
                                            this.state.experienceData.musicalTheatreExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Musical Theatre</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.musicalTheatreExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }

                                        {this.state.experienceData && this.state.experienceData.operaExperience ?
                                            this.state.experienceData.operaExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Opera</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.operaExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }
                                        {this.state.experienceData && this.state.experienceData.filmExperience ?
                                            this.state.experienceData.filmExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Film</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.filmExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }
                                        {this.state.experienceData && this.state.experienceData.televisionExperience ?
                                            this.state.experienceData.televisionExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Television</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.televisionExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }
                                        {this.state.experienceData && this.state.experienceData.commercialExperience ?
                                            this.state.experienceData.commercialExperience.length > 0 ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="panel">
                                                            <div className="panel-heading">
                                                                <h3 className="panel-title">Commercial</h3>
                                                                <BootstrapTable
                                                                    keyField='id' classes="table table-striped" data={this.state.experienceData.commercialExperience} columns={columns2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <LightboxModal open={this.state.profileImgModel} handleClose={this.closeHeadShot} images={[{ src: this.state.actorDetails && this.state.actorDetails.profilePic ? this.state.actorDetails.profilePic : defaultProfilePic }]} currentIndex={0} />
            </>
        );
    }
}

export default withRouter(props => <ActorDetailsComponent {...props} />);