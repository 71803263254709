import React, { Component, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import AddTrainingComponent from './AddTrainingComponent';

class TrainingComponent extends Component {

    _unmounted = false;

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            isTrainingOrder: false,
            isTrainingOrderModal: false,
            trainingModalData: null,
            isSkillsModal: false,
            traniningArray: [],
        }
    }
    componentDidMount() {
        this._unmounted = true;
        this.getActorTraining();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getActorTraining = async () => {
        try {
            const response = await apiHelper('actor/getActorTraining', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        traniningArray: data.trainingData
                    })
                }
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    saveTrainingOrder = async () => {
        try {
            console.log(this.state.traniningArray);
            const formData = new FormData();
            formData.append('data', JSON.stringify(this.state.traniningArray));

            const response = await apiHelper('actor/reOrderTraining', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                this.setState({ isTrainingOrder: false })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }

    }
    openTrainingModal = () => {
        this.setState({
            isTrainingModal: true
        })
    }
    closeTrainingModal = () => {
        this.setState({
            isTrainingModal: false
        })
    }
    openTrainingEditModal = (row) => {
        // console.log(row);
        this.setState({
            isTrainingOrderModal: true,
            trainingModalData: row
        })
    }
    closeTrainingEditModal = () => {
        this.setState({
            isTrainingOrderModal: false,
            trainingModalData: null
        })
    }

    updateDat = (data) => {
        this.setState({
            traniningArray: data
        })
    }

    trainingeOrderFunc = () => {
        this.setState({ isTrainingOrder: true })
    }

    deleteTraining = async (id) => {
        try {
            const { traniningArray } = this.state
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('actor/deleteActorTraining', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const updateArray = traniningArray.filter(info => info.id !== id);
                this.setState({
                    traniningArray: updateArray
                })
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    trainingSubmit = async (data) => {
        try {
            // const { trainingText } = this.state;

            const formData = new FormData();
            formData.append('trainingText', data.trainingText);

            const response = await apiHelper('actor/addActorTraining', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                if (!data.isMore) {
                    this.closeTrainingModal();
                }
                this.getActorTraining();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    updateSubmit = async (data) => {
        try {
            // console.log(data);

            let formData = new FormData();
            formData.append('id', data.id);
            formData.append('trainingText', data.text);

            const response = await apiHelper('actor/actorTraining/update', 'PUT', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.closeTrainingEditModal();
                this.getActorTraining();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { isTrainingOrder, traniningArray, isTrainingOrderModal, trainingModalData } = this.state;
        // console.log(traniningArray);
        return (
            <div style={{ marginBottom: '20px' }}>
                <div className="titl-wrp">
                    <h4 className="res-tit">Training</h4>
                    <div className="bt-grops">
                        {isTrainingOrder ?
                            <button type="button" className="btns btn-transprent" aria-label="Save Changes" onClick={this.saveTrainingOrder}>Save Changes</button>
                            :
                            <button type="button" className="btns btn-transprent" aria-label="Reorder Training" onClick={this.trainingeOrderFunc}>Reorder Training</button>
                        }
                        <button type="button" className="btns btn-black" onClick={this.openTrainingModal} aria-label="Add Training">Add Training</button>
                    </div>
                </div>
                <div className="table-viwe">
                    <DndProvider backend={HTML5Backend}>
                        <UlLi
                            data={traniningArray}
                            deleteFun={this.deleteTraining}
                            updateData={this.updateDat}
                            updateFun={this.openTrainingEditModal}
                            isReOrder={isTrainingOrder}
                        />
                    </DndProvider>
                </div>
                {this.state.isTrainingModal ? <AddTrainingComponent isEdit={false} show={this.state.isTrainingModal} onHide={this.closeTrainingModal} addActorSubmit={this.trainingSubmit} /> : null}
                {isTrainingOrderModal ? <AddTrainingComponent isEdit={true} show={isTrainingOrderModal} data={trainingModalData} onHide={this.closeTrainingEditModal} addActorSubmit={this.updateSubmit} /> : null}
            </div>
        );
    }
}

const UlLi = ({ data, updateData, isReOrder, deleteFun, updateFun }) => {

    const [records, setRecords] = React.useState(data);
    React.useEffect(() => {
        // if (records.length === 0) {
        setRecords(data)
        // }
    }, [records, data])

    // console.log(records);
    const moveCard = React.useCallback((dragIndex, hoverIndex) => {

        const dragCard = records[dragIndex];
        const upd = update(records, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        setRecords(upd);
        updateData(upd);
    }, [records, updateData])

    const renderCard = (record, index) => {
        return (
            <LICompo
                key={index}
                isReorder={isReOrder}
                index={index}
                id={record.id}
                text={record.text}
                data={record}
                moveCard={moveCard}
                deleteFun={deleteFun}
                updateFun={updateFun}
            />
        );
    };

    return (<>
        <ul>{records.length > 0 ? records.map((record, i) => renderCard(record, i)) : <li>Add your info here!</li>}</ul>
    </>);

}

const DND_ITEM_TYPE = 'card';

const LICompo = ({ isReorder, id, index, text, data, moveCard, deleteFun, updateFun }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    preview(drop(ref));
    drag(drop(ref));
    return (<li style={{ opacity }} data-handler-id={handlerId}>
        <button type="button" className={isReorder ? "drag-buton display-show" : " drag-buton display-none"} ref={ref} ><i className="fa fa-arrows arr-icon"></i></button>
        <span>{text}
            <button type="button" className="btn-dele" onClick={() => deleteFun(id)}><i className="fa fa-trash"></i></button>
            <button type="button" className="btn-dele" onClick={() => updateFun(data)}><i className="fa fa-pencil"></i></button>
        </span>
    </li>);
};


export default withRouter(props => <TrainingComponent {...props} />);