export const emailValidation = (value) => {
    if (value.length > 0) {
        const reg = new RegExp(/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/);
        if (!reg.test(value)) {
            return { status: false, message: "Invalid email." }
        }
        return { status: true }
    } else {
        return { status: false, message: "Email is required." }
    }
}
export const aboutValidation = (value) => {
    if (value.length > 0) {
        if(value.split(' ').length > 100){
            return { status: false, message:"Maximum allowed words for about is 100" }
        } else{
            return { status: true }
        }
    } else {
        return { status: false, message: "About is required." }
    }
}

export const passwordValidation = (value) => {
    if (value.length > 0) {
        if (value.length <= 5) {
            return { status: false, message: "Password must be 6 character." }
        }
        return { status: true }
    } else {
        return { status: false, message: "Password is required." }
    }
}

export const confirmPassValidation = (pass, value) => {
    if (value.length > 0) {
        if (value !== pass) {
            return { status: false, message: "Confirm Password not match" }
        }
        return { status: true }
    } else {
        return { status: false, message: "Confirm Password is required." }
    }
}
export function isUrlValid(userInput) {
    const Reg = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/)
    if (userInput.length > 0) {
        var res = Reg.test(userInput);
        if (!res) {
            return { status: false, message: "Please enter valid website url including http or https." }
        }
        return { status: true }
    } else {
        return { status: true }
    }
}