import axios from 'axios';

export const apiHelper = (api, method, data, headers) => {

    // const url = `http://localhost:3001/${api}`;
    // const url = `http://3.143.198.53:3001/${api}`;
    const url = `https://auditionrevolution.com:3001/${api}`;

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            data: data,
            headers: headers
        }).then(res => resolve(res)).catch(err => reject(err))
    })
}