import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';

class EditTagComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            noteText: '',
            error: {
                noteText: ''
            },
            valid: {
                noteText: false
            }
        }
    }

    componentDidMount() {
        const { tagname } = this.props;
        this.setState({
            noteText: tagname,
            error: {
                noteText: ''
            },
            valid: {
                noteText: tagname !== '' ? true : false
            }
        })
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'noteText':
                valid.noteText = value.length > 0;
                error.noteText = valid.noteText ? "" : "Tag name is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    tagNameSubmit = async () => {
        const { valid, noteText } = this.state;
        if (valid.noteText) {
            this.props.submitTagName({ tagName: noteText });
        } else {
            toasterError('Tag Name must be required');
        }
    }

    render() {
        // error
        const { noteText, valid } = this.state;
        const disabled = !valid.noteText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Update tag name</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input type="text" name="noteText" className="form-control" value={noteText} onChange={this.inputHandler} placeholder="Tag name here..." />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={disabled || this.props.loader} onClick={this.tagNameSubmit}>{this.props.loader ? 'Please wait...' : 'Update'}</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default withRouter(connect(null)(EditTagComponent));