import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';
import EditNotesComponent from './EditNotesModal';

class NotesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notesArray: [],
            isEditModal: false,
            editModalData: null
        }
    }
    componentDidMount() {
        this.getCompanyNotes();
    }

    componentDidUpdate(prevProps) {
        const { isReload } = this.props;
        if (prevProps.isReload !== isReload) {
            this.getCompanyNotes();
        }
    }

    getCompanyNotes = async () => {
        try {
            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }

            const response = await apiHelper('company/getCompanyNotes', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                // console.log(data);
                this.setState({
                    notesArray: data
                })
                this.props.reloadNoteFun(false);
                // toasterSuccess(res.message);
            } else {

                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    openUpdateModal = (data) => {
        this.setState({
            isEditModal: true,
            editModalData: data
        })
    }
    closeUpdateModal = () => {
        this.setState({
            isEditModal: false,
            editModalData: null
        })
    }

    noRedirect = (e) => {
        e.preventDefault();
    }
    redirectProfile = (e, item) => {
        e.preventDefault();
        const { history } = this.props;
        history.push(`/profile/${item.user_id}`)
    }
    render() {
        // const notesArray = this.state;
        return (
            <div className="notes-wrap">
                <h2 className="note-title">Recent Notes</h2>

                {this.state.notesArray.map(
                    (item, index) =>
                        item && (
                            <p key={index}>{item.text} <a href="/#" onClick={(e) => this.redirectProfile(e, item)}>{item.name}</a><button type="button" className="btn-dele" onClick={() => this.openUpdateModal(item)} style={{display:'unset', margin:'unset'}}><i className="fa fa-pencil"></i></button></p>
                        )
                )}

                {this.state.isEditModal ?
                    <EditNotesComponent show={this.state.isEditModal} onHide={this.closeUpdateModal} data={this.state.editModalData} />
                    :
                    null
                }
            </div>
        );
    }
}

const mapStatesToProps = state => ({
    isReload: state.profileData.reloadNoteAPIs
})
const mapDispatchToProps = dispatch => ({
    reloadNoteFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_NOTES_API, payload: bool })
})

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(NotesComponent));