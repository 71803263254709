import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { jwtDecode } from '../../../helper/jwt.helper';
import ARLogo from './../../../assets/img/AR_Logo.png';
import * as ACTION_TYPES from '../../../redux/actions/type';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import companyIcon from './../../../assets/img/company-icon.png';
import userIcon from './../../../assets/img/user-icon.png';
import loginIcon from './../../../assets/img/login-icon.png';
class HeaderUser extends Component {

    _unmounted = false;

    container = React.createRef();
    container2 = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isOpen2: false,
            imgErr: false,
            user: null,
            userType: []
        }
    }


    componentDidMount() {
        this._unmounted = true;
        // const token = localStorage.getItem('userToken');
        // if (token) {
        //     const user = jwtDecode(token);
        //     if (this.props.userImg === '') this.props.updateUserImg(user.profilePic)
        // }
        this.getProfileData();
        this.updateUserInfo();
        document.addEventListener('click', this.handleOutisideClick);
    }

    componentDidUpdate(prevProps) {
        const { isUserUpdated } = this.props;
        if (prevProps.isUserUpdated !== isUserUpdated) {
            this.updateUserInfo();
        }
    }

    getProfileData = async () => {
        try {

            const headers = {
                'Authorization': localStorage.getItem('userToken')
            }

            const response = await apiHelper('actor/getProfile', 'GET', null, headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    if (data.profilePic) {
                        this.props.updateUserImg(data.profilePic)
                    }
                }
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
        document.removeEventListener('click', this.handleOutisideClick);
    }

    handleClick = () => {
        this.setState(state => ({
            isOpen: !state.isOpen
        }))
    }

    handleClick2 = () => {
        this.setState(state => ({
            isOpen2: !state.isOpen2
        }))
    }

    handleOutisideClick = (e) => {
        if (this.container.current && !this.container.current.contains(e.target)) {
            this.setState({
                isOpen: false,
            })
        }

        if (this.container2.current && !this.container2.current.contains(e.target)) {
            this.setState({
                isOpen2: false,
            })
        }
    }

    notRedirect = (e) => {
        e.preventDefault();
        this.setState(state => ({
            isOpen: !state.isOpen
        }))
    }

    notRedirect2 = (e) => {
        this.setState(state => ({
            isOpen2: !state.isOpen2
        }))
        e.preventDefault();
    }

    logout = (e) => {
        e.preventDefault()
        const { history } = this.props;
        //toasterSuccess("Logout successfully.");
        localStorage.removeItem('userToken');
        history.push('/login');
    }

    imgError = (e) => {
        this.setState({
            imgErr: true
        })
    }

    noRedirect3 = (e) => {
        e.preventDefault();
        this.closeDropdwon();
    }

    closeDropdwon = () => {
        this.setState({
            isOpen2: false,
            isOpen: false,
        })
    }

    updateUserInfo = () => {

        const token = localStorage.getItem('userToken');

        let user = null;

        if (token) {
            user = jwtDecode(token);
        }

        let userType = [];

        if (user) {
            // console.log(user);
            var result = user.userType.substring(1, user.userType.length - 1);
            userType = result.split(',');
        }

        if (this._unmounted) {
            this.setState({
                user,
                userType
            })
        }
    }

    render() {
        const token = localStorage.getItem('userToken');

        const profileImg = this.props.userImg;
        const { isOpen, isOpen2, user, userType } = this.state;

        // console.log(user);
        const { location: { pathname } } = this.props;
        const splitPath = pathname.split('/');
        let isUser = false;
        if (splitPath[1] !== 'admin') {
            isUser = true;
        }
        return (
            isUser && token &&
            <nav className="navbar navbar-default lig-gray">
                <div className="brand">
                    <Link to="/profile">
                        <img src={ARLogo} alt="AR" className="img-responsive logo" />
                    </Link>
                </div>
                <div className="container-fluid">
                    {/* <div className="navbar-btn">
                        <button type="button" className="btn-toggle-fullwidth"><i className="lnr lnr-arrow-left-circle"></i></button>
                    </div> */}

                    <div id="navbar-menu" >
                        <ul className="nav navbar-nav navbar-right">
                            {user && user.userType && user.userType.includes("theatre") && Number(user.status) == 1 ?
                                <li className={isOpen2 ? "dropdown open" : "dropdown"} ref={this.container2}>
                                    <a href="/#" onClick={this.noRedirect3} style={{ fontSize: '1.8rem', textTransform: 'uppercase' }} onClick={this.notRedirect2} className="dropdown-toggle profile-down" data-toggle="dropdown">
                                        Talent Database <i className="icon-submenu lnr lnr-chevron-down"></i>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/search/actor" onClick={this.closeDropdwon}><i className="fa fa-search"></i> <span>Actor Search</span></Link></li>
                                        <li><Link to="/profile/tags" onClick={this.closeDropdwon}><i className="fa fa-tag"></i> <span>My Tags</span></Link></li>
                                        <li><Link to="/actors" onClick={this.closeDropdwon}><i className="fa fa-user"></i> <span>My Actors</span></Link></li>
                                    </ul>
                                </li>
                                :
                                null
                            }
                            <li>
                                <Link style={{ fontSize: '1.8rem', textTransform: 'uppercase' }} to="/profile/service-directory"><span>Service Directory</span></Link>
                            </li>
                            <li className={isOpen ? "dropdown open" : "dropdown"} ref={this.container}>
                                <a href="/#" onClick={this.notRedirect} className="dropdown-toggle profile-down nav-def" data-toggle="dropdown">
                                    {profileImg === '' ? <div className="img-use">{user && user.firstName.charAt(0).toUpperCase()}</div> : <img src={profileImg} onError={this.imgError} className="img-circle" alt="Avatar" />}
                                    <span className="disNone" style={{ fontSize: '1.8rem', textTransform: 'uppercase' }}>{user && user.firstName + ' ' + user.lastName}<small>{userType.length > 0 && userType.join(', ')}</small></span> <i className="icon-submenu lnr lnr-chevron-down"></i>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/profile/settings" onClick={this.handleClick}><i className="lnr lnr-user"></i> <span>My Account</span></Link></li>
                                    <li style={{ opacity: '0.5' }}><Link to="/#" style={{ cursor: 'no-drop' }} onClick={this.noRedirect3}><i className="lnr lnr-alarm"></i> <span>My Notifications</span></Link></li>
                                    <li style={{ opacity: '0.5' }}><Link to="/#" style={{ cursor: 'no-drop' }} onClick={this.noRedirect3}><i className="lnr lnr-music-note"></i> <span>My Auditions</span></Link></li>
                                    <li> <Link to="/" onClick={() => { window.location.href = "mailto:support@auditionrevolution.com"; }}><i className="lnr lnr-question-circle"></i> <span>Contact Support</span></Link></li>
                                    <li><Link to="/" onClick={this.logout}><i className="lnr lnr-exit"></i> <span>Logout</span></Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div >
            </nav >
        );
    }
}

const mapStateToProps = state => ({
    userImg: state.userImg.userImg,
    isUserUpdated: state.profileData.userInfoUpdated
});

const mapDispatchToProps = dispatch => ({
    updateUserImg: (img) => dispatch({ type: ACTION_TYPES.USER_IMAGE_UPDATE, payload: img })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderUser));