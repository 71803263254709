import React, { Component } from 'react';
import ARLogo from './../../../assets/img/AR_Logo.png';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';



class VerifyComponent extends Component {

    headers = {
        "Authorization": localStorage.getItem('userToken')
    }

    verfiyEmail = async () => {
        try {

            const userToken = localStorage.getItem('userToken');
            const tokenData = jwtDecode(userToken);

            const formData = new FormData();
            formData.append('firstName', tokenData.firstName);
            formData.append('lastName', tokenData.lastName);
            formData.append('email', tokenData.email);

            const response = await apiHelper('actor/resentVerificationEmail', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                //toasterSuccess(res.message);
            } else {

                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {
        const userType = this.props.type;
        const status = Number(this.props.status);
        return (
            userType === "actor" ?
                <section className="bg_verify">
                    <div className="veryfy_wrap">
                        <img src={ARLogo} alt="AR" />
                        <h6 className="ver-title">Thank you for joining Audition Revolution!</h6>
                        <p className="ver-text">You’re almost there! We just sent an email to verify your account. You should receive it soon - be on the lookout!</p>
                        <p className="ver-text">To be sure you receive all notifications from Audition Revolution, whitelist support@auditionrevolution.com and check your spam and promotions folder.</p>
                        <button type="button" aria-label="Resend Verification Email" onClick={this.verfiyEmail}>Resend Verification Email</button>
                    </div>
                </section>
                :
                <section className="bg_verify">
                    {status === 3 ?

                        <div className="veryfy_wrap">
                            <img src={ARLogo} alt="AR" />
                            <h6 className="ver-title">Thank you for joining Audition Revolution!</h6>
                            <p className="ver-text">The Profile is not active and a member of the Audition Revolution team will be in contact shortly to help complete the access process.</p>
                        </div>
                        :
                        <div className="veryfy_wrap">
                            <img src={ARLogo} alt="AR" />
                            <h6 className="ver-title">Thank you for joining Audition Revolution!</h6>
                            <p className="ver-text">You’re almost there! We just sent an email to verify your account. You should receive it soon - be on the lookout!</p>
                            <p className="ver-text">To be sure you receive all notifications from Audition Revolution, whitelist support@auditionrevolution.com and check your spam and promotions folder.</p>
                            <button type="button" aria-label="Resend Verification Email" onClick={this.verfiyEmail}>Resend Verification Email</button>
                            {/* <button type="button" aria-label="Resend Verification Email" onClick={this.verfiyEmail}>Resend Verification Email</button> */}
                        </div>
                    }
                </section>
        );
    }
}

export default withRouter(connect(null)(VerifyComponent));