export const servicesHelper = [
    {
      value: "Accompanist - Piano",
      label: "Accompanist - Piano",
    },
    {
      value: "Audition Coaching: Film/Television",
      label: "Audition Coaching: Film/Television",
    },
    {
      value: "Audition Coaching: Musical Theatre",
      label: "Audition Coaching: Musical Theatre",
    },
    {
      value: "Audition Coaching: Theatre",
      label: "Audition Coaching: Theatre",
    },
    {
      value: "Audition Recording",
      label: "Audition Recording",
    },
    {
      value: "Class: Acting",
      label: "Class: Acting",
    },
    {
      value: "Class: Acting for Film/Television",
      label: "Class: Acting for Film/Television",
    },
    {
      value: "Class: Acting Through Song",
      label: "Class: Acting Through Song",
    },
    {
      value: "Class: Audition Preparation",
      label: "Class: Audition Preparation",
    },
    {
      value: "Class: Dance - Ballet",
      label: "Class: Dance - Ballet",
    },
    {
      value: "Class: Dance - Jazz",
      label: "Class: Dance - Jazz",
    },
    {
      value: "Class: Dance - Modern",
      label: "Class: Dance - Modern",
    },
    {
      value: "Class: Dance - Musical Theatre",
      label: "Class: Dance - Musical Theatre",
    },
    {
      value: "Class: Dance - Tap",
      label: "Class: Dance - Tap",
    },
    {
      value: "Class: Dialects",
      label: "Class: Dialects",
    },
    {
      value: "Class: Makeup",
      label: "Class: Makeup",
    },
    {
      value: "Class: Movement",
      label: "Class: Movement",
    },
    {
      value: "Class: Stage Combat",
      label: "Class: Stage Combat",
    },
    {
      value: "Class: Voice - Classical",
      label: "Class: Voice - Classical",
    },
    {
      value: "Class: Voice - Musical Theatre",
      label: "Class: Voice - Musical Theatre",
    },
    {
      value: "Class: Voice Acting",
      label: "Class: Voice Acting",
    },
    {
      value: "Costuming",
      label: "Costuming",
    },
    {
      value: "Dramaturgy",
      label: "Dramaturgy",
    },
    {
      value: "Instrumentalist",
      label: "Instrumentalist",
    },
    {
      value: "Intimacy Coaching",
      label: "Intimacy Coaching",
    },
    {
      value: "Makeup",
      label: "Makeup",
    },
    {
      value: "Photography: Headshots",
      label: "Photography: Headshots",
    },
    {
      value: "Photography: Production",
      label: "Photography: Production",
    },
    {
      value: "Space: Performance",
      label: "Space: Performance",
    },
    {
      value: "Space: Rehearsal",
      label: "Space: Rehearsal",
    },
    {
      value: "Tax Preparation",
      label: "Tax Preparation",
    },
    {
      value: "Union Representation",
      label: "Union Representation",
    },
  ];
  
  