import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import update from 'immutability-helper'

// import makeData from './makeData'
import { withRouter } from 'react-router-dom'
import './TableComponent.css';
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const Table = ({ classs, columns, data, isRedorder, moveHRow, tableType }) => {
  // console.log(data);
  const [records, setRecords] = React.useState([])

  useEffect(() => {
    if (data && data.length > 0) {
      setRecords(data)
    }
  }, [data])

  // const getRowId = React.useCallback(row => {
  //   console.log(row);
  //   return row.id
  // }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data: records,
    columns,
    // getRowId,
  })

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    const upd = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });

    // console.log(upd);
    moveHRow(tableType, upd);

    setRecords(upd);
    // updateData(records);
  }

  const rowHandler = (result) => {
    console.log(result);
    const { source, destination } = result;
    if (!destination) return;
    const dragRecord = records[source.index];
    const hoverIndex = destination.index;
    const dragIndex = source.index
    const upd = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });

    // console.log(upd);
    moveHRow(tableType, upd);

    setRecords(upd);
  }

  return (
    // <DndProvider backend={TouchBackend} options={{enableMouseEvents:true,enableTouchEvents:true}}>
    <table {...getTableProps()} className={classs}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th className={isRedorder ? "display-show" : "display-none"}></th>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <DragDropContext onDragEnd={rowHandler}>
        <Droppable droppableId="table-body">
          {(provided, snapshot) => (
            <tbody {...getTableBodyProps()} {...provided.droppableProps} ref={provided.innerRef}>
              {rows.map(
                (row, index) => {
                  prepareRow(row);

                  return (<Draggable draggableId={row.id} key={index} index={row.index}>
                    {(provided, snapshot) => {

                      return <tr
                        key={index}
                        index={row.id}
                        // style={{ opacity }} 
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                      >
                        <td className={isRedorder ? "box-drag display-show" : "box-drag display-none"} {...provided.dragHandleProps}><i className="fa fa-arrows"></i></td>
                        {row.cells.map(cell => {
                          // console.log(cell);
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                      // return <Row
                      //   index={index}
                      //   row={row}
                      //   moveRow={moveRow}
                      //   isRedorder={isRedorder}
                      //   {...row.getRowProps()}
                      //   provided={provided}
                      //   snapshot={snapshot}
                      // />
                    }}
                  </Draggable>
                  )
                }
              )}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
    // </DndProvider>
  )
}

const DND_ITEM_TYPE = 'row';

const Row = ({ row, index, moveRow, isRedorder, isDragging, provided, snapshot }) => {
  // const dropRef = React.useRef(null)
  // const dragRef = React.useRef(null)

  // const [collectedProps, drop] = useDrop({
  //   accept: DND_ITEM_TYPE,
  //   hover(item, monitor) {
  //     // console.log(item)
  //     if (!dropRef.current) {
  //       return
  //     }
  //     const dragIndex = item.index
  //     const hoverIndex = index
  //     // Don't replace items with themselves
  //     if (dragIndex === hoverIndex) {
  //       return
  //     }
  //     // Determine rectangle on screen
  //     const hoverBoundingRect = dropRef.current.getBoundingClientRect()
  //     // Get vertical middle
  //     const hoverMiddleY =
  //       (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  //     // Determine mouse position
  //     const clientOffset = monitor.getClientOffset()
  //     // Get pixels to the top
  //     const hoverClientY = clientOffset.y - hoverBoundingRect.top
  //     // Only perform the move when the mouse has crossed half of the items height
  //     // When dragging downwards, only move when the cursor is below 50%
  //     // When dragging upwards, only move when the cursor is above 50%
  //     // Dragging downwards
  //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
  //       return
  //     }
  //     // Dragging upwards
  //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
  //       return
  //     }
  //     // Time to actually perform the action
  //     moveRow(dragIndex, hoverIndex)
  //     // Note: we're mutating the monitor item here!
  //     // Generally it's better to avoid mutations,
  //     // but it's good here for the sake of performance
  //     // to avoid expensive index searches.
  //     item.index = hoverIndex
  //   },
  // })

  // const [{ isDragging }, drag, preview] = useDrag({
  //   item: { type: DND_ITEM_TYPE, index },
  //   type: DND_ITEM_TYPE,
  //   index: index,
  //   collect: monitor => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // })

  const opacity = isDragging ? 0 : 1

  // preview(drop(dropRef))
  // drag(dragRef)

  return (
    <tr style={{ opacity }} {...provided.draggableProps} ref={provided.innerRef} isDragging={snapshot.isDragging}>
      <td className={isRedorder ? "box-drag display-show" : "box-drag display-none"} {...provided.dragHandleProps}><i className="fa fa-arrows"></i></td>
      {row.cells.map(cell => {
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
      })}
    </tr>
  )
}

const TableCompoent = (props) => {

  // const [column, setColumn] = useState([]);
  // const [data, setData] = useState([]);


  // console.log(this.state.data);
  return (
    // this.state.data && this.state.data.length > 0 &&
    props.data && props.data.length > 0 &&
    <Styles>
      <div className="table-responsive">
        <Table classs="table-custom" columns={props.columns} data={props.data} isRedorder={props.isRedorder} moveHRow={props.moveRow} tableType={props.tableType} />
      </div>
    </Styles>
  )
}

export default withRouter(props => <TableCompoent {...props} />);
