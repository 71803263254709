import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';

class AddUserModelComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            error: {
                firstName: '',
                lastName: '',
                username: '',
                password: ''
            },
            valid: {
                firstName: false,
                lastName: false,
                username: false,
                password: false
            }
        }
    }
    saveUser = async () => {
        try {
            const { firstName, lastName, username, password, valid } = this.state;

            const validField = valid.firstName || valid.lastName || valid.username || valid.password;

            if (validField) {
                const formData = new FormData();


                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('username', username);
                formData.append('password', password);
              
                const response = await apiHelper('admin/user/saveuser', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    //toasterSuccess(res.message);
                    this.props.onHide();
                    window.location.reload();
                } else {
                    toasterError(res.message);
                }
            } else {
                toasterError("All field is required!");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'firstName':
                valid.firstName = value.length > 0;
                error.firstName = valid.firstName ? "" : "First name is required!";
                break;
            case 'lastName':
                valid.lastName = value.length > 0;
                error.lastName = valid.lastName ? "" : "Last name is required!";
                break;
            case 'username':
                valid.username = value.length > 0;
                error.username = valid.username ? "" : "User name is required!";
                break;
            case 'password':
                valid.password = value.length > 0;
                error.password = valid.password ? "" : "Password is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    render() {
        const { firstName, lastName, username, password, valid, error } = this.state;
        const disabled = !valid.firstName || !valid.lastName || !valid.username || !valid.password;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Update User</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.inputHandler} placeholder="First Name" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.inputHandler} placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>User Name</label>
                                    <input type="text" name="username" className="form-control" value={username} onChange={this.inputHandler} placeholder="User Name" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" name="password" className="form-control" value={password} onChange={this.inputHandler} placeholder="Password" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.saveUser}>Save</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default AddUserModelComponent;