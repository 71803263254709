import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import * as ACTION_TYPES from './../../../redux/actions/type';
import { CSVLink } from "react-csv";

const CSVheaders2 = [
    { label: 'Tag Name', key: "tag" },
    { label: 'First Name', key: "firstName" },
    { label: 'Last Name', key: "lastName" },
    { label: 'Email', key: "email" },
    { label: 'Phone', key: "phoneNumber" },
    { label: 'Website', key: "website" },
    { label: 'City', key: "city" },
    { label: 'State', key: "state" },
    { label: 'Height', key: "heightInches" },
    // { label: 'Height', key: "heightInches" },
    { label: 'Eye Color', key: "eyeColor" },
    { label: 'Hair Color', key: "hairColor" },
    { label: 'Unions', key: "unions" },
    { label: 'Age Range', key: "ageRange" },
    { label: 'Vocal Range', key: "vocalRange" },
    { label: 'Gender', key: "genderArray" },
    { label: 'Ethnicity', key: "ethnicity" },
    { label: 'Notes', key: "notes" },
]
class CompanyTagsComponent extends Component {
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            tagsArray: [],
            notesArray: [],
            tagsEmail: null,
            tagDeleteConfirm: false,
            mainRow: null,
            CSVData: [],
            fileName: '',
            openTagName:""
        }

        this.csvLinkEl = React.createRef();

    }

    componentDidMount() {
        this._unmounted = true;
        this.getTags();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { isReload } = this.props;
        if (prevProps.isReload !== isReload) {
            this.getTags();
        }
    }

    getTags = async () => {
        const response = await apiHelper('company/getCompanyTags', 'GET', null, this.headers);
        const res = response.data;
        if (res.status) {
            const data = jwtDecode(res.data);
            this.props.reloadTagsFun(false);
            if (this._unmounted) {
                this.setState({
                    tagsArray: data,
                    // openTagName:data && data.length > 0?data[0].tagName:""
                })
            }
        }
    }

    getTagInfo = async (info) => {
        try {
            const formData = new FormData();
            // formData.append('id', this.state.id);
            formData.append('tagName', info.tagName);

            const response = await apiHelper('company/getTagDetails', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const datas = jwtDecode(res.data);
                this.setState({
                    CSVData: datas
                })
                return Promise.resolve(datas)
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    deteleActorTags = async (mainRow, subRow) => {
        try {

            // console.log(mainRow);

            const formData = new FormData();
            formData.append('id', subRow.at_id);

            const response = await apiHelper('company/deleteTagActor', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const { tagsArray } = this.state;
                let mainArray = tagsArray;
                if (tagsArray.length > 0) {
                    let findMainIndex = tagsArray.findIndex(info => info.tagName.toLowerCase() === mainRow.tagName.toLowerCase());
                    if (findMainIndex > -1) {
                        let subArray = tagsArray[findMainIndex].tagData;
                        subArray = subArray.filter(item => item.at_id !== subRow.at_id);
                        mainArray[findMainIndex].tagData = subArray;
                        if (subArray.length === 0) {
                            mainArray = mainArray.filter(lab => lab.tagName.toLowerCase() !== mainRow.tagName.toLowerCase())
                        }
                        // console.log(subArray);
                        // console.log(mainArray);
                        if (this._unmounted) {
                            this.setState({
                                tagsArray: mainArray
                            })
                        }
                    }

                }
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    openTagDeleteConfirmModel = (e, mainRow) => {
        e.preventDefault();
        this.setState({
            tagDeleteConfirm: true,
            mainRow: mainRow
        })

    }
    closeTagDeleteConfirmModel = () => {
        this.setState({
            tagDeleteConfirm: false
        })
    }
    deleteTag = async () => {
        // e.preventDefault();
        try {

            const { mainRow } = this.state;
            if (!mainRow) {
                toasterError("Invalid data.");
                return
            }
            const formData = new FormData();
            formData.append('data', JSON.stringify(mainRow.tagData));

            const response = await apiHelper('company/deleteTag', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {

                const { tagsArray } = this.state;
                const updateArray = tagsArray.filter(lab => lab.tagName.toLowerCase() !== mainRow.tagName.toLowerCase())

                if (this._unmounted) {
                    this.setState({
                        tagsArray: updateArray,
                        tagDeleteConfirm: false
                    })
                }
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }


        } catch (err) {
            toasterError(err.message);
        }
    }
    emailClicks = (info) => {
        let emails = [];
        for (let i = 0; i < info.length; i++) {
            emails.push(info[i].email)
        }
        const tokenData = jwtDecode(localStorage.getItem('userToken'));

        // console.log(emails);
        //{`mailto:${userEmail}?bcc=${emails}`}
        // return <a href={`mailto:${emails.join(',')}`} className="btn-default2 bg-gray">Email All</a>;
        return <a href={`mailto:${tokenData.email}?bcc=${emails.join(',')}`} className="btn-default2 bg-gray">Email All</a>;
    }
    redirectProfile = (item) => {
        console.log(this.props);
        const { history } = this.props;
        history.push(`/profile/${item.id}`)
    }

    csvHandler = async (info) => {
        const data = await this.getTagInfo(info)
        console.log(data);
        this.setState({
            CSVData: data,
            fileName: info.tagName
        }, () => {
            setTimeout(() => {
                this.csvLinkEl.current.link.click();
            })
        })
        console.log(data);
        // const newArray = data.map((n) => {
        //     let age = [];
        //     if (n.ageRange) {
        //         age = n.ageRange.split(',').join(',')
        //     }
        //     return { ...n, ageRange: String(age) }
        // })
        // console.log(newArray);
        // return data;
    }

    rediract = (info) =>{
        this.props.history.push(`/profile/tag?tag=${info.tagName}`)
        
    }

    accordionHandler = (tagName) =>{
        this.setState(state=>({
            openTagName:tagName !== state.openTagName?tagName:''
        }))
    }

    render() {

        const { tagsArray } = this.state;

        return (
            <div className="notes-wrap mt-3 h-100">
                <h2 className="note-title">My Tags</h2>
                <Accordion>
                    <div className="acrod-wrap">
                        {tagsArray.length > 0 ?
                            tagsArray.map((info, i) => <div className="acrod-bloc" key={i}>

                                <div className="sc-fznJRM label_acrodin">
                                    <Accordion.Toggle as={'button'} onClick={()=>this.accordionHandler(info.tagName)} className={`label_acrodin ${this.state.openTagName === info.tagName?'active':''}`} variant="link" eventKey={info.tagName}>
                                        <h6 className="title_acrodin mob-device" onClick={()=>this.rediract(info)}>{info.tagName} <small>{info.tagData.length} actor</small></h6>
                                    </Accordion.Toggle>
                                    <div className="modal-button modal-button2">
                                        <div className="visible-mob">
                                            <h6 className="title_acrodin"><small>{info.tagData.length} actor</small></h6>
                                        </div>
                                        <a href="/#" onClick={(e) => this.openTagDeleteConfirmModel(e, info)} className="btn-default2">Delete All</a>
                                        {info.tagData.length > 0 ?
                                            this.emailClicks(info.tagData)
                                            :
                                            null
                                        }
                                        {/* <a href="/#" title="Download" className="btn-default2"> */}
                                        <button type="button" className="btn-default2" style={{ marginRight: '10px' }} onClick={() => this.csvHandler(info)}>
                                            <i className="fa fa-download"></i>
                                            {/* </a> */}
                                        </button>
                                        <CSVLink headers={CSVheaders2} data={this.state.CSVData} filename={`${this.state.fileName}.csv`} ref={this.csvLinkEl} />
                                        {/* </CSVLink> */}
                                        {/* <CsvDownloader separator={','} columns={CSVheaders} datas={async () => await this.csvHandler(info)} filename={info.tagName} extension=".csv">
                                                {/* <a href="/#" title="Download" className="btn-default2"> *
                                                <button type="button" className="btn-default2" style={{ marginRight: '10px' }}>
                                                    <i className="fa fa-download"></i>
                                                    {/* </a> *
                                                </button>
                                            </CsvDownloader> */}
                                        <Accordion.Toggle as={'button'} onClick={()=>this.accordionHandler(info.tagName)} className={`label_acrodin ${this.state.openTagName === info.tagName?'active':''}`} variant="link" eventKey={info.tagName}>
                                            <i className="fa fa-angle-down icon-accordion"></i>
                                        </Accordion.Toggle>
                                    </div>
                                </div>

                                <Accordion.Collapse eventKey={info.tagName}>
                                    <div className="acro-tab">
                                        <ul className="acto-li">
                                            {info.tagData.length > 0 ?
                                                info.tagData.map((item, index) =>
                                                    <li key={index}>
                                                        <span onClick={() => this.redirectProfile(item)}>{item.name}</span>
                                                        <button className="btn-dele" onClick={() => this.deteleActorTags(info, item)}><i className="fa fa-times"></i></button>
                                                    </li>
                                                )
                                                :
                                                <li className="no-record">No record found.</li>
                                            }
                                        </ul>
                                    </div>
                                </Accordion.Collapse>
                            </div>
                            )
                            :
                            null
                        }
                    </div>
                </Accordion>
                <Modal show={this.state.tagDeleteConfirm} className="file_modal" onHide={this.closeTagDeleteConfirmModel} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <h2>Are you sure want to delete this?</h2>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.closeTagDeleteConfirmModel}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={this.deleteTag}>Yes, Delete it.</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStatesToProps = state => ({
    isReload: state.profileData.reloadTagAPIs
})

const mapDispatchToProps = dispatch => ({
    reloadTagsFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_TAG_API, payload: bool })
})
export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(CompanyTagsComponent));