import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

class Sidebar extends Component {
    render() {
        const token = localStorage.getItem('token');

        const { location: { pathname } } = this.props;
        const splitPath = pathname.split('/');
        let isAdmin = false;
        if (splitPath[1] === 'admin') {
            isAdmin = true;
        }

        return (
            isAdmin && token &&
            <div id="sidebar-nav" className="sidebar">
                <div className="sidebar-scroll">
                    <nav>
                        <ul className="nav">
                            <li>
                                <NavLink to="/admin/dashboard" activeClassName="active">
                                    <i className="lnr lnr-home"></i> <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/companies" activeClassName="active">
                                    <i className="lnr lnr-apartment"></i> <span>Companies</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/actors" activeClassName="active">
                                    <i className="lnr lnr-chart-bars"></i> <span>Actors</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/users" activeClassName="active">
                                    <i className="lnr lnr-users"></i> <span>Admin Users</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/servicedirectory" activeClassName="active">
                                    <i className="lnr lnr-user"></i> <span>Service Directory</span>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <Sidebar {...props} />);