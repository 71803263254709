import * as ACTION_TYPES from '../actions/type';

const initialState = {
    profileData: null,
    reloadNoteAPIs: false,
    reloadTagAPIs: false,
    userInfoUpdated: false,
    isActorUpdate:false
}

const profileReducers = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.USER_PROFILE_DATA:
            return {
                ...state,
                profileData: action.payload
            }
        case ACTION_TYPES.UPDATE_ACTOR_TAGS:
            return{
                ...state,
                isActorUpdate:action.payload
            }
        case ACTION_TYPES.RELOAD_TAG_API:
            return {
                ...state,
                reloadTagAPIs: action.payload
            }
        case ACTION_TYPES.RELOAD_NOTES_API:
            return {
                ...state,
                reloadNoteAPIs: action.payload
            }
        case ACTION_TYPES.UPDATE_USER_INFO:
            return {
                ...state,
                userInfoUpdated: action.payload
            }
        default:
            return state;
    }
}

export default profileReducers;