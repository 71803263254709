import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import './UserLoginComponent.css';
import ARLogo from './../../../assets/img/AR_Logo.png';
import { emailValidation } from '../../../helper/validation.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { apiHelper } from '../../../helper/api.helper';

class UserLoginComponent extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: {
                email: '',
                password: ''
            },
            valid: {
                email: false,
                password: false
            }
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkvalidation(name, value));
    }

    checkvalidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;

        switch (name) {
            case 'email':
                const emailValid = emailValidation(value);
                if (emailValid.status) {
                    error.email = '';
                    valid.email = emailValid.status;
                } else {
                    error.email = emailValid.message;
                    valid.email = emailValid.status;
                }
                break;
            case 'password':
                if (value !== '') {
                    error.password = '';
                    valid.password = true;
                } else {
                    error.password = 'password must required';
                    valid.password = false;
                }
                break;
            default:
                break;
        }

        this.setState({
            error,
            valid
        })

    }

    submitForm = async (e) => {
        try {

            e.preventDefault();
            const { valid } = this.state;
            if (valid.email && valid.password) {

                const formData = new FormData();
                formData.append('username', this.state.email);
                formData.append('password', this.state.password);

                const response = await apiHelper('user/login', 'POST', formData, null);
                const res = response.data;
                if (res.status) {
                    localStorage.setItem('userToken', res.data);
                    // toasterSuccess(res.message);
                    this.props.history.push('/profile');
                } else {
                    toasterError(res.message);
                }

            } else {
                toasterError('All field required');
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    render() {
        const token = localStorage.getItem('userToken');

        const { email, password, valid } = this.state;
        const disabled = !valid.email || !valid.password;
        // console.log(disabled);
        let btnClass = "MuiButtonBase-root MuiButton-root MuiButton-contained w-full mx-auto mt-16 MuiButton-containedPrimary";
        if (disabled) {
            btnClass += "  Mui-disabled Mui-disabled";
        }
        return (
            token ?
                <Redirect to="/profile" />
                :
                <div id="fuse-layout" >
                    <div className="sc-Axmtr hvJMgY">
                        <div className="main12">
                            <div className="jss12 mt-0" style={{ marginBottom: '64px', height: '100%', minHeight: '100vh' }}>
                                <div className="sc-AxjAm bcMPWx jss75">
                                    <div className="sc-AxiKw eSbheu">
                                        {/* <img src={ARLogo} alt="logo" /> */}
                                        <div className="heading-txt">
                                            <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-colorInherit">Welcome to Audition Revolution!</h3>
                                        </div>
                                        <div>
                                            <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorInherit">Sign In Or Create New Account!</h6>
                                        </div>
                                    </div>
                                    <div className="MuiPaper-root MuiCard-root sc-AxirZ bJCmFu MuiPaper-elevation1">
                                        <div className="MuiCardContent-root fflex-start">
                                            <h6 className="MuiTypography-root MuiTypography-h6">LOGIN TO YOUR ACCOUNT</h6>
                                            <form name="loginForm" onSubmit={this.submitForm}>
                                                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth" style={{ marginBottom: '1.6rem' }}>
                                                    <label data-shrink="true" htmlFor="login-email" id="login-email-label">Email
                                                <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                                                    </label>

                                                    <input name="email" required="" value={email} type="email" className="form-control" onChange={this.inputHandler} />
                                                </div>
                                                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth" style={{ marginBottom: '1.6rem' }}>
                                                    <label id="login-password-label">Password
                                                    <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span>
                                                    </label>
                                                    <input name="password" required="" value={password} type="password" className="form-control" onChange={this.inputHandler} />
                                                </div>
                                                <div className="forgot-password">
                                                    <a className="font-medium" href="/passwordReset">Forgot Password?</a>
                                                </div>
                                                <button className={btnClass} tabIndex="-1" type="submit" disabled={disabled} aria-label="LOG IN">
                                                    <span className="MuiButton-label">LOGIN</span>
                                                </button>
                                            </form>
                                            <div className="divider">
                                                <hr className="MuiDivider-root" />
                                                <span>OR</span>
                                                <hr className="MuiDivider-root" />
                                            </div>
                                            <div className="no-account">
                                                <span className="font-medium">Don't have an account?</span>
                                                <a className="font-medium" href="/register">Actor? Create an account</a>
                                                <a className="font-medium" href="/register-company">Company? Request Access</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="jss11">
                                <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters" style={{ padding: '0px' }}>
                                    <div className="sc-AxheI eXzlnr">
                                        <p className="MuiTypography-root MuiTypography-body2">Audition Revolution.v2 Beta</p>
                                        <p className="MuiTypography-root MuiTypography-body2">Contact Support: support@auditionrevolution.com</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
        );
    }
}
export default withRouter(props => <UserLoginComponent {...props} />);