import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import { Form } from "react-bootstrap";
import { toasterError, toasterSuccess } from "../../../helper/toaster.helper";
import { apiHelper } from "../../../helper/api.helper";

class RejectServiceDirectoryModal extends Component {
  headers = {
    Authorization: localStorage.getItem("token"),
  };
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.data.firstName,
      id: props.data.id,
      email: props.data.email,
      lastName: props.data.lastName,
      reason: "",
      error: {
        reason: "",
      },
      valid: {
        reason: false,
      },
    };
  }

  closeServiceDirectoryView = () => {
    this.setState({
      rejectServiceDirectoryViewModal: false,
    });
  };
  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.checkValidation(name, value)
    );
  };
  checkValidation = (name, value) => {
    let error = this.state.error;
    let valid = this.state.valid;

    switch (name) {
      case "reason":
        valid.reason = value.length > 0;
        error.reason = valid.reason ? "" : "Reason is required";
        break;
      default:
        break;
    }
    this.setState({
      error,
      valid,
    });
  };
  submitFun = async (e) => {
    try {
      e.preventDefault();
      const { valid } = this.state;
      if (
        !valid.reason
      ) {
        toasterError("Reason cannot be empty.");
        return;
      }

      const formData = new FormData();
      formData.append("id", this.state.id);
      formData.append("reason", this.state.reason);
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("email", this.state.email);

      const response = await apiHelper(
        "admin/service-directory/reject",
        "POST",
        formData,
        this.headers
      );
      const res = response.data;

      if (res.status) {
        toasterSuccess(res.message);
        this.setState({
          rejectServiceDirectoryViewModal: false,
          reason: ""
        });
        window.location.reload();
      } else {
        toasterError(res.message);
      }
    } catch (e) {
      toasterError(e.message);
    }
  };
  render() {
    const { reason, valid } = this.state;
    let disabled = false;
    if (
      !valid.reason 
    ) {
      disabled = true;
    }
    return (
      <>
        <Modal
          show={this.props.show}
          className="file_modal"
          onHide={this.props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h3>Reject Service Directory</h3>
          </Modal.Header>
          <form action="#" name="registerForm" onSubmit={this.submitFun}>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <label>Reason : </label>

                  <Form.Control
                    as="textarea"
                    name="reason"
                    onChange={this.inputHandler}
                    value={reason}
                    className="form-control"
                    rows={3}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={disabled}
              >
                Reject
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.props.onHide}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
export default withRouter((props) => (
  <RejectServiceDirectoryModal {...props} />
));
