import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError } from '../../../helper/toaster.helper';
import Autocomplete from "react-google-autocomplete";

class AddCompanyActor extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            cityState: '',
            city: '',
            state: '',
            heightFeet: 0,
            feetInches: 0,
            hairColor: "",
            eyeColor: "",
            website: "",
            representation: "",
            unionStatus: [],
            gender: [],
            age: [],
            ethnicity: [],
            voiceType: [],
            error: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                cityState: '',
                city: '',
                state: '',
                heightFeet: '',
                feetInches: '',
                hairColor: "",
                eyeColor: "",
                website: "",
                representation: "",
                unionStatus: "",
                gender: "",
                age: "",
                ethnicity: "",
                voiceType: ""
            },
            valid: {
                firstName: false,
                lastName: false,
                email: false,
                phoneNumber: true,
                cityState: true,
                city: true,
                state: true,
                heightFeet: true,
                feetInches: true,
                hairColor: true,
                eyeColor: true,
                website: true,
                representation: true,
                unionStatus: true,
                gender: true,
                age: true,
                ethnicity: true,
                voiceType: true
            }
        }
    }
    componentDidMount() {
        this.setState({
            activeStep: 0
        })
        // this.renderCityInput();
    }

    onNextHandler = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }))
    }

    onPrevHandler = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }))
    }
    inputHandler = (e) => {
        const { name, value, checked } = e.target;

        switch (name) {
            case 'unionStatus':
                let uniStatus = this.state.unionStatus;
                const index = uniStatus.indexOf(value);
                if (index > -1) {
                    if (!checked) uniStatus.splice(index, 1);
                } else {
                    uniStatus.push(value);
                }
                this.setState({
                    unionStatus: uniStatus
                }, () => this.checkValidation(name, value))
            case 'gender':
                let genderStatus = this.state.gender;
                const genderIndex = genderStatus.indexOf(value);
                if (genderIndex > -1) {
                    if (!checked) genderStatus.splice(genderIndex, 1);
                } else {
                    genderStatus.push(value);
                }
                this.setState({
                    gender: genderStatus
                }, () => this.checkValidation(name, value))
                break;
            case 'age':
                let ageStatus = this.state.age;
                const ageIndex = ageStatus.indexOf(value);
                if (ageIndex > -1) {
                    if (!checked) ageStatus.splice(ageIndex, 1);
                } else {
                    ageStatus.push(value);
                }
                this.setState({
                    age: ageStatus
                }, () => this.checkValidation(name, value))
                break;
            case 'ethnicity':
                let ethnicityStatus = this.state.ethnicity;
                const ethnicityIndex = ethnicityStatus.indexOf(value);
                if (ethnicityIndex > -1) {
                    if (!checked) ethnicityStatus.splice(ethnicityIndex, 1);
                } else {
                    ethnicityStatus.push(value);
                }
                this.setState({
                    ethnicity: ethnicityStatus
                }, () => this.checkValidation(name, value))
                break;
            case 'voiceType':
                let voiceTypeStatus = this.state.voiceType;
                const voiceTypeIndex = voiceTypeStatus.indexOf(value);
                if (voiceTypeIndex > -1) {
                    if (!checked) voiceTypeStatus.splice(voiceTypeIndex, 1);
                } else {
                    voiceTypeStatus.push(value);
                }
                this.setState({
                    voiceType: voiceTypeStatus
                }, () => this.checkValidation(name, value))
                break;
            default:
                this.setState({
                    [name]: value
                }, () => this.checkValidation(name, value))
                break;
        }

    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'firstName':
                valid.firstName = value.length > 0;
                error.firstName = valid.firstName ? "" : "First name is required!";
                break;
            case 'lastName':
                valid.lastName = value.length > 0;
                error.lastName = valid.lastName ? "" : "Last name is required!";
                break;
            case 'email':
                valid.email = value.length > 0;
                error.email = valid.email ? "" : "Email is required!";
                break;
            case 'phoneNumber':
                valid.phoneNumber = true;
                error.phoneNumber = "";
                // const regNumber = new RegExp(/^[0-9\b]+$/);
                // if (value.length > 0) {

                //     if (regNumber.test(value)) {
                //         valid.phoneNumber = true;
                //         error.phoneNumber = "";
                //     } else {
                //         valid.phoneNumber = false;
                //         error.phoneNumber = "Phone number is not valid";
                //     }

                // } else {
                //     valid.phoneNumber = false;
                //     error.phoneNumber = "Phone number is required!";
                // }
                break;
            case 'heightFeet':
                valid.heightFeet = value >= 0 && value !== "" ? true : false;
                error.heightFeet = "";
                break;
            case 'feetInches':
                valid.feetInches = value >= 0 && value !== "" ? true : false;
                error.feetInches = "";
                break;
            case 'hairColor':
                valid.hairColor = true;
                error.hairColor = "";
                break;
            case 'eyeColor':
                valid.eyeColor = true;
                error.eyeColor = "";
                break;
            case 'website':
                valid.website = true;
                error.website = "";
                break;
            case 'representation':
                valid.representation = true;
                error.representation = "";
                break;
            case 'unionStatus':
                valid.unionStatus = true;
                error.unionStatus = "";
                break;
            case 'gender':
                valid.gender = true;
                error.gender = "";
                break;
            case 'age':
                valid.age = true;
                error.age = "";
                break;
            case 'ethnicity':
                valid.ethnicity = true;
                error.ethnicity = "";
                break;
            case 'voiceType':
                valid.voiceType = true;
                error.voiceType = "";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }
    onSubmitStep1 = async (e) => {
        try {
            e.preventDefault();
            const { firstName, lastName, email, phoneNumber, city, state, heightFeet, feetInches, hairColor, eyeColor, website, representation, unionStatus, gender, age, ethnicity, voiceType, valid } = this.state;
            const validField = valid.firstName || valid.lastName || !valid.email || valid.phoneNumber || valid.cityState || valid.heightFeet || valid.feetInches || valid.hairColor || valid.eyeColor;

            if (validField) {
                const formData = new FormData();

                let Height = (heightFeet * 12);
                Height = (Height + Number(feetInches));

                formData.append('firstName', firstName);
                formData.append('lastName', lastName);
                formData.append('email', email);
                formData.append('phoneNumber', phoneNumber);
                formData.append('city', city);
                formData.append('state', state);
                formData.append('heightInches', Height);
                formData.append('hairColor', hairColor);
                formData.append('eyeColor', eyeColor);
                formData.append('website', website);
                formData.append('representation', representation);
                formData.append('unionStatus', unionStatus);
                formData.append('gender', gender);
                formData.append('age', age);
                formData.append('ethnicity', ethnicity);
                formData.append('voiceType', voiceType);

                const response = await apiHelper('company/saveCompanyActor', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    //toasterSuccess(res.message);
                    window.location.reload();
                } else {
                    toasterError(res.message);
                }
            } else {
                toasterError("All field is required!");
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    // renderCityInput = () => {
    //     const placeInstance = places({
    //         appId: "plL5W1957CCG",
    //         apiKey: "8f309db6542eab1fb7c18b83fcff357c",
    //         container: document.querySelector("#address-input")
    //     });

    //     placeInstance.configure({
    //         language: "en",
    //         type: this.props.type,
    //         countries: ["us"]
    //     });

    //     placeInstance.on("change", (e) => {
    //         const { name: city, administrative: state } = e.suggestion;

    //         this.setState({
    //             cityState: `${city}, ${state}`,
    //             city: city,
    //             state: state,
    //             error: {
    //                 ...this.state.error,
    //                 cityState: ''
    //             },
    //             valid: {
    //                 ...this.state.valid,
    //                 cityState: true
    //             }
    //         });
    //     });
    // }
    render() {
        const { firstName, lastName, email, phoneNumber, cityState, heightFeet, feetInches, hairColor, eyeColor, website, representation, unionStatus, gender, age, ethnicity, voiceType, valid, error } = this.state;
        const disabled = !valid.firstName || !valid.lastName || !valid.email || !valid.phoneNumber || !valid.cityState || !valid.heightFeet || !valid.feetInches || !valid.hairColor || !valid.eyeColor;

        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Add New Actor</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="step_conetnt">
                            <div className="form_erap">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.inputHandler} placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.inputHandler} placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" name="email" className="form-control" value={email} onChange={this.inputHandler} placeholder="Email" />
                                            {error.email !== "" ? <p className="error-text">{error.email}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" name="phoneNumber" className="form-control" value={phoneNumber} onChange={this.inputHandler} placeholder="Phone" />
                                            {error.phoneNumber !== "" ? <p className="error-text">{error.phoneNumber}</p> : null}
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>City, State</label>
                                            {/* <input type="text" name="cityState" id="address-input" className="form-control" value={cityState} onChange={this.inputHandler} placeholder="City,State" /> */}
                                            <Autocomplete
                                                name="cityState"
                                                required=""
                                                value={cityState}
                                                type="text"
                                                className="form-control"
                                                id="address-input"
                                                placeholder="City,State"
                                                onChange={this.inputHandler}
                                                apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                                                onPlaceSelected={(place) => {
                                                var formatted_address = place.formatted_address.split(",");
                                                this.setState({
                                                    cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                                    city: formatted_address[0],
                                                    state: formatted_address[1],
                                                    error: {
                                                    ...this.state.error,
                                                    cityState: "",
                                                    },
                                                    valid: {
                                                    ...this.state.valid,
                                                    cityState: true,
                                                    },
                                                });
                                                }}
                                            />    
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Height Feet</label>
                                            <input type="number" name="heightFeet" className="form-control" value={heightFeet} onChange={this.inputHandler} placeholder="Height Feet" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Height Inches</label>
                                            <input type="number" name="feetInches" className="form-control" value={feetInches} onChange={this.inputHandler} placeholder="Height Inches" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Hair Color</label>
                                            <select name="hairColor" className="form-control" value={hairColor} onChange={this.inputHandler}>
                                                <option value="">Select Hair Color</option>
                                                <option value="black">Black</option>
                                                <option value="brown">Brown</option>
                                                <option value="red">Red</option>
                                                <option value="blonde">Blonde</option>
                                                <option value="gray">Gray</option>
                                                <option value="white">White</option>
                                                <option value="other">Other</option>
                                                <option value="unknown">Unknown</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Eye Color</label>
                                            <select name="eyeColor" className="form-control" value={eyeColor} onChange={this.inputHandler}>
                                                <option value="">Select Eye Color</option>
                                                <option value="brown">Brown</option>
                                                <option value="hazel">Hazel</option>
                                                <option value="blue">Blue</option>
                                                <option value="green">Green</option>
                                                <option value="gray">Gray</option>
                                                <option value="amber">Amber</option>
                                                <option value="other">Other</option>
                                                <option value="unknown">Unknown</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Representation</label>
                                            <input type="text" name="representation" className="form-control" value={representation} onChange={this.inputHandler} placeholder="Representation" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Website</label>
                                            <input type="text" name="website" className="form-control" value={website} onChange={this.inputHandler} placeholder="Website" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label-title">Union Status<strong>*</strong></label>
                                            <div className="input_groups">
                                                <label htmlFor="non-union"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Non-Union")} defaultValue="Non-Union" id="non-union" onChange={this.inputHandler} /> Non-Union</label>
                                                <label htmlFor="aea"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA")} defaultValue="AEA" id="AEA" onChange={this.inputHandler} /> AEA</label>
                                                <label htmlFor="aea-emc"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA-EMC")} id="aea-emc" defaultValue="AEA-EMC" onChange={this.inputHandler} /> AEA-EMC</label>
                                                <label htmlFor="unionOther"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Other")} id="unionOther" defaultValue="Other" onChange={this.inputHandler} /> Other</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label-title">Gender<strong>*</strong></label>
                                            <div className="input_groups">
                                                <label htmlFor="female"><input type="checkbox" name="gender" checked={gender.includes("Female")} defaultValue="Female" id="female" onChange={this.inputHandler} /> Female</label>
                                                <label htmlFor="male"><input type="checkbox" name="gender" checked={gender.includes("Male")} defaultValue="Male" id="male" onChange={this.inputHandler} /> Male</label>
                                                <label htmlFor="non-binary"><input type="checkbox" name="gender" checked={gender.includes("Non-Binary")} defaultValue="Non-Binary" id="non-binary" onChange={this.inputHandler} /> Non-Binary</label>
                                                <label htmlFor="genderOther"><input type="checkbox" name="gender" checked={gender.includes("Other")} defaultValue="Other" id="genderOther" onChange={this.inputHandler} /> Other</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label-title">Age<strong>*</strong></label>
                                            <div className="input_groups">
                                                <label htmlFor="child"><input type="checkbox" name="age" defaultValue="Child" checked={age.includes("Child")} onChange={this.inputHandler} id="child" /> Child</label>
                                                <label htmlFor="teen"><input type="checkbox" name="age" defaultValue="Teen" checked={age.includes("Teen")} onChange={this.inputHandler} id="teen" /> Teen</label>
                                                <label htmlFor="teens20"><input type="checkbox" name="age" defaultValue="Teens-20" checked={age.includes("Teens-20")} onChange={this.inputHandler} id="teens20" /> Teens-20</label>
                                                <label htmlFor="20s30s"><input type="checkbox" name="age" defaultValue="20s-30s" checked={age.includes("20s-30s")} onChange={this.inputHandler} id="20s30s" /> 20s-30s</label>
                                                <label htmlFor="30s40s"><input type="checkbox" name="age" defaultValue="30s-40s" checked={age.includes("30s-40s")} onChange={this.inputHandler} id="30s40s" /> 30s-40s</label>
                                                <label htmlFor="40s50s"><input type="checkbox" name="age" defaultValue="40s-50s" checked={age.includes("40s-50s")} onChange={this.inputHandler} id="40s50s" /> 40s-50s</label>
                                                <label htmlFor="50s60s"><input type="checkbox" name="age" defaultValue="50s-60s" checked={age.includes("50s-60s")} onChange={this.inputHandler} id="50s60s" /> 50s-60s</label>
                                                <label htmlFor="60s70s"><input type="checkbox" name="age" defaultValue="60s-70s" checked={age.includes("60s-70s")} onChange={this.inputHandler} id="60s70s" /> 60s-70s</label>
                                                <label htmlFor="over70"><input type="checkbox" name="age" defaultValue="Over 70" checked={age.includes("Over 70")} onChange={this.inputHandler} id="over70" /> Over 70</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label-title">Ethnicity<strong>*</strong></label>
                                            <div className="input_groups">
                                                <label htmlFor="africanamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("African American")} defaultValue="African American" onChange={this.inputHandler} id="africanamerican" /> African American</label>
                                                <label htmlFor="asian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Asian")} defaultValue="Asian" onChange={this.inputHandler} id="asian" /> Asian</label>
                                                <label htmlFor="caucasian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Caucasian")} defaultValue="Caucasian" onChange={this.inputHandler} id="caucasian" /> Caucasian</label>
                                                <label htmlFor="hispanic"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hispanic")} defaultValue="Hispanic" onChange={this.inputHandler} id="hispanic" /> Hispanic</label>
                                                <label htmlFor="latino"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Latino")} defaultValue="Latino" onChange={this.inputHandler} id="latino" /> Latino</label>
                                                <label htmlFor="nativeamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Native American")} defaultValue="Native American" onChange={this.inputHandler} id="nativeamerican" /> Native American</label>
                                                <label htmlFor="alaskannative"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Alaskan Native")} defaultValue="Alaskan Native" onChange={this.inputHandler} id="alaskannative" /> Alaskan Native</label>
                                                <label htmlFor="hawaiian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hawaiian")} defaultValue="Hawaiian" onChange={this.inputHandler} id="hawaiian" /> Hawaiian</label>
                                                <label htmlFor="middleeastern"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Middle Eastern")} defaultValue="Middle Eastern" onChange={this.inputHandler} id="middleeastern" /> Middle Eastern</label>
                                                <label htmlFor="northafrican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("North African")} defaultValue="North African" onChange={this.inputHandler} id="northafrican" /> North African</label>
                                                <label htmlFor="multicultural"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Multi-Cultural")} defaultValue="Multi-Cultural" onChange={this.inputHandler} id="multicultural" /> Multi-Cultural</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="label-title">Voice Type<strong>*</strong></label>
                                            <div className="input_groups">
                                                <label htmlFor="soprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Soprano")} defaultValue="Soprano" onChange={this.inputHandler} id="soprano" /> Soprano</label>
                                                <label htmlFor="mezzosopranobelter"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano Belter")} defaultValue="Mezzo Soprano Belter" onChange={this.inputHandler} id="mezzosopranobelter" /> Mezzo Soprano Belter</label>
                                                <label htmlFor="mezzosoprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano")} defaultValue="Mezzo Soprano" onChange={this.inputHandler} id="mezzosoprano" /> Mezzo Soprano</label>
                                                <label htmlFor="alto"><input type="checkbox" name="voiceType" checked={voiceType.includes("Alto")} defaultValue="Alto" onChange={this.inputHandler} id="alto" /> Alto</label>
                                                <label htmlFor="tenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Tenor")} defaultValue="Tenor" onChange={this.inputHandler} id="tenor" /> Tenor</label>
                                                <label htmlFor="baritenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritenor")} defaultValue="Baritenor" onChange={this.inputHandler} id="baritenor" /> Baritenor</label>
                                                <label htmlFor="baritone"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritone")} defaultValue="Baritone" onChange={this.inputHandler} id="baritone" /> Baritone</label>
                                                <label htmlFor="bass"><input type="checkbox" name="voiceType" checked={voiceType.includes("Bass")} defaultValue="Bass" onChange={this.inputHandler} id="bass" /> Bass</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.onSubmitStep1} disabled={disabled}>Save</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default AddCompanyActor;

// class Step1 extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             firstName: '',
//             lastName: '',
//             email: '',
//             phoneNumber: '',
//             cityState: '',
//             city: '',
//             state: '',
//             heightFeet: '',
//             feetInches: '',
//             hairColor: "",
//             eyeColor: "",
//             website: "",
//             representation: "",
//             error: {
//                 firstName: '',
//                 lastName: '',
//                 email: '',
//                 phoneNumber: '',
//                 cityState: '',
//                 city: '',
//                 state: '',
//                 heightFeet: '',
//                 feetInches: '',
//                 hairColor: "",
//                 eyeColor: "",
//                 website: "",
//                 representation: ""
//             },
//             valid: {
//                 firstName: false,
//                 lastName: false,
//                 email: false,
//                 phoneNumber: false,
//                 cityState: false,
//                 city: false,
//                 state: false,
//                 heightFeet: false,
//                 feetInches: false,
//                 hairColor: false,
//                 eyeColor: false,
//                 website: false,
//                 representation: false
//             }
//         }
//     }

//     componentDidMount() {
//         this.renderCityInput();
//     }

//     inputHandler = (e) => {
//         const { name, value } = e.target;
//         this.setState({
//             [name]: value
//         }, () => this.checkValidation(name, value))
//     }

//     checkValidation = (name, value) => {
//         let error = this.state.error;
//         let valid = this.state.valid;
//         switch (name) {
//             case 'firstName':
//                 valid.firstName = value.length > 0;
//                 error.firstName = valid.firstName ? "" : "First name is required!";
//                 break;
//             case 'lastName':
//                 valid.lastName = value.length > 0;
//                 error.lastName = valid.lastName ? "" : "Last name is required!";
//                 break;
//             case 'email':
//                 valid.email = value.length > 0;
//                 error.email = valid.email ? "" : "Email is required!";
//                 break;
//             case 'phoneNumber':
//                 const regNumber = new RegExp(/^[0-9\b]+$/);
//                 if (value.length > 0) {

//                     if (regNumber.test(value)) {
//                         valid.phoneNumber = true;
//                         error.phoneNumber = "";
//                     } else {
//                         valid.phoneNumber = false;
//                         error.phoneNumber = "Phone number is not valid";
//                     }

//                 } else {
//                     valid.phoneNumber = false;
//                     error.phoneNumber = "Phone number is required!";
//                 }
//                 break;
//             case 'heightFeet':
//                 valid.heightFeet = value > 0;
//                 error.heightFeet = valid.heightFeet ? "" : "Height Feet number is required!";
//                 break;
//             case 'feetInches':
//                 valid.feetInches = value > 0;
//                 error.feetInches = valid.feetInches ? "" : "Height Inches is required!";
//                 break;
//             case 'hairColor':
//                 valid.hairColor = value.length > 0;
//                 error.hairColor = valid.hairColor ? "" : "Hire Color is required!";
//                 break;
//             case 'eyeColor':
//                 valid.eyeColor = value.length > 0;
//                 error.eyeColor = valid.eyeColor ? "" : "Eye Color is required!";
//                 break;
//             default:
//                 break;
//         }

//         this.setState({
//             error: error,
//             valid: valid
//         })
//     }

//     renderCityInput = () => {
//         const placeInstance = places({
//             appId: "plL5W1957CCG",
//             apiKey: "8f309db6542eab1fb7c18b83fcff357c",
//             container: document.querySelector("#address-input")
//         });

//         placeInstance.configure({
//             language: "en",
//             type: this.props.type,
//             countries: ["us"]
//         });

//         placeInstance.on("change", (e) => {
//             const { name: city, administrative: state } = e.suggestion;

//             this.setState({
//                 cityState: `${city}, ${state}`,
//                 city: city,
//                 state: state,
//                 error: {
//                     ...this.state.error,
//                     cityState: ''
//                 },
//                 valid: {
//                     ...this.state.valid,
//                     cityState: true
//                 }
//             });
//         });
//     }


//     onSubmitStep1 = async (e) => {
//         try {
//             e.preventDefault();

//             const { firstName, lastName, email, phoneNumber, city, state, cityState, heightFeet, feetInches, hairColor, eyeColor, website, representation, valid } = this.state;
//             const validField = valid.firstName || valid.lastName || !valid.email || valid.phoneNumber || valid.cityState || valid.heightFeet || valid.feetInches || valid.hairColor || valid.eyeColor;

//             if (validField) {
//                 const formData = new FormData();

//                 let Height = (heightFeet * 12);
//                 Height = (Height + Number(feetInches));

//                 formData.append('firstName', firstName);
//                 formData.append('lastName', lastName);
//                 formData.append('email', email);
//                 formData.append('phoneNumber', phoneNumber);
//                 formData.append('city', city);
//                 formData.append('state', state);
//                 formData.append('heightInches', Height);
//                 formData.append('hairColor', hairColor);
//                 formData.append('eyeColor', eyeColor);
//                 formData.append('website', website);
//                 formData.append('representation', representation);

//                 const response = await apiHelper('company/saveActorStep1', 'POST', formData, this.headers);
//                 const res = response.data;
//                 if (res.status) {
//                     this.props.onNext();
//                 } else {
//                     toasterError(res.message);
//                 }
//             } else {
//                 toasterError("All field is required!");
//             }
//         } catch (e) {
//             toasterError(e.message);
//         }
//     }


//     render() {

//         const { firstName, lastName, email, phoneNumber, cityState, heightFeet, feetInches, hairColor, eyeColor, website, representation, valid, error } = this.state;
//         const disabled = !valid.firstName || !valid.lastName || !valid.email || !valid.phoneNumber || !valid.cityState || !valid.heightFeet || !valid.feetInches || !valid.hairColor || !valid.eyeColor;

//         return <div className="step_conetnt">
//             <div className="form_erap">
//                 <div className="row">
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.inputHandler} placeholder="First Name" />
//                         </div>
//                     </div>
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.inputHandler} placeholder="Last Name" />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="email" className="form-control" value={email} onChange={this.inputHandler} placeholder="Email" />
//                             {error.email !== "" ? <p className="error-text">{error.email}</p> : null}
//                         </div>
//                     </div>
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="phoneNumber" className="form-control" value={phoneNumber} onChange={this.inputHandler} placeholder="Phone" />
//                             {error.phoneNumber !== "" ? <p className="error-text">{error.phoneNumber}</p> : null}
//                         </div>
//                     </div>

//                 </div>
//                 <div className="row">
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="cityState" id="address-input" className="form-control" value={cityState} onChange={this.inputHandler} placeholder="City,State" />
//                         </div>
//                     </div>
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="heightFeet" className="form-control" value={heightFeet} onChange={this.inputHandler} placeholder="Height Feet" />
//                         </div>
//                     </div>

//                 </div>
//                 <div className="row">
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <input type="text" name="feetInches" className="form-control" value={feetInches} onChange={this.inputHandler} placeholder="Height Inches" />
//                         </div>
//                     </div>
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <select name="hairColor" className="form-control" value={hairColor} onChange={this.inputHandler}>
//                                 <option value="">Select Hair Color</option>
//                                 <option value="black">Black</option>
//                                 <option value="brown">Brown</option>
//                                 <option value="red">Red</option>
//                                 <option value="blonde">Blonde</option>
//                                 <option value="gray">Gray</option>
//                                 <option value="white">White</option>
//                                 <option value="other">Other</option>
//                                 <option value="unknown">Unknown</option>
//                             </select>
//                         </div>
//                     </div>

//                 </div>
//                 <div className="row">
//                     <div className="col-sm-6">
//                         <div className="form-group">
//                             <select name="eyeColor" className="form-control" value={eyeColor} onChange={this.inputHandler}>
//                                 <option value="">Select Eye Color</option>
//                                 <option value="brown">Brown</option>
//                                 <option value="hazel">Hazel</option>
//                                 <option value="blue">Blue</option>
//                                 <option value="green">Green</option>
//                                 <option value="gray">Gray</option>
//                                 <option value="amber">Amber</option>
//                                 <option value="other">Other</option>
//                                 <option value="unknown">Unknown</option>
//                             </select>
//                         </div>
//                     </div>

//                 </div>
//                 <div className="row">
//                     <div className="col-sm-12">
//                         <div className="form-group">
//                             <input type="text" name="representation" className="form-control" value={representation} onChange={this.inputHandler} placeholder="Representation" />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     <div className="col-sm-12">
//                         <div className="form-group">
//                             <input type="text" name="website" className="form-control" value={website} onChange={this.inputHandler} placeholder="Website" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="btn-gr">
//                 <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmitStep1} disabled={disabled}>Next</button>
//             </div>
//         </div >
//     }
// }

// class Step2 extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             unionStatus: [],
//             gender: [],
//             age: [],
//             ethnicity: [],
//             voiceType: []
//         }
//     }


//     inputHandler = (e) => {
//         const { name, value, checked } = e.target;
//         switch (name) {
//             case 'unionStatus':
//                 let uniStatus = this.state.unionStatus;
//                 const index = uniStatus.indexOf(value);
//                 if (index > -1) {
//                     if (!checked) uniStatus.splice(index, 1);
//                 } else {
//                     uniStatus.push(value);
//                 }
//                 this.setState({
//                     unionStatus: uniStatus
//                 })
//                 break;
//             case 'gender':
//                 let genderStatus = this.state.gender;
//                 const genderIndex = genderStatus.indexOf(value);
//                 if (genderIndex > -1) {
//                     if (!checked) genderStatus.splice(genderIndex, 1);
//                 } else {
//                     genderStatus.push(value);
//                 }
//                 this.setState({
//                     gender: genderStatus
//                 })
//                 break;
//             case 'age':
//                 let ageStatus = this.state.age;
//                 const ageIndex = ageStatus.indexOf(value);
//                 if (ageIndex > -1) {
//                     if (!checked) ageStatus.splice(ageIndex, 1);
//                 } else {
//                     ageStatus.push(value);
//                 }
//                 this.setState({
//                     age: ageStatus
//                 })
//                 break;
//             case 'ethnicity':
//                 let ethnicityStatus = this.state.ethnicity;
//                 const ethnicityIndex = ethnicityStatus.indexOf(value);
//                 if (ethnicityIndex > -1) {
//                     if (!checked) ethnicityStatus.splice(ethnicityIndex, 1);
//                 } else {
//                     ethnicityStatus.push(value);
//                 }
//                 this.setState({
//                     ethnicity: ethnicityStatus
//                 })
//                 break;
//             case 'voiceType':
//                 let voiceTypeStatus = this.state.voiceType;
//                 const voiceTypeIndex = voiceTypeStatus.indexOf(value);
//                 if (voiceTypeIndex > -1) {
//                     if (!checked) voiceTypeStatus.splice(voiceTypeIndex, 1);
//                 } else {
//                     voiceTypeStatus.push(value);
//                 }
//                 this.setState({
//                     voiceType: voiceTypeStatus
//                 })
//                 break;
//             default:
//                 break;
//         }
//     }

//     onSubmit = async (e) => {

//         const { unionStatus, gender, age, ethnicity, voiceType } = this.state;

//         if (unionStatus.length === 0) {
//             return toasterError('Union Status is required');
//         }
//         if (gender.length === 0) {
//             return toasterError('Gender is required');
//         }
//         if (age.length === 0) {
//             return toasterError('Age is required');
//         }
//         if (ethnicity.length === 0) {
//             return toasterError('Ethnicity is required');
//         }
//         if (voiceType.length === 0) {
//             return toasterError('Voice type is required');
//         }

//         try {
//             const formData = new FormData();
//             formData.append('unionStatus', unionStatus);
//             formData.append('gender', gender);
//             formData.append('age', age);
//             formData.append('ethnicity', ethnicity);
//             formData.append('voiceType', voiceType);

//             const response = await apiHelper('actor/saveActorStep2', 'POST', formData, this.headers);
//             const res = response.data;
//             if (res.status) {
//                 this.props.onNext();
//             } else {
//                 toasterError(res.message);
//             }

//         } catch (e) {
//             toasterError(e.message);
//         }
//     }

//     render() {

//         return <div className="step_conetnt">
//             <div className="form_erap">
//                 <form action="/" method="post">
//                     <div className="row">
//                         <div className="col-sm-12">
//                             <div className="form-group">
//                                 <label className="label-title">Union Status<strong>*</strong></label>
//                                 <div className="input_groups">
//                                     <label htmlFor="non-union"><input type="checkbox" name="unionStatus" defaultValue="Non-Union" id="non-union" onChange={this.inputHandler} /> Non-Union</label>
//                                     <label htmlFor="aea"><input type="checkbox" name="unionStatus" defaultValue="AEA" id="AEA" onChange={this.inputHandler} /> AEA</label>
//                                     <label htmlFor="aea-emc"><input type="checkbox" name="unionStatus" id="aea-emc" defaultValue="AEA-EMC" onChange={this.inputHandler} /> AEA-EMC</label>
//                                     <label htmlFor="unionOther"><input type="checkbox" name="unionStatus" id="unionOther" defaultValue="Other" onChange={this.inputHandler} /> Other</label>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-sm-12">
//                             <div className="form-group">
//                                 <label className="label-title">Gender<strong>*</strong></label>
//                                 <div className="input_groups">
//                                     <label htmlFor="female"><input type="checkbox" name="gender" defaultValue="Female" id="female" onChange={this.inputHandler} /> Female</label>
//                                     <label htmlFor="male"><input type="checkbox" name="gender" defaultValue="Male" id="male" onChange={this.inputHandler} /> Male</label>
//                                     <label htmlFor="non-binary"><input type="checkbox" name="gender" defaultValue="Non-Binary" id="non-binary" onChange={this.inputHandler} /> Non-Binary</label>
//                                     <label htmlFor="genderOther"><input type="checkbox" name="gender" defaultValue="Other" id="genderOther" onChange={this.inputHandler} /> Other</label>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-sm-12">
//                             <div className="form-group">
//                                 <label className="label-title">Age<strong>*</strong></label>
//                                 <div className="input_groups">
//                                     <label htmlFor="child"><input type="checkbox" name="age" defaultValue="Child" onChange={this.inputHandler} id="child" /> Child</label>
//                                     <label htmlFor="teen"><input type="checkbox" name="age" defaultValue="Teen" onChange={this.inputHandler} id="teen" /> Teen</label>
//                                     <label htmlFor="teens20"><input type="checkbox" name="age" defaultValue="Teens-20" onChange={this.inputHandler} id="teens20" /> Teens-20</label>
//                                     <label htmlFor="20s30s"><input type="checkbox" name="age" defaultValue="20s-30s" onChange={this.inputHandler} id="20s30s" /> 20s-30s</label>
//                                     <label htmlFor="30s40s"><input type="checkbox" name="age" defaultValue="30s-40s" onChange={this.inputHandler} id="30s40s" /> 30s-40s</label>
//                                     <label htmlFor="40s50s"><input type="checkbox" name="age" defaultValue="40s-50s" onChange={this.inputHandler} id="40s50s" /> 40s-50s</label>
//                                     <label htmlFor="50s60s"><input type="checkbox" name="age" defaultValue="50s-60s" onChange={this.inputHandler} id="50s60s" /> 50s-60s</label>
//                                     <label htmlFor="60s70s"><input type="checkbox" name="age" defaultValue="60s-70s" onChange={this.inputHandler} id="60s70s" /> 60s-70s</label>
//                                     <label htmlFor="over70"><input type="checkbox" name="age" defaultValue="Over 70" onChange={this.inputHandler} id="over70" /> Over 70</label>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-sm-12">
//                             <div className="form-group">
//                                 <label className="label-title">Ethnicity<strong>*</strong></label>
//                                 <div className="input_groups">
//                                     <label htmlFor="africanamerican"><input type="checkbox" name="ethnicity" defaultValue="African American" onChange={this.inputHandler} id="africanamerican" /> African American</label>
//                                     <label htmlFor="asian"><input type="checkbox" name="ethnicity" defaultValue="Asian" onChange={this.inputHandler} id="asian" /> Asian</label>
//                                     <label htmlFor="caucasian"><input type="checkbox" name="ethnicity" defaultValue="Caucasian" onChange={this.inputHandler} id="caucasian" /> Caucasian</label>
//                                     <label htmlFor="hispanic"><input type="checkbox" name="ethnicity" defaultValue="Hispanic" onChange={this.inputHandler} id="hispanic" /> Hispanic</label>
//                                     <label htmlFor="latino"><input type="checkbox" name="ethnicity" defaultValue="Latino" onChange={this.inputHandler} id="latino" /> Latino</label>
//                                     <label htmlFor="nativeamerican"><input type="checkbox" name="ethnicity" defaultValue="Native American" onChange={this.inputHandler} id="nativeamerican" /> Native American</label>
//                                     <label htmlFor="alaskannative"><input type="checkbox" name="ethnicity" defaultValue="Alaskan Native" onChange={this.inputHandler} id="alaskannative" /> Alaskan Native</label>
//                                     <label htmlFor="hawaiian"><input type="checkbox" name="ethnicity" defaultValue="Hawaiian" onChange={this.inputHandler} id="hawaiian" /> Hawaiian</label>
//                                     <label htmlFor="middleeastern"><input type="checkbox" name="ethnicity" defaultValue="Middle Eastern" onChange={this.inputHandler} id="middleeastern" /> Middle Eastern</label>
//                                     <label htmlFor="northafrican"><input type="checkbox" name="ethnicity" defaultValue="North African" onChange={this.inputHandler} id="northafrican" /> North African</label>
//                                     <label htmlFor="multicultural"><input type="checkbox" name="ethnicity" defaultValue="Multi-Cultural" onChange={this.inputHandler} id="multicultural" /> Multi-Cultural</label>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-sm-12">
//                             <div className="form-group">
//                                 <label className="label-title">Voice Type<strong>*</strong></label>
//                                 <div className="input_groups">
//                                     <label htmlFor="soprano"><input type="checkbox" name="voiceType" defaultValue="Soprano" onChange={this.inputHandler} id="soprano" /> Soprano</label>
//                                     <label htmlFor="mezzosopranobelter"><input type="checkbox" name="voiceType" defaultValue="Mezzo Soprano Belter" onChange={this.inputHandler} id="mezzosopranobelter" /> Mezzo Soprano Belter</label>
//                                     <label htmlFor="mezzosoprano"><input type="checkbox" name="voiceType" defaultValue="Mezzo Soprano" onChange={this.inputHandler} id="mezzosoprano" /> Mezzo Soprano</label>
//                                     <label htmlFor="alto"><input type="checkbox" name="voiceType" defaultValue="Alto" onChange={this.inputHandler} id="alto" /> Alto</label>
//                                     <label htmlFor="tenor"><input type="checkbox" name="voiceType" defaultValue="Tenor" onChange={this.inputHandler} id="tenor" /> Tenor</label>
//                                     <label htmlFor="baritenor"><input type="checkbox" name="voiceType" defaultValue="Baritenor" onChange={this.inputHandler} id="baritenor" /> Baritenor</label>
//                                     <label htmlFor="baritone"><input type="checkbox" name="voiceType" defaultValue="Baritone" onChange={this.inputHandler} id="baritone" /> Baritone</label>
//                                     <label htmlFor="bass"><input type="checkbox" name="voiceType" defaultValue="Bass" onChange={this.inputHandler} id="bass" /> Bass</label>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//             <div className="btn-gr">
//                 <button type="button" aria-label="Next" className="btn btn-default" style={{ marginRight: '10px' }} onClick={this.props.onPrev}>Back</button>
//                 <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmit}>Next</button>
//             </div>
//         </div>
//     }
// }

// class Step3 extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             filesArr: null
//         }
//     }

//     onSubmit = async (e) => {
//         try {
//             const file = this.state.filesArr;
//             let formData = new FormData();
//             formData.append('profilePic', file);

//             const response = await apiHelper('actor/savestep3', 'POST', formData, this.headers);
//             const res = response.data;
//             if (res.status) {
//                 this.props.onNext();
//             } else {
//                 toasterError(res.message);
//             }
//         } catch (e) {
//             toasterError(e.message);
//         }
//     }
//     uploadFiles = (e) => {
//         const file = e.target.files[0];
//         this.setState({
//             filesArr: file
//         })
//     }

//     render() {
//         return <div className="step_conetnt">
//             <div className="form_erap">
//                 <h2>Upload your headshot!</h2>
//                 <div className="file-wra">
//                     <input type="file" name="file" className="form-control-file" onChange={this.uploadFiles} />
//                 </div>
//             </div>
//             <div className="btn-gr">
//                 <button type="button" aria-label="Next" className="btn btn-default" style={{ marginRight: '10px' }} onClick={this.props.onPrev}>Back</button>
//                 <button type="button" aria-label="Next" className="btn btn-primary" onClick={this.onSubmit}>Next</button>
//             </div>
//         </div>
//     }
// }
