import React, { Component } from 'react';
import { withRouter, Link, Redirect, NavLink } from 'react-router-dom';
import './HomeLandingComponent.css';
import ARLogo from './../../../assets/img/AR_Logo.png';
class HomeLandingComponent extends Component {

  navigateLink = (pageUrl) =>{
    const { history } = this.props;
    history.push(pageUrl);
  }

  componentDidMount() {
    this._unmounted = true;
    document.addEventListener('click', this.handleOutisideClick);
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <section className="banner-sec">
          <div className="container">
            <div className="content">
              <h1>It’s time to add<br/> a little <em>kindness</em> <br/>into the casting process.</h1>
              <p>It’s time for a <a href="#">revolution.</a></p>
            </div>
          </div>
        </section>
        <section className="revolution-actor-sec">
          <div className="revolution-content">
            <h2>a revolution for <span>actors</span>.</h2>
            <p>Are you tired of mailing and emailing updated headshots 
              and résumés to casting directors every time you make a change? 
              Create an Audition Revolution actor profile and automatically be 
              included in the database of every Audition Revolution casting director!
            </p>
            <h4>Your free actor profile includes:</h4>
            <ul>
              <li>Upload 1 Headshot</li>
              <li>Profile including bio info, experience, training, and skills</li>
              <li>Inclusion in the AR talent database, searchable by all AR casting directors</li>
              <li>More features coming soon!</li>
            </ul>
            <div className="price-sec">
              <div className="left-sec">
                <p>Price: <span>Free!</span></p>
              </div>
              <div className="right-sec">
               <a href="/register" className="btn btn-primary">Actor Signup</a>
              </div>
            </div>
          </div>
          <div className="revolution-img"></div>
        </section>
        <section className="revolution-actor-sec revolution-casting-sec">
          <div className="revolution-img"></div>
          <div className="revolution-content">
            <h2>a revolution for <br/><span>casting directors</span>.</h2>
            <p>Are you still using a spreadsheet as a talent database? Does it take ages to find pdf actor résumés and headshots in your computer files, email folders, or flipping through that old filing cabinet? Tired of misplacing pen-and-paper talent notes? (“It’s here somewhere…”).</p>
            <h4>There’s a better way!</h4>
            <ul>
              <li>A dynamic, searchable, and customizable database of talent</li>
              <li>Your personal talent notes live in one place and are quickly searchable</li>
              <li>Custom tags make organizing and finding talent quick and easy</li>
              <li>More features to make your casting process easier coming soon!</li>
            </ul>
            <div className="price-sec">
              <div className="left-sec">
                <p>Price: <span>$20/mo. or $240/yr.</span></p>
              </div>
              <div className="right-sec">
               <a className="btn btn-primary" href="/register-company">Request Company Access</a>
              </div>
            </div>
          </div>
        </section>
        <section className="dynamic-talent-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="talent-block">
                  <h4>Dynamic Talent Database</h4>
                  <p>Your world moves fast – you need a database that can keep up. AR is that database.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="talent-block">
                  <h4>Simple and Efficient</h4>
                  <p>Secure and easy-to-use platform, simplifying the casting process for all involved.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="talent-block">
                  <h4>Connecting Talent and Casting</h4>
                  <p>With an up-to-date database, contacting talent is easier than ever before.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="join-revolution">
          <div className="container">
            <h2>Join the Revolution!</h2>
            <ul className="btn-list">
              <li><a href="/register" className="btn-primary btn ">I’m an Actor</a></li>
              <li><a className="btn-primary btn" href="/register-company">I’m a Casting Director</a></li>
            </ul>
          </div>
        </section>
        
      </>
    );
  }
}

export default withRouter(props => <HomeLandingComponent {...props} />);