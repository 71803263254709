import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import update from 'immutability-helper';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableCompoent from './TableCompoent';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import ExperienceModel from './ExperienceModel';
import TrainingComponent from './TrainingComponent';
import SkillsComponent from './SkillsComponent';

class ResumeComponent extends Component {

    _unmounted = false;

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            listOftables: [],
            isMainOrder: false,
            experienceOrder: props.experienceList,
            theatreExperience: [],
            isTheatreOrder: false,
            isMusicalTheatreOrder: false,
            isOperaOrder: false,
            isFilmOrder: false,
            isTelevisionOrder: false,
            isCommercialOrder: false,
            musicalTheatreExperience: [],
            filmExperience: [],
            televisionExperience: [],
            commercialExperience: [],
            operaExperience: [],
            isAddExperienceModal: false,
            editExperienceModal: false,
            experienceModalData: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getExperience();
    }

    getExperience = async () => {
        try {
            const response = await apiHelper('actor/getActorExperience', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        theatreExperience: data.theatreExperience,
                        musicalTheatreExperience: data.musicalTheatreExperience,
                        filmExperience: data.filmExperience,
                        televisionExperience: data.televisionExperience,
                        commercialExperience: data.commercialExperience,
                        operaExperience: data.operaExperience
                    });
                    this.updateListOrderArray();
                }
                // toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    updateListOrderArray = () => {
        const listOftables = this.props.experienceList.map(info => {
            let dataArr = [];
            let titleTable = '';
            switch (info) {
                case 'theatreExperience':
                    titleTable = 'Theatre';
                    dataArr = this.state.theatreExperience;
                    break;
                case 'musicalTheatreExperience':
                    titleTable = 'Musical Theatre';
                    dataArr = this.state.musicalTheatreExperience;
                    break;
                case 'operaExperience':
                    titleTable = 'Opera';
                    dataArr = this.state.operaExperience;
                    break;
                case 'filmExperience':
                    titleTable = 'Film';
                    dataArr = this.state.filmExperience;
                    break;
                case 'televisionExperience':
                    titleTable = 'Television';
                    dataArr = this.state.televisionExperience;
                    break;
                case 'commercialExperience':
                    titleTable = 'Commercial';
                    dataArr = this.state.commercialExperience;
                    break;
                default:
                    break;
            }
            return {
                type: info,
                tableTitle: titleTable,
                isOrdering: false,
                columns: [
                    { Header: 'Project', accessor: 'project', Cell:({row})=>{return <span style={{width:'160px', display:'inline-block'}}>{row.values.project}</span>} },
                    { Header: 'Role', accessor: 'role', Cell:({row})=>{return <span style={{width:'120px', display:'inline-block'}}>{row.values.role}</span>} },
                    { Header: 'Company', accessor: 'company', Cell:({row})=>{return <span style={{width:'160px', display:'inline-block'}}>{row.values.company}</span>} },
                    { Header: 'Director', accessor: 'director', Cell:({row})=>{return <span style={{width:'160px', display:'inline-block'}}>{row.values.director}</span>} },
                    { Header: '', id: 'id', Cell: ({ row }) => this.deleteExpBtns(row, info) }
                ],
                data: dataArr
            }
        })
        this.setState({
            listOftables: listOftables
        })
    }

    componentWillUnmount() {
        this._unmounted = false;
    }
    openAddExperienceModal = () => {
        this.setState({
            isAddExperienceModal: true
        })
    }

    closeAddExperienceModal = () => {
        this.setState({
            isAddExperienceModal: false
        })
    }

    experienceSubmit = async (data) => {
        try {

            const { project, role, company, director, experienceType } = data;

            const formData = new FormData();

            formData.append('experienceType', experienceType);
            formData.append('project', project);
            formData.append('role', role);
            formData.append('company', company);
            formData.append('director', director);

            const response = await apiHelper('actor/addExperience', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                if (!data.isMore) {
                    this.closeAddExperienceModal();
                }
                let listArray = this.state.listOftables;
                const findIndex = listArray.findIndex(info => info.type === experienceType);
                if (findIndex > -1) {
                    const updateArray = [decode, ...listArray[findIndex].data];
                    listArray[findIndex].data = updateArray
                    // console.log(listArray);
                    this.setState({
                        listOftables: listArray
                    });
                }
                // this.getExperience();

                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    experienceOrderFunc = (name) => {
        if (name !== 'mainOrder') {
            let listArray = this.state.listOftables;
            const findIndex = listArray.findIndex(info => info.type === name);
            if (findIndex > -1) {
                listArray[findIndex].isOrdering = true;
                // console.log(findIndex);
                this.setState({
                    listOftables: listArray
                });
            }
        } else {
            this.setState({ isMainOrder: true })
        }
    }

    moveTypeRow = (type, updateData) => {
        let listArray = this.state.listOftables;
        const findIndex = listArray.findIndex(info => info.type === type);
        if (findIndex > -1) {
            listArray[findIndex].data = updateData;
            this.setState({
                listOftables: listArray
            });
        }
    }

    saveExperienceOrder = (name) => {
        const { listOftables } = this.state;
        if (name !== 'mainOrder') {
            // let listArray = listOftables;
            // const findIndex = listArray.findIndex(info => info.type === name);
            // if (findIndex > -1) {
            //     listArray[findIndex].isOrdering = false;
            // console.log(findIndex);
            this.saveOrder(name, listOftables);
            // }
        } else {
            this.setState({ isMainOrder: false })
            const OrderTables = listOftables.map(info => info.type);
            //console.log();
            this.saveTableOrder(OrderTables.join(','));
        }
    }
    saveTableOrder = async (tableOrder) => {
        try {
            const formData = new FormData();
            formData.append('tableOrder', tableOrder);

            const response = await apiHelper('actor/saveTableOrder', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    saveOrder = async (type, mainArray) => {
        // console.log(mainArray.type.data);
        try {


            let listArray = mainArray;
            const findIndex = listArray.findIndex(info => info.type === type);
            // console.log(mainArray);
            let TableData = [];
            if (findIndex > -1) {
                listArray[findIndex].isOrdering = false;
                TableData = listArray[findIndex].data;
            }

            this.setState({
                listOftables: listArray
            })

            const formData = new FormData();
            formData.append('type', type);
            formData.append('data', JSON.stringify(TableData));

            const response = await apiHelper('actor/reOrderExperience', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
        // if (res.status) {
        //     toasterSuccess(res.message);
        // } else {

        //     toasterError(res.message);
        // }
    }

    deleteExperienceRow = async (row, type) => {
        try {
            // console.log(row);
            const formData = new FormData();
            formData.append('id', row?.original?.id);
            formData.append('experienceType', type);

            const response = await apiHelper('actor/deleteActorExperience', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                let listArray = this.state.listOftables;
                const findIndex = listArray.findIndex(info => info.type === type);
                if (findIndex > -1) {
                    let newData = listArray[findIndex].data;
                    const updateData = newData.filter(info => info.id !== row?.original?.id);
                    listArray[findIndex].data = updateData;
                    this.setState({
                        listOftables: listArray
                    });
                }
                toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    deleteExpBtns = (row, type) => {
        return <div className="d-flex">
            <button type="button" className="btn-dele" onClick={() => this.openEditExperienceModal(row, type)}><i className="fa fa-pencil"></i></button>
            <button type="button" className="btn-dele" onClick={() => this.deleteExperienceRow(row, type)}><i className="fa fa-trash"></i></button>
        </div>
    }

    openEditExperienceModal = (row, type) => {
        this.setState({
            editExperienceModal: true,
            experienceModalData: { row, type }
        })
    }

    closeEditExperienceModal = () => {
        this.setState({
            editExperienceModal: false,
            experienceModalData: null
        })
    }

    updateSubmit = async (data) => {
        try {
            // console.log(data);
            const { id, project, role, company, director, experienceType } = data;

            const formData = new FormData();

            formData.append('id', id);
            formData.append('experienceType', experienceType);
            formData.append('project', project);
            formData.append('role', role);
            formData.append('company', company);
            formData.append('director', director);

            const response = await apiHelper('actor/actorExperience/update', 'PUT', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                this.closeEditExperienceModal();
                let listArray = this.state.listOftables;
                const findIndex = listArray.findIndex(info => info.type === experienceType);
                // console.log(findIndex);
                if (findIndex > -1) {
                    const find = listArray[findIndex].data.map((info, i)=>{
                        if(id === info.id){
                            info = decode
                        }
                        return info
                    });
                    // console.log(find);
                    // const updateArray = [..., decode];
                    listArray[findIndex].data = find
                    // // console.log(listArray);
                    this.setState({
                        listOftables: listArray
                    });
                }
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    updateRecord = (updateData) => {
        this.setState({
            listOftables: updateData
        })
        // console.log(updateData);
    }

    renderCard = (info, i) => {
        return (
            <LICompo
                key={i}
                index={i}
                id={info.id}
                isMainOrder={this.state.isMainOrder}
                dataArray={info}
                moveTypeRow={this.moveTypeRow}
                // moveDiv={moveCard2}
                saveExperienceOrder={this.saveExperienceOrder}
                experienceOrderFunc={this.experienceOrderFunc}

            />
        );
    };

    reorderTable = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = (result) => {
        const res = this.reorderTable(this.state.listOftables, result.source.index, result.destination.index);
        // console.log(res);
        this.setState({
            listOftables: res
        })
    }

    render() {
        // console.log(this.props.experienceList);
        const { listOftables, isMainOrder } = this.state;

        return (
            <div className="resu-wr">
                <div className="resum-blo">
                    <div>
                        <div className="col-sm-8">
                            <div className="titl-wrp">
                                <h4 className="res-tit">Experience</h4>
                                <div className="bt-grops">
                                    {isMainOrder ?
                                        <button type="button" className="btns btn-transprent" aria-label="Reorder Experience" onClick={() => this.saveExperienceOrder('mainOrder')}>Save changes</button>
                                        :
                                        <button type="button" className="btns btn-transprent" aria-label="Reorder Experience" onClick={() => this.experienceOrderFunc('mainOrder')}>Reorder Experience</button>
                                    }
                                    <button type="button" className="btns btn-black" onClick={this.openAddExperienceModal} aria-label="Add Experience">Add Experience</button>
                                </div>
                            </div>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId={"droppable"} >
                                    {(provided, snapshot) => (
                                        <ul className="layout_tables" {...provided.droppableProps} ref={provided.innerRef}>
                                            {listOftables.length > 0 ?
                                                listOftables.map((list, i) =>
                                                    list.data.length > 0 ?
                                                        <li key={i}>
                                                            {/* {console.log(list)} */}
                                                            <LICompo
                                                                key={list.type}
                                                                index={i}
                                                                id={list.type}
                                                                isMainOrder={this.state.isMainOrder}
                                                                dataArray={list}
                                                                moveTypeRow={this.moveTypeRow}
                                                                // moveDiv={moveCard2}
                                                                saveExperienceOrder={this.saveExperienceOrder}
                                                                experienceOrderFunc={this.experienceOrderFunc}

                                                            />
                                                        </li>
                                                        :
                                                        null
                                                )
                                                :
                                                <li>No record found.</li>
                                            }
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {/* <DndProvider backend={HTML5Backend}>
                                <TableBlock
                                    experienceOrder={listOftables}
                                    updateRecord={this.updateRecord}
                                    isMainOrder={isMainOrder}
                                    saveExperienceOrder={this.saveExperienceOrder}
                                    experienceOrderFunc={this.experienceOrderFunc}
                                    moveTypeRow={this.moveTypeRow}
                                />

                            </DndProvider> */}
                        </div>
                        <div className="col-sm-4">
                            <TrainingComponent />
                            <SkillsComponent />
                        </div>
                    </div>
                </div>
                <ExperienceModel show={this.state.isAddExperienceModal} isEdit={false} onHide={this.closeAddExperienceModal} submitFun={this.experienceSubmit} />
                {this.state.editExperienceModal ? <ExperienceModel isEdit={true} show={this.state.editExperienceModal} data={this.state.experienceModalData} onHide={this.closeEditExperienceModal} submitFun={this.updateSubmit} /> : null}
            </div >
        );
    }
}

// const TableBlock = (props) => {

//     const [datas, setDatas] = React.useState([]);
//     React.useEffect(() => {
//         // console.log('working');
//         if (datas.length === 0) setDatas(props.experienceOrder)
//     })

//     // console.log(records);
//     const moveCard2 = React.useCallback((dragIndex2, hoverIndex2) => {

//         const dragCard2 = datas[dragIndex2];
//         const upd2 = update(datas, {
//             $splice: [
//                 [dragIndex2, 1],
//                 [hoverIndex2, 0, dragCard2],
//             ],
//         });
//         setDatas(upd2);
//         props.updateRecord(upd2);
//     }, [datas])

//     const renderCard = (info, i) => {
//         return (
//             <LICompo
//                 key={i}
//                 index={i}
//                 id={info.id}
//                 isMainOrder={props.isMainOrder}
//                 dataArray={info}
//                 moveTypeRow={props.moveTypeRow}
//                 moveDiv={moveCard2}
//                 saveExperienceOrder={props.saveExperienceOrder}
//                 experienceOrderFunc={props.experienceOrderFunc}

//             />
//         );
//     };

//     return (<>
//         <ul className="layout_tables">
//             {datas.length > 0 ?
//                 datas.map((info, i) => info.data.length > 0 ? renderCard(info, i) : null)
//                 :
//                 <li>No record found.</li>
//             }
//         </ul>
//         {/* <ul>{records.length > 0 ?
//             records.map((record, i) => renderCard(record, i))
//             :
//             <li>Add your info here!</li>}
//         </ul> */}
//     </>);

// }

// const DNDITEM_TYPE2 = 'card';

const LICompo = ({ id, index, saveExperienceOrder, experienceOrderFunc, dataArray, isMainOrder, moveDiv, moveTypeRow }) => {

    return (
        <>
            {isMainOrder ?
                <Draggable draggableId={id} index={index} >
                    {(provided, snapshot) => (
                        // <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={isMainOrder ? "table-viwe ordering" : "table-viwe"}>
                            <div className="table-header">
                                <h5 className="table-title">{isMainOrder ? <i className="fa fa-arrows"></i> : null}{dataArray.tableTitle}</h5>
                                {dataArray.isOrdering ?
                                    <button type="button" className="btns btn-transprent" onClick={() => saveExperienceOrder(dataArray.type)}>Save changes</button>
                                    :
                                    <button type="button" className="btns btn-transprent" onClick={() => experienceOrderFunc(dataArray.type)}>Reorder</button>
                                }
                            </div>
                            <TableCompoent columns={dataArray.columns} data={dataArray.data} moveRow={moveTypeRow} isRedorder={dataArray.isOrdering} tableType={dataArray.type} />
                        </div>
                        // </li>
                    )}
                </Draggable>
                :
                <div className={isMainOrder ? "table-viwe ordering" : "table-viwe"}>
                    <div className="table-header">
                        <h5 className="table-title">{isMainOrder ? <i className="fa fa-arrows"></i> : null}{dataArray.tableTitle}</h5>
                        {dataArray.isOrdering ?
                            <button type="button" className="btns btn-transprent" onClick={() => saveExperienceOrder(dataArray.type)}>Save changes</button>
                            :
                            <button type="button" className="btns btn-transprent" onClick={() => experienceOrderFunc(dataArray.type)}>Reorder</button>
                        }
                    </div>
                    <TableCompoent columns={dataArray.columns} data={dataArray.data} moveRow={moveTypeRow} isRedorder={dataArray.isOrdering} tableType={dataArray.type} />
                </div>
            }
            {/*  //     :
            //     <li>
                //         <div className={"table-viwe"}>
                    //             <div className="table-header">
                        //                 <h5 className="table-title">{isMainOrder ? <i className="fa fa-arrows"></i> : null}{dataArray.tableTitle}</h5>
            //                 {dataArray.isOrdering ?
            //                     <button type="button" className="btns btn-transprent" onClick={() => saveExperienceOrder(dataArray.type)}>Save Item</button>
            //                     :
            //                     <button type="button" className="btns btn-transprent" onClick={() => experienceOrderFunc(dataArray.type)}>Reorder</button>
            //                 }
            //             </div>
            //             <TableCompoent columns={dataArray.columns} data={dataArray.data} moveRow={moveTypeRow} isRedorder={dataArray.isOrdering} tableType={dataArray.type} />
            //         </div>
            //     </li>
            // } */}
        </>);
}

export default withRouter(props => <ResumeComponent {...props} />);