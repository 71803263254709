import React, { Component } from 'react';
import { withRouter } from 'react-router';
// import Pagination from 'rc-pagination';
import Pagination from 'react-bootstrap/Pagination'
import { apiHelper } from '../../../helper/api.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import Accordion from 'react-bootstrap/Accordion';
//import AddNotesComponent from './AddNotesComponent';
import AddNotesComponent from './../CompanyActorProfileComponent/AddActorNote';
import AddTagComponent from './../CompanyActorProfileComponent/AddActorTag';
import * as ACTION_TYPES from './../../../redux/actions/type';
import { connect } from 'react-redux';
import { toasterError } from '../../../helper/toaster.helper';
// import 'rc-select/assets/index.css';

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

class ActorSearchComponent extends Component {
    _unmounted = false;
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        const { totalRecords = null, pageLimit = 10, pageNeighbours = 0 } = props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 10;
        this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

        this.pageNeighbours = typeof pageNeighbours === 'number'
            ? Math.max(0, Math.min(pageNeighbours, 2))
            : 0;

        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

        this.state = {
            isSearchLoader: false,
            advancedSearchOpen: false,
            unionStatus: [],
            gender: [],
            age: [],
            ethnicity: [],
            voiceType: [],
            currentPage: 1,
            perPage: 10,
            actorsArr: [],
            totalActors: 0,
            // filterSearchVal: 0,
            // searchType: '0',
            tag: '',
            keywordType: '',
            keyword: '',
            keywordType2: '',
            keyword2: '',
            searchText: null,
            isNoteModal: false,
            isTagModal: false,
            user_id: null,
            tagsArray: []
        }
    }
    componentDidMount() {
        const { isViewProfile, searchValue, totalACountUpdateFun, viewProfileFun, updateSearchFun, searchValueFun, pageUpdateFun, unionStatusUpdateFun, genderUpdateFun, ageUpdateFun, ethnicityUpdateFun, voiceTypeUpdateFun, tagUpdateFun, keywordTypeUpdateFun, keywordUpdateFun, keywordType2UpdateFun, keyword2UpdateFun } = this.props;
        if (isViewProfile) {
            this.getDataActor(this.props.currentPage, searchValue, this.props.updatedUnionStatus, this.props.updatedGender, this.props.updatedAge, this.props.updatedEthnicity, this.props.updatedVoiceType, this.props.tag, this.props.keywordType, this.props.keyword, this.props.keywordType2, this.props.keyword2);
            this.setState({
                searchText: searchValue,
                currentPage: this.props.currentPage,
                unionStatus: this.props.updatedUnionStatus,
                gender: this.props.updatedGender,
                age: this.props.updatedAge,
                ethnicity: this.props.updatedEthnicity,
                voiceType: this.props.updatedVoiceType,
                tag: this.props.tag,
                keywordType: this.props.keywordType,
                keyword: this.props.keyword,
                keywordType2: this.props.keywordType2,
                keyword2: this.props.keyword2,
            });
        } else {
            viewProfileFun(false);
            updateSearchFun([]);
            searchValueFun('');
            tagUpdateFun('');
            keywordTypeUpdateFun('');
            keywordUpdateFun('');
            keywordType2UpdateFun('');
            keyword2UpdateFun('');
            pageUpdateFun(0);
            totalACountUpdateFun(0);
            unionStatusUpdateFun([]);
            genderUpdateFun([]);
            ageUpdateFun([]);
            ethnicityUpdateFun([]);
            voiceTypeUpdateFun([]);
        }
        this.getTags();
    }
    getTags = async () => {
        try {
            const response = await apiHelper('company/getCompanyTags', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                this.setState({
                    tagsArray: data
                })
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    componentDidUpdate(prevProps) {
        const { reloadSearchAPIs } = this.props;
        if (prevProps.reloadSearchAPIs !== reloadSearchAPIs) {
            this.getDataActor(this.state.currentPage, this.state.searchText);
        }
    }

    fetchPageNumbers = () => {
        const totalPages = Math.ceil(this.totalRecords / this.pageLimit);
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (this.pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);

    }

    accrodionHandler = () => {
        this.setState(state => ({
            advancedSearchOpen: !state.advancedSearchOpen
        }))
    }

    getDataActor = async (page, searchText, unionStatus, gender, age, ethnicity, voiceType, tag, keywordType, keyword, keywordType2, keyword2) => {

        let flag = false
        const { perPage } = this.state;

        const startIndex = page * perPage - perPage;
        const endIndex = startIndex + perPage;

        if(!tag && !keywordType && !keyword && !keywordType2 && !keyword2 && !unionStatus && !gender && !age && !ethnicity && !voiceType){
            flag = true
        }

        const formData = new FormData();
        formData.append('searchText', searchText);
        formData.append('tag', tag);
        formData.append('keywordType', keywordType);
        formData.append('keyword', keyword);
        formData.append('keywordType2', keywordType2);
        formData.append('keyword2', keyword2);
        formData.append('unionStatus', JSON.stringify(unionStatus));
        formData.append('gender', JSON.stringify(gender));
        formData.append('age', JSON.stringify(age));
        formData.append('ethnicity', JSON.stringify(ethnicity));
        formData.append('voiceType', JSON.stringify(voiceType));

        if(!flag){
        const response = await apiHelper('company/searchActor', "POST", formData, this.headers);
        const res = response.data;
        if (res.status) {
            const data = jwtDecode(res.data);
            this.totalRecords = data.actorData.length;
            this.setState({
                actorsArr: data.actorData.slice(startIndex, endIndex),
                totalActors: data.actorData.length,
                isSearchLoader: false
            });
            this.props.updateSearchFun(data.actorData.slice(startIndex, endIndex));
            this.props.reloadTagsFun(false);
        } else {
            toasterError(res.message);
            this.setState({
                actorsArr: [],
                totalActors: 0,
                isSearchLoader: false
            });
            this.props.updateSearchFun([]);
        }
        }       
    }

    prevPage = () => {
        const selectedPage = this.state.currentPage - 1
        this.setState({
            currentPage: selectedPage
        });
        this.getDataActor(selectedPage, this.state.searchText, this.state.unionStatus, this.state.gender, this.state.age, this.state.ethnicity, this.state.voiceType, this.state.tag, this.state.keywordType, this.state.keyword, this.state.keywordType2, this.state.keyword2);
    }

    nextPage = () => {
        const selectedPage = this.state.currentPage + 1;
        this.setState({
            currentPage: selectedPage
        });
        this.getDataActor(selectedPage, this.state.searchText, this.state.unionStatus, this.state.gender, this.state.age, this.state.ethnicity, this.state.voiceType, this.state.tag, this.state.keywordType, this.state.keyword, this.state.keywordType2, this.state.keyword2);
    }
    redirectProfile = (id) => {
        const { viewProfileFun, updateSearchFun, searchValueFun, pageUpdateFun, totalACountUpdateFun, unionStatusUpdateFun, genderUpdateFun, ageUpdateFun, ethnicityUpdateFun, voiceTypeUpdateFun } = this.props;
        viewProfileFun(true);
        updateSearchFun(this.state.actorsArr);
        searchValueFun(this.state.searchText);
        // searchTypeFun(this.state.searchType);

        this.props.tagUpdateFun(this.state.tag);
        this.props.keywordTypeUpdateFun(this.state.keywordType);
        this.props.keywordUpdateFun(this.state.keyword);
        this.props.keywordType2UpdateFun(this.state.keywordType2);
        this.props.keyword2UpdateFun(this.state.keyword2);

        pageUpdateFun(this.state.currentPage);
        totalACountUpdateFun(this.state.totalActors)
        unionStatusUpdateFun(this.state.unionStatus)
        genderUpdateFun(this.state.gender)
        ageUpdateFun(this.state.age)
        ethnicityUpdateFun(this.state.ethnicity)
        voiceTypeUpdateFun(this.state.voiceType)

        this.props.history.push(`/profile/${id}`)
    }
    paginationPage = (page) => {
        this.setState({
            currentPage: page
        });
        this.getDataActor(page, this.state.searchText, this.state.unionStatus, this.state.gender, this.state.age, this.state.ethnicity, this.state.voiceType, this.state.tag, this.state.keywordType, this.state.keyword, this.state.keywordType2, this.state.keyword2);
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "unionStatus") {
            let unionArray = this.state.unionStatus;
            if (unionArray.includes(value)) {
                this.setState(prevState => ({
                    unionStatus: prevState.unionStatus.filter(info => info.toLowerCase() !== value.toLowerCase())
                }));
            } else {
                this.setState(prevState => ({
                    unionStatus: [...prevState.unionStatus, value]
                }));
            }
        } else if (name === "gender") {
            let genderArray = this.state.gender;
            if (genderArray.includes(value)) {
                this.setState(prevState => ({
                    gender: prevState.gender.filter(info => info.toLowerCase() !== value.toLowerCase())
                }));
            } else {
                this.setState(prevState => ({
                    gender: [...prevState.gender, value]
                }));
            }
        } else if (name === "age") {
            let ageArray = this.state.age;
            if (ageArray.includes(value)) {
                this.setState(prevState => ({
                    age: prevState.age.filter(info => info.toLowerCase() !== value.toLowerCase())
                }));
            } else {
                this.setState(prevState => ({
                    age: [...prevState.age, value]
                }));
            }
        } else if (name === "ethnicity") {
            let ethnicityArray = this.state.ethnicity;
            if (ethnicityArray.includes(value)) {
                this.setState(prevState => ({
                    ethnicity: prevState.ethnicity.filter(info => info.toLowerCase() !== value.toLowerCase())
                }));
            } else {
                this.setState(prevState => ({
                    ethnicity: [...prevState.ethnicity, value]
                }));
            }
        } else if (name === "voiceType") {
            let voiceTypeArray = this.state.voiceType;
            if (voiceTypeArray.includes(value)) {
                this.setState(prevState => ({
                    voiceType: prevState.voiceType.filter(info => info.toLowerCase() !== value.toLowerCase())
                }));
            } else {
                this.setState(prevState => ({
                    voiceType: [...prevState.voiceType, value]
                }));
            }
        } else {
            this.setState({
                [name]: value
            })
        }

    }

    submitSearch = (e) => {
        e.preventDefault();
        document.getElementsByClassName('test-class')[0].classList.remove('show')
        this.setState({
            isSearchLoader: true,
            advancedSearchOpen:false
        })
        const { viewProfileFun, updateSearchFun, searchValueFun, pageUpdateFun, totalACountUpdateFun, unionStatusUpdateFun, genderUpdateFun, ageUpdateFun, ethnicityUpdateFun, voiceTypeUpdateFun } = this.props;
        viewProfileFun(true);
        updateSearchFun(this.state.actorsArr);
        searchValueFun(this.state.searchText);
        // searchTypeFun(this.state.searchType);

        this.props.tagUpdateFun(this.state.tag);
        this.props.keywordTypeUpdateFun(this.state.keywordType);
        this.props.keywordUpdateFun(this.state.keyword);
        this.props.keywordType2UpdateFun(this.state.keywordType2);
        this.props.keyword2UpdateFun(this.state.keyword2);

        pageUpdateFun(this.state.currentPage);
        totalACountUpdateFun(this.state.totalActors)
        unionStatusUpdateFun(this.state.unionStatus)
        genderUpdateFun(this.state.gender)
        ageUpdateFun(this.state.age)
        ethnicityUpdateFun(this.state.ethnicity)
        voiceTypeUpdateFun(this.state.voiceType)

        this.getDataActor(1, this.state.searchText, this.state.unionStatus, this.state.gender, this.state.age, this.state.ethnicity, this.state.voiceType, this.state.tag, this.state.keywordType, this.state.keyword, this.state.keywordType2, this.state.keyword2);
    }

    cancelSearch = () => {
        this.setState({
            unionStatus: [],
            gender: [],
            age: [],
            ethnicity: [],
            voiceType: [],
            tag: '',
            keywordType: '',
            keyword: '',
            keywordType2: '',
            keyword2: '',
            searchText: '',
        })
    }

    openNoteModal = (id) => {
        this.setState({
            isNoteModal: true,
            user_id: id
        })
    }
    closeNoteModal = () => {
        this.setState({
            isNoteModal: false
        })
    }
    openTagModal = (id) => {
        this.setState({
            isTagModal: true,
            user_id: id
        })
    }
    closeTagModal = () => {
        this.setState({
            isTagModal: false
        })
    }

    renderPagination = (page, index) => {

        if (page === LEFT_PAGE) {
            return <Pagination.Prev key={index} onClick={this.prevPage}>Prev</Pagination.Prev>
        }

        if (page === RIGHT_PAGE) {
            return <Pagination.Next key={index} onClick={this.nextPage}>Next</Pagination.Next>
        }


        return <Pagination.Item key={index} active={page === this.state.currentPage} onClick={() => this.paginationPage(page)}>
            {page}
        </Pagination.Item>
    }

    render() {

        const { isSearchLoader, unionStatus, gender, age, ethnicity, voiceType, tagsArray, tag, keywordType, keyword, keywordType2, keyword2 } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <>
                <div className="search-wrap">
                    <h2 className="search_title">Actor Search</h2>
                    <div className="card-wrap">
                        <form action="/" method="post" onSubmit={this.submitSearch}>
                            <div className="search-block">

                                <div className="input_group">
                                    <input type="text" name="searchText" value={this.state.searchText} onChange={this.inputHandler} placeholder="Search for Talent by Name" className="search-input" />
                                    <span className="search_icon"><i className="fa fa-search"></i></span>
                                </div>

                                {/* <select name="searchType" onChange={this.inputHandler} className="form-control block-select">
                                <option value="0" selected={searchType.includes("0")}>Search by Name</option>
                                <option value="1" selected={searchType.includes("1")}>Search by Keyword</option>
                                <option value="2" selected={searchType.includes("2")}>Search by Tag</option>
                            </select> */}
                                <hr className="horz-hr" />
                                <button type="submit" className="btn_search">Search</button>
                            </div>
                        </form>
                        <Accordion defaultActiveKey={this.state.advancedSearchOpen?'0':''}>
                            <div className="custom-accordion-with-button">
                                <Accordion.Toggle as={'button'} className={`btn-acro ${this.state.advancedSearchOpen ? 'active' : ''}`} variant="link" eventKey="0" onClick={this.accrodionHandler}>
                                    <span>
                                        Advanced Search
                                        <div className="accordion-div">
                                            <i className="fa fa-angle-down accrodion-icon"></i>
                                        </div>
                                    </span>
                                </Accordion.Toggle>
                                <button type="button" className="btn btn-dark custom-position-btn" style={{ background: 'transparent', boxShadow: 'none', marginRight: '10px' }} onClick={this.cancelSearch}>Clear Selection</button>
                            </div>
                            <Accordion.Collapse eventKey="0" className="test-class">
                                <div className="tabs-bl">
                                    <form action="/" method="post">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Union Status<strong>*</strong></label>
                                                    <div className="input_groups">
                                                        <label htmlFor="non-union"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Non-Union")} defaultValue="Non-Union" id="non-union" onChange={this.inputHandler} /> Non-Union</label>
                                                        <label htmlFor="aea"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA")} defaultValue="AEA" id="AEA" onChange={this.inputHandler} /> AEA</label>
                                                        <label htmlFor="aea-emc"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("AEA-EMC")} id="aea-emc" defaultValue="AEA-EMC" onChange={this.inputHandler} /> AEA-EMC</label>
                                                        <label htmlFor="unionOther"><input type="checkbox" name="unionStatus" checked={unionStatus.includes("Other")} id="unionOther" defaultValue="Other" onChange={this.inputHandler} /> Other</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Gender<strong>*</strong></label>
                                                    <div className="input_groups">
                                                        <label htmlFor="female"><input type="checkbox" name="gender" checked={gender.includes("Female")} defaultValue="Female" id="female" onChange={this.inputHandler} /> Female</label>
                                                        <label htmlFor="male"><input type="checkbox" name="gender" checked={gender.includes("Male")} defaultValue="Male" id="male" onChange={this.inputHandler} /> Male</label>
                                                        <label htmlFor="non-binary"><input type="checkbox" name="gender" checked={gender.includes("Non-Binary")} defaultValue="Non-Binary" id="non-binary" onChange={this.inputHandler} /> Non-Binary</label>
                                                        <label htmlFor="genderOther"><input type="checkbox" name="gender" checked={gender.includes("Other")} defaultValue="Other" id="genderOther" onChange={this.inputHandler} /> Other</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Age<strong>*</strong></label>
                                                    <div className="input_groups">
                                                        <label htmlFor="child"><input type="checkbox" name="age" checked={age.includes("Child")} defaultValue="Child" onChange={this.inputHandler} id="child" /> Child</label>
                                                        <label htmlFor="teen"><input type="checkbox" name="age" checked={age.includes("Teen")} defaultValue="Teen" onChange={this.inputHandler} id="teen" /> Teen</label>
                                                        <label htmlFor="teens20"><input type="checkbox" name="age" checked={age.includes("Teens-20")} defaultValue="Teens-20" onChange={this.inputHandler} id="teens20" /> Teens-20</label>
                                                        <label htmlFor="20s30s"><input type="checkbox" name="age" checked={age.includes("20s-30s")} defaultValue="20s-30s" onChange={this.inputHandler} id="20s30s" /> 20s-30s</label>
                                                        <label htmlFor="30s40s"><input type="checkbox" name="age" checked={age.includes("30s-40s")} defaultValue="30s-40s" onChange={this.inputHandler} id="30s40s" /> 30s-40s</label>
                                                        <label htmlFor="40s50s"><input type="checkbox" name="age" checked={age.includes("40s-50s")} defaultValue="40s-50s" onChange={this.inputHandler} id="40s50s" /> 40s-50s</label>
                                                        <label htmlFor="50s60s"><input type="checkbox" name="age" checked={age.includes("50s-60s")} defaultValue="50s-60s" onChange={this.inputHandler} id="50s60s" /> 50s-60s</label>
                                                        <label htmlFor="60s70s"><input type="checkbox" name="age" checked={age.includes("60s-70s")} defaultValue="60s-70s" onChange={this.inputHandler} id="60s70s" /> 60s-70s</label>
                                                        <label htmlFor="over70"><input type="checkbox" name="age" checked={age.includes("Over 70")} defaultValue="Over 70" onChange={this.inputHandler} id="over70" /> Over 70</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Ethnicity<strong>*</strong></label>
                                                    <div className="input_groups">
                                                        <label htmlFor="africanamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("African American")} defaultValue="African American" onChange={this.inputHandler} id="africanamerican" /> African American</label>
                                                        <label htmlFor="asian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Asian")} defaultValue="Asian" onChange={this.inputHandler} id="asian" /> Asian</label>
                                                        <label htmlFor="caucasian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Caucasian")} defaultValue="Caucasian" onChange={this.inputHandler} id="caucasian" /> Caucasian</label>
                                                        <label htmlFor="hispanic"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hispanic")} defaultValue="Hispanic" onChange={this.inputHandler} id="hispanic" /> Hispanic</label>
                                                        <label htmlFor="latino"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Latino")} defaultValue="Latino" onChange={this.inputHandler} id="latino" /> Latino</label>
                                                        <label htmlFor="nativeamerican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Native American")} defaultValue="Native American" onChange={this.inputHandler} id="nativeamerican" /> Native American</label>
                                                        <label htmlFor="alaskannative"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Alaskan Native")} defaultValue="Alaskan Native" onChange={this.inputHandler} id="alaskannative" /> Alaskan Native</label>
                                                        <label htmlFor="hawaiian"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Hawaiian")} defaultValue="Hawaiian" onChange={this.inputHandler} id="hawaiian" /> Hawaiian</label>
                                                        <label htmlFor="middleeastern"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Middle Eastern")} defaultValue="Middle Eastern" onChange={this.inputHandler} id="middleeastern" /> Middle Eastern</label>
                                                        <label htmlFor="northafrican"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("North African")} defaultValue="North African" onChange={this.inputHandler} id="northafrican" /> North African</label>
                                                        <label htmlFor="multicultural"><input type="checkbox" name="ethnicity" checked={ethnicity.includes("Multi-Cultural")} defaultValue="Multi-Cultural" onChange={this.inputHandler} id="multicultural" /> Multi-Cultural</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Voice Type<strong>*</strong></label>
                                                    <div className="input_groups">
                                                        <label htmlFor="soprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Soprano")} defaultValue="Soprano" onChange={this.inputHandler} id="soprano" /> Soprano</label>
                                                        <label htmlFor="mezzosopranobelter"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano Belter")} defaultValue="Mezzo Soprano Belter" onChange={this.inputHandler} id="mezzosopranobelter" /> Mezzo Soprano Belter</label>
                                                        <label htmlFor="mezzosoprano"><input type="checkbox" name="voiceType" checked={voiceType.includes("Mezzo Soprano")} defaultValue="Mezzo Soprano" onChange={this.inputHandler} id="mezzosoprano" /> Mezzo Soprano</label>
                                                        <label htmlFor="alto"><input type="checkbox" name="voiceType" checked={voiceType.includes("Alto")} defaultValue="Alto" onChange={this.inputHandler} id="alto" /> Alto</label>
                                                        <label htmlFor="tenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Tenor")} defaultValue="Tenor" onChange={this.inputHandler} id="tenor" /> Tenor</label>
                                                        <label htmlFor="baritenor"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritenor")} defaultValue="Baritenor" onChange={this.inputHandler} id="baritenor" /> Baritenor</label>
                                                        <label htmlFor="baritone"><input type="checkbox" name="voiceType" checked={voiceType.includes("Baritone")} defaultValue="Baritone" onChange={this.inputHandler} id="baritone" /> Baritone</label>
                                                        <label htmlFor="bass"><input type="checkbox" name="voiceType" checked={voiceType.includes("Bass")} defaultValue="Bass" onChange={this.inputHandler} id="bass" /> Bass</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label className="label-title">Search by tags</label>
                                                    <div className="input_groups">
                                                        <select className="form-control" value={tag} name="tag" onChange={this.inputHandler}>
                                                            <option value="">Select Tag</option>
                                                            {tagsArray && tagsArray.map((info, index) =>
                                                                <option key={index} value={info.tagName}>{info.tagName}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className="label-title">Search by Keyword</label>
                                                    <div className="row">
                                                        <div className="col-sm-4 mb-4">
                                                            <div className="input_groups">
                                                                <select className="form-control" value={keywordType} name="keywordType" onChange={this.inputHandler}>
                                                                    <option value="">Search keyword in</option>
                                                                    <option value="project">Experience: Project</option>
                                                                    <option value="role">Experience: Role</option>
                                                                    <option value="company">Experience: Company</option>
                                                                    <option value="director">Experience: Director</option>
                                                                    <option value="training">Training</option>
                                                                    <option value="skills">Special Skills</option>
                                                                    <option value="notes">Notes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="input_groups">
                                                                <input type="text" name="keyword" value={keyword} className="form-control" onChange={this.inputHandler} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    {/* <label className="label-title">Search by Keyword</label> */}
                                                    <div className="row">
                                                        <div className="col-sm-4 mb-4">
                                                            <div className="input_groups">
                                                                <select className="form-control" value={keywordType2} name="keywordType2" onChange={this.inputHandler}>
                                                                    <option value="">Search keyword in</option>
                                                                    <option value="project">Experience: Project</option>
                                                                    <option value="role">Experience: Role</option>
                                                                    <option value="company">Experience: Company</option>
                                                                    <option value="director">Experience: Director</option>
                                                                    <option value="training">Training</option>
                                                                    <option value="skills">Special Skills</option>
                                                                    <option value="notes">Notes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="input_groups">
                                                                <input type="text" name="keyword2" value={keyword2} className="form-control" onChange={this.inputHandler} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button type="button" className="btn btn-primary" style={{ float: 'right' }} onClick={this.submitSearch}>Search</button>
                                                <button type="button" className="btn btn-dark" style={{ float: 'right', marginRight: '10px' }} onClick={this.cancelSearch}>Clear Selection</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Accordion.Collapse>
                        </Accordion>

                    </div>

                    <div className="actor-wrap">
                        <div className="title-wrap">
                            <p className="tite">Found Actors</p>
                            <p className="lab-badg">{this.state.totalActors} Actors</p>
                        </div>
                        {isSearchLoader ?
                            <div className="loader_wrap">
                                <div className="loader_box"></div>
                            </div>
                            :
                            <ul className="actor_list">
                                {this.state.actorsArr.length > 0 ?
                                    this.state.actorsArr.map((item, i) => item && <li key={i}>
                                        <div className="actor-list-details" onClick={() => this.redirectProfile(item.id)}>
                                            {console.log(item)}
                                            <div className="img-wrap">
                                                <img src={item.profilePic} alt="" />
                                            </div>
                                            <div className="contents">
                                                <h6 className="actor-title">{item.user_firstName && item.user_firstName} {item.user_lastName && item.user_lastName}</h6>
                                                <div className="visible-mob">
                                                    <p className="email-field">Email: <a href={`mailto:${item.user_email}`}>{item.user_email}</a></p>
                                                </div>
                                                <p className="address-actor">{item.user_city && item.user_city}, {item.user_state && item.user_state}</p>
                                                <p className="address-actor">{item.tags && item.tags.length > 0 ? 'Tags :' : null}
                                                    {item.tags && item.tags.length > 0 ?

                                                        item.tags.map((info, index) => <span key={index} className="actor-tag"> {info.tag}</span>)
                                                        :
                                                        null
                                                    }
                                                </p>
                                                <div className="visible-mob contact-info">
                                                    <div className="group-btn">
                                                        <button type="button" className="btn_default2 border-gray" onClick={() => this.openTagModal(item.user_id)}>Add Tag</button>
                                                        <button type="button" className="btn_default2" onClick={() => this.openNoteModal(item.user_id)}>Add Notes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contact-info hide-mob">
                                            <p>Email: <a href={`mailto:${item.user_email}`}>{item.user_email}</a></p>
                                            <div className="group-btn">
                                                <button type="button" className="btn_default2 border-gray" onClick={() => this.openTagModal(item.user_id)}>Add Tag</button>
                                                <button type="button" className="btn_default2" onClick={() => this.openNoteModal(item.user_id)}>Add Notes</button>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <li className="no-record">No record found.</li>
                                }
                            </ul>
                        }
                        {this.state.actorsArr.length > 0 ?
                            <div className="page-wrap">
                                {(!this.totalRecords || this.totalPages === 1) ? null : <Pagination>
                                    {/* {this.state.currentPage > 1 ? <Pagination.Prev onClick={this.prevPage} /> : null} */}
                                    {pages.map((page, index) => this.renderPagination(page, index))}
                                    {/* {this.state.currentPage < totalPages ? <Pagination.Next onClick={this.nextPage} /> : null} */}
                                </Pagination>}
                            </div>
                            :
                            null
                        }
                    </div>
                    {this.state.isNoteModal ? <AddNotesComponent show={this.state.isNoteModal} id={this.state.user_id} onHide={this.closeNoteModal} /> : null}
                    {this.state.isTagModal ? <AddTagComponent show={this.state.isTagModal} id={this.state.user_id} onHide={this.closeTagModal} /> : null}
                </div>

            </>
        );
    }
}

const mapStateToProps = state => ({
    reloadSearchAPIs: state.profileData.reloadTagAPIs,
    searchData: state.company.searchData,
    searchValue: state.company.searchValue,
    // searchType: state.company.searchType,
    currentPage: state.company.currentPage,
    isViewProfile: state.company.isViewProfile,
    totalActorCount: state.company.totalActorCount,
    updatedUnionStatus: state.company.unionStatus,
    updatedGender: state.company.gender,
    updatedAge: state.company.age,
    updatedEthnicity: state.company.ethnicity,
    updatedVoiceType: state.company.voiceType,
    tag: state.company.tag,
    keywordType: state.company.keywordType,
    keyword: state.company.keyword,
    keywordType2: state.company.keywordType2,
    keyword2: state.company.keyword2
});

const mapDispatchToProps = dispatch => ({
    reloadTagsFun: (bool) => dispatch({ type: ACTION_TYPES.RELOAD_TAG_API, payload: bool }),
    viewProfileFun: (bool) => dispatch({ type: ACTION_TYPES.IS_COMPANY_VIEW_ACTOR, payload: bool }),
    updateSearchFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_DATA, payload: data }),
    searchValueFun: (search) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VALUE, payload: search }),
    // searchTypeFun: (type) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_TYPE, payload: type }),
    pageUpdateFun: (page) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_CURRENTPAGE, payload: page }),
    totalACountUpdateFun: (count) => dispatch({ type: ACTION_TYPES.TOTAL_ACTOR_COUNT, payload: count }),
    unionStatusUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_UNIONSTATUS, payload: data }),
    genderUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_GENDER, payload: data }),
    ageUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_AGE, payload: data }),
    ethnicityUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_ETHNICITY, payload: data }),
    voiceTypeUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_VAL_VOICETYPE, payload: data }),
    tagUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_TAG, payload: data }),
    keywordTypeUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD_TYPE, payload: data }),
    keywordUpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD, payload: data }),
    keywordType2UpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD_TYPE2, payload: data }),
    keyword2UpdateFun: (data) => dispatch({ type: ACTION_TYPES.UPDATE_COMPANY_SEARCH_KEYWORD2, payload: data })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActorSearchComponent));