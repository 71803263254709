export const USER_IMAGE_UPDATE = 'USER_IMAGE_UPDATE';
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const RELOAD_NOTES_API = 'RELOAD_NOTES_API';
export const RELOAD_TAG_API = 'RELOAD_TAG_API';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_ACTOR_TAGS = 'UPDATE_ACTOR_TAGS';
export const UPDATE_COMPANY_SEARCH_DATA = 'UPDATE_COMPANY_SEARCH_DATA';
export const UPDATE_COMPANY_SEARCH_VALUE = 'UPDATE_COMPANY_SEARCH_VALUE';
// export const UPDATE_COMPANY_SEARCH_TYPE = 'UPDATE_COMPANY_SEARCH_TYPE';
export const UPDATE_COMPANY_SEARCH_CURRENTPAGE = 'UPDATE_COMPANY_SEARCH_CURRENTPAGE';
export const IS_COMPANY_VIEW_ACTOR = 'IS_COMPANY_VIEW_ACTOR';
export const TOTAL_ACTOR_COUNT = 'TOTAL_ACTOR_COUNT';
export const UPDATE_COMPANY_SEARCH_VAL_UNIONSTATUS = 'UPDATE_COMPANY_SEARCH_VAL_UNIONSTATUS';
export const UPDATE_COMPANY_SEARCH_VAL_GENDER = 'UPDATE_COMPANY_SEARCH_VAL_GENDER';
export const UPDATE_COMPANY_SEARCH_VAL_AGE = 'UPDATE_COMPANY_SEARCH_VAL_AGE';
export const UPDATE_COMPANY_SEARCH_VAL_ETHNICITY = 'UPDATE_COMPANY_SEARCH_VAL_ETHNICITY';
export const UPDATE_COMPANY_SEARCH_VAL_VOICETYPE = 'UPDATE_COMPANY_SEARCH_VAL_VOICETYPE';

export const UPDATE_COMPANY_SEARCH_TAG = 'UPDATE_COMPANY_SEARCH_TAG';
export const UPDATE_COMPANY_SEARCH_KEYWORD_TYPE = 'UPDATE_COMPANY_SEARCH_KEYWORD_TYPE';
export const UPDATE_COMPANY_SEARCH_KEYWORD = 'UPDATE_COMPANY_SEARCH_KEYWORD';
export const UPDATE_COMPANY_SEARCH_KEYWORD_TYPE2 = 'UPDATE_COMPANY_SEARCH_KEYWORD_TYPE2';
export const UPDATE_COMPANY_SEARCH_KEYWORD2 = 'UPDATE_COMPANY_SEARCH_KEYWORD2';

export const UPDATE_SERVICE_DIRECTORY_SEARCH_DATA = 'UPDATE_SERVICE_DIRECTORY_SEARCH_DATA';
export const UPDATE_SERVICE_DIRECTORY_SEARCH_VALUE = 'UPDATE_SERVICE_DIRECTORY_SEARCH_VALUE';
export const UPDATE_SERVICE_DIRECTORY_SEARCH_CURRENTPAGE = 'UPDATE_SERVICE_DIRECTORY_SEARCH_CURRENTPAGE';
export const TOTAL_SERVICE_DIRECTORY_COUNT = 'TOTAL_SERVICE_DIRECTORY_COUNT';