import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toasterError } from '../../../helper/toaster.helper';
// import { apiHelper } from '../../../helper/api.helper';

class AddTrainingComponent extends Component {
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            trainingText: '',
            error: {
                trainingText: ''
            },
            valid: {
                trainingText: false
            }
        }
    }

    componentDidMount() {
        const { isEdit, data } = this.props;
        if (isEdit) {
            this.setState({
                trainingText: data?.text,
                error: {
                    trainingText: ''
                },
                valid: {
                    trainingText: data?.text ? true : false
                }
            })
        } else {
            this.setState({
                trainingText: '',
                error: {
                    trainingText: ''
                },
                valid: {
                    trainingText: false
                }
            })
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;
        switch (name) {
            case 'trainingText':
                valid.trainingText = value.length > 0;
                error.trainingText = valid.trainingText ? "" : "Text is required!";
                break;
            default:
                break;
        }

        this.setState({
            error: error,
            valid: valid
        })
    }

    closeTrainingModal = () => {
        this.setState({
            isTrainingModal: false
        })
    }

    trainingSubmit = async (e) => {
        try {
            e.preventDefault();
            const { trainingText } = this.state;
            const { isEdit, data } = this.props;
            if (isEdit) {

                this.props.addActorSubmit({ id: data?.id, text: trainingText });
            } else {
                this.props.addActorSubmit({ trainingText });
            }

            // const formData = new FormData();
            // formData.append('trainingText', trainingText);

            // const response = await apiHelper('actor/addActorTraining', 'POST', formData, this.headers);
            // const res = response.data;
            // if (res.status) {
            //     toasterSuccess(res.message);
            // } else {
            //     toasterError(res.message);
            // }
        } catch (e) {
            toasterError(e.message);
        }
    }

    trainingMoreSubmit = () => {
        const { trainingText } = this.state;
        this.props.addActorSubmit({ isMore: true, trainingText });
        this.setState({
            trainingText: '',
            error: {
                trainingText: ''
            },
            valid: {
                trainingText: false
            }
        })
    }

    render() {
        // error
        const { trainingText, valid } = this.state;

        const disabled = !valid.trainingText;
        return (
            <>
                <Modal show={this.props.show} className="file_modal" onHide={this.props.onHide} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>{`${this.props.isEdit ? 'Update' : 'Add'} Training`}</h3>
                    </Modal.Header>
                    <form action="/" method="POST" onSubmit={this.trainingSubmit}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <input type="text" name="trainingText" className="form-control" value={trainingText} onChange={this.inputHandler} placeholder="Text*" />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-default" onClick={this.props.onHide}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={disabled}>{this.props.isEdit ? 'Update' : 'Save & Close'}</button>
                            {!this.props.isEdit ? <button type="button" className="btn btn-primary" disabled={disabled} onClick={this.trainingMoreSubmit}>{'Save & Add More'}</button> : null}
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}
export default withRouter(props => <AddTrainingComponent {...props} />);