import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './../../../assets/img/logo.png';
import { emailValidation, passwordValidation } from '../../../helper/validation.helper';
import { apiHelper } from '../../../helper/api.helper';
import { toasterSuccess, toasterError } from '../../../helper/toaster.helper';

class AdminLoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    loginSubmit = async (e) => {
        try {
            e.preventDefault();
            const { email, password } = this.state;
            const emailValid = emailValidation(email);

            const passwordValid = passwordValidation(password);
            if (emailValid.status && passwordValid.status) {
                let formData = new FormData();
                formData.append('username', email);
                formData.append('password', password);

                const response = await apiHelper('admin/login', 'POST', formData, null);
                const res = response.data;
                if (res.status) {
                    localStorage.setItem('token', res.data);
                    // toasterSuccess(res.message);
                    this.props.history.push('/admin/dashboard');
                } else {
                    toasterError(res.message);
                }

            } else {
                toasterError("All fields is required.");
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { email, password } = this.state;

        return (
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle">
                        <div className="auth-box ">
                            <div className="left">
                                <div className="content">
                                    <div className="header">
                                        <div className="logo small-logo text-center"><img src={logo} alt="Klorofil Logo" /></div>
                                        <p className="lead">Login to your account</p>
                                    </div>
                                    <form className="form-auth-small" action="/" onSubmit={this.loginSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="signin-email" className="control-label sr-only">Email</label>
                                            <input type="email" name="email" className="form-control" id="signin-email" onChange={this.inputHandler} value={email} placeholder="Email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="signin-password" className="control-label sr-only">Password</label>
                                            <input type="password" name="password" className="form-control" id="signin-password" onChange={this.inputHandler} value={password} placeholder="Password" />
                                        </div>
                                        <div className="form-group clearfix">
                                            {/* <label className="fancy-checkbox element-left">
                                                <input type="checkbox">
                                                    <span>Remember me</span>
									</label> */}
                                        </div>
                                        <button type="submit" className="btn btn-primary btn-lg btn-block">LOGIN</button>
                                        {/* <div className="bottom">
                                            <span className="helper-text"><i className="fa fa-lock"></i> <a href="#">Forgot password?</a></span>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                            <div className="right">
                                <div className="overlay"></div>
                                <div className="content text">
                                    <h1 className="heading">Audition Revolution</h1>
                                    <p>Admin Panel</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
           
        );
    }
}

export default withRouter(props => <AdminLoginComponent {...props} />);