import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "./ActorRegisterComponent.css";
import {
  emailValidation,
  passwordValidation,
  confirmPassValidation,
} from "../../../helper/validation.helper";
import { toasterError } from "../../../helper/toaster.helper";
import { apiHelper } from "../../../helper/api.helper";
import Autocomplete from "react-google-autocomplete";

class ActorRegisterComponent extends Component {
  _unmonted = false;

  constructor(props) {
    super(props);
    this.state = {
      fName: "",
      lName: "",
      city: "",
      state: "",
      cityState: "",
      email: "",
      password: "",
      confirmPass: "",
      acceptTerms: false,
      error: {
        fName: "",
        lName: "",
        cityState: "",
        email: "",
        password: "",
        confirmPass: "",
      },
      valid: {
        fName: false,
        lName: false,
        cityState: false,
        email: false,
        password: false,
        confirmPass: false,
      },
    };
  }
  componentDidMount() {
    this._unmonted = true;

    const token = localStorage.getItem("userToken");
    if (token) {
      this.props.history.push("/profile");
      return true;
    }

    if (this.props.location.search !== "") {
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      console.log(this.props.location);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params && params.email) {
        const emailValid = emailValidation(params.email);

        if (this._unmonted) {
          this.setState({
            email: params.email,
            error: {
              ...this.state.error,
              email: emailValid.status ? "" : emailValid.message,
            },
            valid: {
              ...this.state.valid,
              email: emailValid.status,
            },
          });
        }
      }
    }
    // if (this._unmonted) {
    //   const placeInstance = places({
    //     appId: "plL5W1957CCG",
    //     apiKey: "8f309db6542eab1fb7c18b83fcff357c",
    //     container: document.querySelector("#address-input"),
    //   });

    //   placeInstance.configure({
    //     language: "en",
    //     type: this.props.type,
    //     countries: ["us"],
    //   });

    //   placeInstance.on("change", (e) => {
    //     const { name: city, administrative: state } = e.suggestion;

    //     // if (this.props.type === "address") {
    //     //     this.handleChange(e.suggestion);
    //     // } else {
    //     //     this.handleChange(city, state);
    //     // }

    //     this.setState({
    //       cityState: `${city}, ${state}`,
    //       city: city,
    //       state: state,
    //       error: {
    //         ...this.state.error,
    //         cityState: "",
    //       },
    //       valid: {
    //         ...this.state.valid,
    //         cityState: true,
    //       },
    //     });
    //   });
    // }
  }
//   handleAddress = () => {
//     const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
//       useGoogle({
//         apiKey: "AIzaSyA_vc6ss-pHbOSkGfkeAxV-lmHn57msYpk",
//       });
//   };
  componentWillUnmount() {
    this._unmonted = false;
  }

  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.checkValidation(name, value)
    );
  };

  checkValidation = (name, value) => {
    let error = this.state.error;
    let valid = this.state.valid;

    switch (name) {
      case "fName":
        valid.fName = value.length > 0;
        error.fName = valid.fName ? "" : "First Name Required";
        break;
      case "lName":
        valid.lName = value.length > 0;
        error.lName = valid.lName ? "" : "Last Name Required";
        break;
      case "cityState":
        valid.cityState = value.length > 0;
        error.cityState = valid.cityState ? "" : "City,State Required";
        break;
      case "email":
        const emailValid = emailValidation(value);
        valid.email = emailValid.status;
        error.email = emailValid.status ? "" : emailValid.message;
        break;
      case "password":
        const passValid = passwordValidation(value);
        valid.password = passValid.status;
        error.password = valid.password ? "" : passValid.message;
        break;
      case "confirmPass":
        const confValid = confirmPassValidation(this.state.password, value);
        valid.confirmPass = confValid.status;
        error.confirmPass = valid.confirmPass ? "" : confValid.message;
        break;
      default:
        break;
    }

    this.setState({
      error,
      valid,
    });
  };

  termsHandler = (e) => {
    const checked = e.target.checked;
    this.setState({
      acceptTerms: checked,
    });
  };

  submitFun = async (e) => {
    try {
      e.preventDefault();
      const { valid, acceptTerms } = this.state;
      if (
        !valid.fName ||
        !valid.lName ||
        !valid.cityState ||
        !valid.email ||
        !valid.password ||
        !valid.confirmPass
      ) {
        toasterError("All Field required.");
        return;
      }

      if (!acceptTerms) {
        toasterError("please select terms and coditions");
        return;
      }

      const formData = new FormData();
      formData.append("firstName", this.state.fName);
      formData.append("lastName", this.state.lName);
      formData.append("city", this.state.city);
      formData.append("state", this.state.state);
      formData.append("email", this.state.email);
      formData.append("password", this.state.password);

      const response = await apiHelper(
        "actor/register",
        "POST",
        formData,
        null
      );
      const res = response.data;

      if (res.status) {
        localStorage.setItem("userToken", res.data);
        // toasterSuccess(res.message);
        this.props.history.push("/profile");
      } else {
        toasterError(res.message);
      }
    } catch (e) {
      toasterError(e.message);
    }
  };

  render() {
    // console.log(this.props);
    // const token = localStorage.getItem('userToken');
    const {
      fName,
      lName,
      cityState,
      email,
      password,
      confirmPass,
      valid,
      acceptTerms,
    } = this.state;
    let disabled = false;
    if (
      !valid.fName ||
      !valid.lName ||
      !valid.cityState ||
      !valid.email ||
      !valid.password ||
      !valid.confirmPass ||
      !acceptTerms
    ) {
      disabled = true;
    }

    let btnClass =
      "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary";
    if (disabled) {
      btnClass += " Mui-disabled Mui-disabled";
    }

    return (
      // token ?
      //     <Redirect to="/profile" />
      //     :
      <div id="fuse-layout">
        <div className="sc-Axmtr hvJMgY">
          <div className="main12">
            <div
              className="jss12 mt-0"
              style={{
                marginBottom: "64px",
                height: "100%",
                minHeight: "100vh",
              }}
            >
              <div className="sc-AxjAm bcMPWx jss75">
                <div className="sc-AxiKw eSbheu">
                  {/* <img src={ARLogo} alt="logo" /> */}
                  <div className="heading-txt">
                    <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-colorInherit">
                      Welcome to Audition Revolution!
                    </h3>
                  </div>
                  <div>
                    <h6 className="MuiTypography-root MuiTypography-subtitle1 MuiTypography-colorInherit">
                      Sign In Or Create New Account!
                    </h6>
                  </div>
                </div>
                <div className="MuiPaper-root MuiCard-root sc-AxirZ bJCmFu MuiPaper-elevation1">
                  <div className="MuiCardContent-root fflex-start">
                    <h6 className="MuiTypography-root MuiTypography-h6">
                      CREATE AN ACCOUNT
                    </h6>
                    <form
                      action="#"
                      name="registerForm"
                      onSubmit={this.submitFun}
                    >
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label data-shrink="false">
                          First Name
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk Mui-error Mui-error">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="fName"
                            required=""
                            type="text"
                            className="form-control"
                            value={fName}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label data-shrink="false">
                          Last Name
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk Mui-error Mui-error">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="lName"
                            required=""
                            type="text"
                            className="form-control"
                            value={lName}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>

                      <div
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label
                          data-shrink="false"
                          htmlFor="address-input"
                          id="address-input-label"
                        >
                          City, State
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                          {/* <input name="cityState" placeholder="" required="" id="address-input" type="city" className="form-control" value={cityState} onChange={this.inputHandler} style={{ position: 'relative', verticalAlign: 'top' }} /> */}
                          <Autocomplete
                            style={{ position: 'relative', verticalAlign: 'top' }}
                            name="cityState"
                            required=""
                            value={cityState}
                            type="city"
                            className="form-control"
                            id="address-input"
                            placeholder=""
                            onChange={this.inputHandler}
                            apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                            onPlaceSelected={(place) => {
                              var formatted_address = place.formatted_address.split(",");
                              this.setState({
                                cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                                city: formatted_address[0],
                                state: formatted_address[1],
                                error: {
                                  ...this.state.error,
                                  cityState: "",
                                },
                                valid: {
                                  ...this.state.valid,
                                  cityState: true,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label data-shrink="false">
                          Email
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk Mui-error Mui-error">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="email"
                            required=""
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label data-shrink="false">
                          Password
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk Mui-error Mui-error">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root Mui-error Mui-error MuiInputBase-formControl">
                          <input
                            name="password"
                            required=""
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div
                        className="MuiFormControl-root MuiTextField-root mb-16 MuiFormControl-fullWidth"
                        style={{ marginBottom: "1.6rem" }}
                      >
                        <label data-shrink="false">
                          Password (Confirm)
                          <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                             *
                          </span>
                        </label>
                        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                          <input
                            name="confirmPass"
                            required=""
                            type="password"
                            className="form-control"
                            value={confirmPass}
                            onChange={this.inputHandler}
                          />
                        </div>
                      </div>
                      <div className="MuiFormControl-root">
                        <label className="MuiFormControlLabel-root">
                          <span
                            className="MuiButtonBase-root MuiIconButton-root jss337 MuiCheckbox-root MuiCheckbox-colorSecondary MuiIconButton-colorSecondary"
                            aria-disabled="false"
                          >
                            <span className="MuiIconButton-label custom-checkBox">
                              <input
                                className="jss340 cus-check"
                                id="termsAndConditions"
                                name="acceptTermsConditions"
                                type="checkbox"
                                data-indeterminate="false"
                                onChange={this.termsHandler}
                              />
                              <span className="checkmark"></span>
                              {/* <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                                <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z">
                                                                </path>
                                                            </svg> */}
                            </span>
                          </span>
                          <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                            <span>
                              I read and accept{" "}
                              <a href="/">terms and conditions</a>
                            </span>
                          </span>
                        </label>
                      </div>
                      <button
                        className={btnClass}
                        tabIndex="-1"
                        type="submit"
                        id="createAccount"
                        aria-label="Register"
                        disabled={disabled}
                      >
                        <span className="MuiButton-label">
                          CREATE AN ACCOUNT
                        </span>
                      </button>
                    </form>
                    <div className="no-account">
                      <span className="font-medium">
                        Already have an account?
                      </span>
                      <Link className="font-medium" to="/login">
                        Login
                      </Link>
                      <Link className="font-medium" to="/register-company">
                        Company? Request Access
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="jss11">
                                <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters" style={{ padding: '0px' }}>
                                    <div className="sc-AxheI eXzlnr">
                                        <p className="MuiTypography-root MuiTypography-body2">Audition Revolution.v2 Beta</p>
                                        <p className="MuiTypography-root MuiTypography-body2">Contact Support: support@auditionrevolution.com</p>
                                    </div>
                                </div>
                            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((props) => <ActorRegisterComponent {...props} />);
