import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import RootReducers from '../reducers';

const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = null;
// console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
    store = createStore(RootReducers, composerEnhancer(applyMiddleware(thunk)));
} else {
    store = createStore(RootReducers, compose(applyMiddleware(thunk)));
}

export default store;

// export default createStore(RootReducers, composerEnhancer(applyMiddleware(thunk)))