import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ProfilePicComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }


    constructor(props) {
        super(props);
        this.state = {
            isDelete: true,
            profileImg: this.props.profilePic
        }
    }



    render() {

        const { isDelete, profileImg } = this.state;

        return (
            <>

                <div className="profile-up">
                    <div className="image-div">
                        <img src={profileImg} alt="profile" />
                    </div>
                </div>

            </>
        );
    }
}


export default withRouter(props => <ProfilePicComponent {...props} />);