import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link, withRouter } from "react-router-dom";
import ARLogo from "./../../../assets/img/AR_Logo.png";
import companyIcon from "./../../../assets/img/company-icon.png";
import userIcon from "./../../../assets/img/user-icon.png";
import loginIcon from "./../../../assets/img/login-icon.png";
import serviceIcon from "./../../../assets/img/clapperboard 1.png";

class HomeHeader extends Component {
  _unmounted = false;

  container = React.createRef();
  container2 = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpen2: false,
      imgErr: false,
      user: null,
      userType: [],
    };
  }

  componentDidMount() {
    this._unmounted = true;
    document.addEventListener("click", this.handleOutisideClick);
  }

  componentWillUnmount() {
    this._unmounted = false;
    document.removeEventListener("click", this.handleOutisideClick);
  }

  handleClick = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  handleClick2 = () => {
    this.setState((state) => ({
      isOpen2: !state.isOpen2,
    }));
  };

  handleOutisideClick = (e) => {
    if (this.container.current && !this.container.current.contains(e.target)) {
      this.setState({
        isOpen: false,
      });
    }

    if (
      this.container2.current &&
      !this.container2.current.contains(e.target)
    ) {
      this.setState({
        isOpen2: false,
      });
    }
  };

  notRedirect = (e) => {
    e.preventDefault();
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  notRedirect2 = (e) => {
    this.setState((state) => ({
      isOpen2: !state.isOpen2,
    }));
    e.preventDefault();
  };

  imgError = (e) => {
    this.setState({
      imgErr: true,
    });
  };

  noRedirect3 = (e) => {
    e.preventDefault();
    this.closeDropdwon();
  };

  closeDropdwon = () => {
    this.setState({
      isOpen2: false,
      isOpen: false,
    });
  };

  render() {
    return (
      <nav className="navbar navbar-default lig-gray">
        <div className="brand">
          <Link to="/">
            <img src={ARLogo} alt="AR" className="img-responsive logo" />
          </Link>
        </div>
        <div className="container-fluid">
          {/* landing page menu */}
          <div
            className=" navbar-collapse landing-page-nav"
            id="navbarSupportedContent"
          >
            <ul className="nav navbar-nav navbar-right">
              <li class="nav-item">
                <NavLink exact to="/service-directory">
                  <span className="icon">
                    <img src={serviceIcon} />
                  </span>
                  Service Directory
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/register-company">
                  <span className="icon">
                    <img src={companyIcon} />
                  </span>
                  Company Access
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/register">
                  <span className="icon">
                    <img src={userIcon} />
                  </span>
                  Actor Signup
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink exact to="/login">
                  <span className="icon">
                    <img src={loginIcon} />
                  </span>
                  Login Access
                </NavLink>
              </li>
            </ul>
          </div>
          {/* landing page menu */}
        </div>
      </nav>
    );
  }
}

export default withRouter(connect()(HomeHeader));
