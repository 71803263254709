import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { apiHelper } from '../../../helper/api.helper';
//import { useHistory, useParams } from 'react-router-dom';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';

class VerifyUserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id
        }

    }

    componentDidMount() {
        this.verifyUser();
    }

    verifyUser = async () => {
        try {
            const id = this.state.id;
            const response = await apiHelper('user/verify/' + id, 'GET', null, null);
            const res = response.data;
            if (res.status) {
                localStorage.setItem('userToken', res.data);
                //toasterSuccess(res.message);
                this.props.history.push('/profile');
            } else {
                toasterError(res.message);
                this.props.history.push('/profile');
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    render() {

        return (
            <div>

            </div>
        );
    }
}

export default withRouter(props => <VerifyUserComponent {...props} />);
