import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ActorSearchComponent from './SearchComponent';

class SearchActorComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <ActorSearchComponent />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(props => <SearchActorComponent {...props} />);