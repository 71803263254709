import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';

class Footer extends Component {
    render() {
        const token = localStorage.getItem('token');
        return (
            token &&
            <footer>
                <div className="container-fluid">
                    <p className="copyright">&copy; {moment().format('YYYY')}  <a href="https://www.themeineed.com">Theme I Need</a>. All Rights Reserved.</p>
                </div>
            </footer>
        );
    }
}

export default withRouter(props => <Footer {...props} />);
