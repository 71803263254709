import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { apiHelper } from './../../../helper/api.helper';
import { toasterSuccess, toasterError } from './../../../helper/toaster.helper';
import { jwtDecode } from './../../../helper/jwt.helper';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import Modal from 'react-bootstrap/Modal';

class ActorsComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('token')
    }
    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isStausUpdate: false,
            //isUploadModal: false,
            //filesArr: null,
            actorStatus: null
        }
    }

    componentDidMount() {
        this._unmounted = true;
        this.getActorsData();
    }


    // componentDidUpdate(prevProps, prevState) {
    //     const { isStausUpdate, data } = this.state;

    //     if(prevState.isStausUpdate !== isStausUpdate){
    //         if(data.length > 0){
    //             data.map(info=>this.emailStatusFormat(null, info));
    //         }
    //     }
    // }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getActorsData = async () => {
        try {
            const response = await apiHelper('admin/actor/getAllActor', 'GET', null, this.headers);
            let res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        data: data
                    })
                    // toasterSuccess(res.message);
                }
            } else {
                // toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }

    }
    statusFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.status} onChange={(e) => this.changeActorstatus(e, row)}>
            <option value="0">Inactive</option>
            <option value="1">Active</option>
            <option value="2">Deactive</option>
        </select>
        )
    }

    changeType = async (e, row) => {
        const formData = new FormData();
        formData.append('id', row.id);
        formData.append('type', e.target.value);

        const response = await apiHelper('admin/actor/changeType', 'POST', formData, this.headers);
        const res = response.data;
        if (res.status) {
            const targetVal = e.target.value;
            const actorArray = this.state.data;
            const updatedArray = actorArray.map((info) => {
                if (info.id === row.id) {
                    info.userType = targetVal
                }
                return info;
            })
            this.setState({
                data: updatedArray
            })
            //toasterSuccess(res.message);
        } else {
            toasterError(res.message);
        }
    }
    changeActorstatus = async (e, row) => {
        try {

            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('status', e.target.value);

            const response = await apiHelper('admin/actor/changeActorStatus', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const targetVal = e.target.value;
                const actorArray = this.state.data;
                const updatedArray = actorArray.map((info) => {
                    if (info.id === row.id) {
                        info.status = targetVal
                    }
                    return info;
                });
                this.setState(prevState => ({
                    data: updatedArray,
                    isStausUpdate: !prevState.isStausUpdate
                }));
                // this.getActorsData();
                //toasterSuccess(res.message);
                window.location.reload();

            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    emailStatusFormat = (cell, row) => {
        const { data } = this.state;
        let status = "0";
        if (data.length > 0) {
            const filterArr = data.filter(info => info.id === row.id);
            status = filterArr.length > 0 ? filterArr[0].status : row.status;
        } else {
            status = row.status;
        }
        return status === "1" ?
            <button type="button" className="btn btn-success" disabled>Verified</button>
            :
            status === "2" ?
                <button type="button" className="btn btn-danger" disabled>Deactived</button>
                :
                <button type="button" className="btn btn-primary" onClick={() => this.verifyHandler(row)}>Verify</button>
            ;
    }

    verifyHandler = async (row) => {
        try {
            const formData = new FormData();
            formData.append('id', row.id);
            formData.append('firstName', row.firstName);
            formData.append('lastName', row.lastName);
            formData.append('email', row.email);

            const response = await apiHelper('admin/actor/sendUserRegistrationEmail', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    actionFormat = (cell, row) => {
        return (
            <>
                <button type="button" className="btn btn-danger" onClick={() => this.deleteActor(row)} style={{ marginRight: '10px' }}>Delete</button>
                <button type="button" onClick={() => this.viewActorDetails(row.id)} className="btn btn-primary">View</button>
            </>
        )
    }
    typeFormat = (cell, row) => {
        return (<select className="form-control" defaultValue={row.userType} onChange={(e) => this.changeType(e, row)}>
            <option value="{actor}">Actor</option>
            <option value="{actor,theatre}">Actor/Company</option>
        </select>
        )
    }
    viewActorDetails = (id) => {
        this.props.history.push(`/admin/actor/details/${id}`)
    }
    deleteActor = async (row) => {
        try {
            if (window.confirm("Are you sure want to delete this Actor?")) {
                const formData = new FormData();
                formData.append('id', row.id);
                const response = await apiHelper('admin/actor/deleteActor', 'POST', formData, this.headers);
                const res = response.data;
                if (res.status) {
                    const darr = this.state.data.filter((info) => info.id !== row.id);
                    this.setState({
                        data: darr
                    })
                    //toasterSuccess(res.message);
                } else {
                    toasterError(res.message);
                }
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    // openFileModal = () => {
    //     this.setState({
    //         isUploadModal: true
    //     })
    // }

    // closeUploadModal = () => {
    //     this.setState({
    //         isUploadModal: false
    //     })
    // }
    // fileSubmit = async () => {
    //     try {

    //         const file = this.state.filesArr;
    //         let formData = new FormData();

    //         formData.append('actorFile', file);

    //         const response = await apiHelper('admin/actor/uploadActor', 'POST', formData, this.headers);
    //         const res = response.data;
    //         if (res.status) {
    //             this.setState({
    //                 isUploadModal: false
    //             })
    //             toasterSuccess(res.message);
    //         } else {
    //             toasterError(res.message);
    //         }

    //     } catch (e) {
    //         toasterError(e.message);
    //     }
    // }
    // uploadFiles = (e) => {
    //     const file = e.target.files[0];
    //     this.setState({
    //         filesArr: file
    //     })
    // }

    render() {
        const { SearchBar } = Search;
        // console.log(this.state.data);
        const columns = [
            { dataField: 'firstName', text: 'FirstName' },
            { dataField: 'lastName', text: 'LastName' },
            { dataField: 'email', text: 'Email' },
            // { dataField: 'phoneNumber', text: 'PhoneNumber' },
            { dataField: 'userType', text: 'Type', formatter: this.typeFormat },
            { dataField: 'status', text: 'Status', formatter: this.statusFormat },
            { dataField: 'emailVerify', text: 'Verified Status', formatter: this.emailStatusFormat },
            { dataField: 'action', text: 'Action', formatter: this.actionFormat },
        ]
        return (
            <>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="col-md-12">
                                    <button type="button" className="btn btn-success btn-upload-file" onClick={this.openFileModal}> Upload New Actors <i className="fa fa-paperclip"></i></button>
                                </div> */}
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Actors List</h3>
                                            {/* <div className="right">
                                                <button type="button" className="btn-toggle-collapse"><i className="lnr lnr-chevron-up"></i></button>
                                                <button type="button" className="btn-remove"><i className="lnr lnr-cross"></i></button>
                                            </div> */}
                                        </div>
                                        <div className="panel-body no-padding">
                                            <ToolkitProvider
                                                keyField='id' data={this.state.data} columns={columns}
                                                search
                                            >
                                                {
                                                    props => (
                                                        <div>
                                                            {/* <h3>Input something at below input field:</h3> */}
                                                            <div className="customSearch">
                                                                <SearchBar {...props.searchProps} />
                                                            </div>
                                                            <BootstrapTable
                                                                {...props.baseProps} classes="table table-striped" pagination={paginationFactory()}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                {/* <Modal show={this.state.isUploadModal} className="file_modal" onHide={this.closeUploadModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <h3>Upload Actor CSV</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-wrap">
                            <div className="file-div">
                                <input id="uploadFil" type="file" name="file" onChange={this.uploadFiles} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default" onClick={this.closeUploadModal}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={this.fileSubmit}>Submit</button>
                    </Modal.Footer>
                </Modal> */}
            </>
        );
    }
}

export default withRouter(props => < ActorsComponent {...props} />);