import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
//import ResumeComponent from './ResumeComponent';
import './../ProfileComponent/ActorProfileComponent.css';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import ProfilePicComponent from './ProfilePicComponent';
import ResumeComponent from './ResumeComponent';
import AddActorTag from './AddActorTag';
import AddActorNote from './AddActorNote';
import Carousel, { Modal, ModalGateway } from "react-images";

const DefaultProfilePic = "https://image.shutterstock.com/z/stock-vector-default-avatar-profile-icon-grey-photo-placeholder-518740741.jpg";
const Breakdown = ({ title, breakdownArray, width, defaultArray }) => {
    let newArray = [];

    for (var i = 0; i < defaultArray.length; i++) {
        if (breakdownArray.length > 0) {
            breakdownArray.find(info => {
                if (info === defaultArray[i]) {
                    newArray.push(info);
                }
            });
        }
    }
    return (
        <>
            <p>{title}: </p>
            <div className="break-down">
                <div className="flet-wrp">
                    {newArray.map(
                        (a, i) =>
                            a && (
                                // <div >
                                <div key={`${i}${a}`} className="custom-chip">
                                    <span>{a}</span>
                                </div>
                                // </div>
                            )
                    )}
                </div>
            </div>
        </>
    );
};
const styleFn = (styleObj) => ({ ...styleObj, zIndex: 100 });

const LightboxModal = props => {
    const { open, handleClose, images, currentIndex } = props;
    return (
        <ModalGateway>
            {open ? (
                <Modal
                    styles={{ blanket: styleFn, positioner: styleFn }}
                    onClose={handleClose}
                >
                    <Carousel views={images} currentIndex={currentIndex} />
                </Modal>
            ) : null}
        </ModalGateway>
    );
};
class CompanyActorProfileComponent extends Component {

    _unmounted = false;
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            experienceList: null,
            tabsActive: 0,
            profileData: null,
            profileImg: props.userImg,
            isActorTagOpen: false,
            isActorNoteOpen: false,
            profileImgModel: false
        }
    }
    openHeadShot = (url) => {
        this.setState({
            profileImgModel: true
        })

    }
    closeHeadShot = () => {
        this.setState({
            profileImgModel: false
        })
    }
    componentDidMount() {
        this._unmounted = true;
        this.getProfileData(this.props.match.params.id);
    }
    getProfileData = async (id) => {
        try {
            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('company/getActorProfile', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                var result = data.experienceOrder.substring(1, data.experienceOrder.length - 1);
                if (this._unmounted) {
                    this.setState({
                        profileData: data,
                        experienceList: result.split(',')
                    })
                }
                // toasterSuccess(res.message);
            } else {

                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    tabHandler = (tabVal) => {
        this.setState({
            tabsActive: tabVal
        })
    }
    inchToFeet = (val) => {
        const feet = Math.floor(val / 12);
        const inches = val % 12;
        return feet + " Feet " + inches + ' Inches';
    }

    openActorNoteModel = (e) => {
        e.preventDefault();
        this.setState({
            isActorNoteOpen: true
        })
    }
    openActorTagModel = (e) => {
        e.preventDefault();
        this.setState({
            isActorTagOpen: true
        })
    }
    closeActorNoteModel = () => {
        this.setState({
            isActorNoteOpen: false
        })
    }
    closeActorTagModel = () => {
        this.setState({
            isActorTagOpen: false
        })
    }
    phoneNumber = (phoneNumber) => {
        const phoneString = phoneNumber.toString().split("");
        const areaCode = phoneString.slice(0, 3).join("");
        const phone1 = phoneString.slice(3, 6).join("");
        const phone2 = phoneString.slice(6, 10).join("");
        return `(${areaCode}) ${phone1}-${phone2}`;
    }
    render() {
        const { tabsActive, profileImg, profileData } = this.state;

        const unionDefaultArray = ['Non-Union', 'AEA', 'AEA-EMC', 'Other'];
        const vocalRangeDefaultArray = ['Soprano', 'Mezzo Soprano Belter', 'Mezzo Soprano', 'Alto', 'Tenor', 'Baritenor', 'Baritone', 'Bass'];
        const ethnicityDefaultArray = ['African American', 'Asian', 'Caucasian', 'Hispanic', 'Latino', 'Native American', 'Alaskan Native', 'Hawaiian', 'Middle Eastern', 'North African', 'Multi-Cultural'];
        const genderDefaultArray = ['Female', 'Male', 'Non-Binary', 'Other'];
        const ageRangeDefaultArray = ['Child', 'Teen', 'Teens-20', '20s-30s', '30s-40s', '40s-50s', '50s-60s', '60s-70s', 'Over 70'];
        return (
            this.state.profileData &&
            <>
                <div className="profile-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="d-flex flex-column justify-content-between">
                                    <div className="profile-pic" style={{ marginTop: '10px' }}>
                                        <img data-cy="profile-picture" src={this.state.profileData && this.state.profileData.profilePic ? this.state.profileData.profilePic : DefaultProfilePic} alt="user" onClick={this.openHeadShot} />
                                    </div>
                                </div>
                                {/* <div className="col-sm-12"> */}
                                <div className="lik-werp" style={{marginTop:'5rem'}}>
                                    <h6>Notes</h6>
                                    <p><a href="/#" onClick={this.openActorNoteModel}>See and Add Notes On This Actor</a></p>
                                </div>
                                {/* </div>
                                <div className="col-sm-12"> */}
                                <div className="lik-werp">
                                    <h6>Tags</h6>
                                    <p><a href="/#" onClick={this.openActorTagModel}>See and Add Tags On This Actor</a></p>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-sm-8 ">
                                <div className="flex-bloc profile-detail-mob">
                                    <div className="profile-info2 p-2">
                                        <h2 className="title-name">{this.state.profileData && this.state.profileData.firstName} {this.state.profileData && this.state.profileData.lastName}</h2>
                                        <h6 className="title-address email-address desktop-hidden">Email: <a href={`mailto:${this.state.profileData.email}`}>{this.state.profileData.email}</a></h6>
                                        <h6 className="title-address desktop-hidden">Phone: {this.state.profileData && this.state.profileData.phoneNumber ? this.phoneNumber(this.state.profileData.phoneNumber) : ''}</h6>
                                        <h6 className="title-address">{this.state.profileData && this.state.profileData.city}, {this.state.profileData && this.state.profileData.state}</h6>
                                        <h6 className="title-address">{this.state.profileData && this.state.profileData.heightInches ? this.inchToFeet(this.state.profileData.heightInches) : '--'}</h6>
                                        <h6 className="title-address">Eye: {this.state.profileData && this.state.profileData.eyeColor}, Hair: {this.state.profileData && this.state.profileData.hairColor}</h6>
                                    </div>
                                    <div className="representation2 p-2">
                                        {this.state.profileData && this.state.profileData.representation ? <h6 className="title-address">Representation: {this.state.profileData.representation}</h6> : null}
                                        <h6 className="title-address email-address mobile-hidden">Email: <a href={`mailto:${this.state.profileData.email}`} style={{ color: '#29b6f6' }}>{this.state.profileData.email}</a></h6>
                                        <h6 className="title-address mobile-hidden">Phone: {this.state.profileData && this.state.profileData.phoneNumber ? this.phoneNumber(this.state.profileData.phoneNumber) : ''}</h6>
                                        {this.state.profileData && this.state.profileData.website ? <h6 className="title-address">
                                            <a
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                href={this.state.profileData.website}
                                                style={{ color: '#29b6f6' }}
                                            >
                                                Personal Site
                                            </a>
                                        </h6> : null}
                                    </div>
                                </div>
                                <div className="actor-wr">
                                    <h6 className="act-tit">Actor Breakdown</h6>
                                    <div className="flex-bloc justify-content-start align-start">
                                        <div className="rang-wrp">
                                            <div className="sect">
                                                <Breakdown title="Unions" breakdownArray={this.state.profileData ? this.state.profileData.unions : []} defaultArray={unionDefaultArray} />
                                            </div>

                                            <div className="sect">
                                                <Breakdown title="Age Range" breakdownArray={this.state.profileData ? this.state.profileData.ageRange : []} defaultArray={ageRangeDefaultArray} />
                                            </div>
                                            <div className="sect">
                                                <Breakdown title="Vocal Range" breakdownArray={this.state.profileData ? this.state.profileData.vocalRange : []} defaultArray={vocalRangeDefaultArray} />
                                            </div>
                                        </div>

                                        <div className="rang-wrp">
                                            <div className="sect">
                                                <Breakdown title="Gender" breakdownArray={this.state.profileData ? this.state.profileData.gender : []} defaultArray={genderDefaultArray} />
                                            </div>
                                            <div className="sect">
                                                <Breakdown title="Ethnicity" breakdownArray={this.state.profileData ? this.state.profileData.ethnicity : []} defaultArray={ethnicityDefaultArray} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            
                        </div> */}
                    </div>
                </div >

                <div className="tab-blos">
                    <ul className="tbs-ul">
                        <li className={tabsActive === 0 ? "active" : ""} onClick={() => this.tabHandler(0)}>General Resume</li>
                        <li className={tabsActive === 1 ? "active" : ""} onClick={() => this.tabHandler(1)}>Photos</li>
                    </ul>
                    <div className="blo-tabs">
                        {tabsActive === 0 ? <ResumeComponent experienceList={this.state.experienceList} /> : null}
                        {tabsActive === 1 ? <ProfilePicComponent profilePic={this.state.profileData.profilePic ? this.state.profileData.profilePic : null} /> : null}
                    </div>
                </div>
                {this.state.isActorTagOpen ? <AddActorTag onHide={this.closeActorTagModel} show={this.state.isActorTagOpen} id={this.props.match.params.id} actorName={this.state.profileData.firstName + ' ' + this.state.profileData.lastName} /> : null}
                {this.state.isActorNoteOpen ? <AddActorNote onHide={this.closeActorNoteModel} show={this.state.isActorNoteOpen} id={this.props.match.params.id} actorName={this.state.profileData.firstName + ' ' + this.state.profileData.lastName} /> : null}
                <LightboxModal open={this.state.profileImgModel} handleClose={this.closeHeadShot} images={[{ src: this.state.profileData && this.state.profileData.profilePic ? this.state.profileData.profilePic : DefaultProfilePic }]} currentIndex={0} />
            </>
        );
    }
}

export default withRouter(props => <CompanyActorProfileComponent {...props} />);