import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { jwtDecode } from '../../../helper/jwt.helper';
import AddSkillsComponent from './AddSkillsComponent';
// const tra = [
//     { id: 1, text: 'Testing 1' },
//     { id: 2, text: 'Testing 2' },
//     { id: 3, text: 'Testing 3' }
// ]
// const headers = {
//     'Authorization': localStorage.getItem('userToken')
// }
class SkillsComponent extends Component {
    _unmounted = false;

    headers = {
        'Authorization': localStorage.getItem('userToken')
    }

    constructor(props) {
        super(props);
        this.state = {
            isSkillsOrder: false,
            isSkillsModal: false,
            isSkillsEditModal: false,
            skillEditData: null,
            skillsArray: [],
        }
    }
    componentDidMount() {
        this._unmounted = true;
        this.getActorSkills();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getActorSkills = async () => {
        try {
            const response = await apiHelper('actor/getActorSkills', 'GET', null, this.headers);
            const res = response.data;
            if (res.status) {
                const data = jwtDecode(res.data);
                if (this._unmounted) {
                    this.setState({
                        skillsArray: data.skillData
                    })
                }
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    saveSkillsOrder = async () => {
        try {

            const formData = new FormData();
            formData.append('data', JSON.stringify(this.state.skillsArray));

            const response = await apiHelper('actor/reOrderSkills', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                if (this._unmounted) {
                    this.setState({ isSkillsOrder: false })
                }
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }

    }

    updateDat = (data) => {
        this.setState({
            skillsArray: data
        })
    }

    SkillseOrderFunc = () => {
        this.setState({ isSkillsOrder: true })
    }

    deleteSkill = async (id) => {
        try {

            const { skillsArray } = this.state;

            const formData = new FormData();
            formData.append('id', id);

            const response = await apiHelper('actor/deleteActorSkill', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                const updateArray = skillsArray.filter(info => info.id !== id);
                this.setState(prevState => ({
                    skillsArray: updateArray
                }))
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    openSkillsModal = () => {
        this.setState({
            isSkillsModal: true
        })
    }
    closeSkillsModal = () => {
        this.setState({
            isSkillsModal: false
        })
    }
    openSkillsEditModal = (data) => {
        // console.log(data);
        this.setState({
            isSkillsEditModal: true,
            skillEditData: data
        })
    }
    closeSkillsEditModal = () => {
        this.setState({
            isSkillsEditModal: false,
            skillEditData: null
        })
    }

    skillsSubmit = async (data) => {
        try {

            const formData = new FormData();
            formData.append('skillText', data.skillText);

            const response = await apiHelper('actor/addActorSkills', 'POST', formData, this.headers);
            const res = response.data;
            if (res.status) {
                if (!data.isMore) {
                    this.closeSkillsModal();
                }
                this.getActorSkills();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    skillsUpdateSubmit = async (data) => {
        try {

            // console.log(data)
            const formData = new FormData();
            formData.append('id', data.id);
            formData.append('skillText', data.skillText);

            const response = await apiHelper('actor/actorSkills/update', 'PUT', formData, this.headers);
            const res = response.data;
            if (res.status) {
                this.closeSkillsEditModal();
                this.getActorSkills();
                //toasterSuccess(res.message);
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }

    render() {

        const { isSkillsOrder, skillsArray } = this.state;
        return (
            <div>
                <div className="titl-wrp">
                    <h4 className="res-tit">Special Skills</h4>
                    <div className="bt-grops">
                        {isSkillsOrder ?
                            <button type="button" className="btns btn-transprent" aria-label="Save Changes" onClick={this.saveSkillsOrder}>Save Changes</button>
                            :
                            <button type="button" className="btns btn-transprent" aria-label="Reorder Skills" onClick={this.SkillseOrderFunc}>Reorder Skills</button>
                        }
                        <button type="button" className="btns btn-black" onClick={this.openSkillsModal} aria-label="Add Skills">Add Skills</button>
                    </div>
                </div>
                <div className="table-viwe">
                    <DndProvider backend={HTML5Backend}>
                        <UlLi data={skillsArray} deleteFun={this.deleteSkill} updateFun={this.openSkillsEditModal} updateData={this.updateDat} isReOrder={isSkillsOrder} />
                    </DndProvider>
                </div>
                {this.state.isSkillsModal ? <AddSkillsComponent isEdit={false} show={this.state.isSkillsModal} onHide={this.closeSkillsModal} addSkillsSubmit={this.skillsSubmit} /> : null}
                {this.state.isSkillsEditModal ? <AddSkillsComponent isEdit={true} data={this.state.skillEditData} show={this.state.isSkillsEditModal} onHide={this.closeSkillsEditModal} addSkillsSubmit={this.skillsUpdateSubmit} /> : null}
            </div>
        );
    }
}

const UlLi = ({ data, updateData, isReOrder, deleteFun, updateFun }) => {
    // console.log(data);
    const [records, setRecords] = React.useState([]);

    React.useEffect(() => {
        // if (records.length === 0) {
        setRecords(data)
        // }
    }, [records, data])

    const moveCard = React.useCallback((dragIndex, hoverIndex) => {
        const dragCard = records[dragIndex];
        const upd = update(records, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        })
        setRecords(upd);
        updateData(upd);
        // React.useEffect(()=>{updateData(upd)},[updateData(upd)]);
    }, [records, updateData])

    const renderCard = (record, index) => {
        return (<LICompo key={index} isReorder={isReOrder} index={index} id={record.id} text={record.text} moveCard={moveCard} delete={deleteFun} update={updateFun} />);
    };

    return (<>
        <ul>{records.length > 0 ? records.map((record, i) => renderCard(record, i)) : <li>Add your info here!</li>}</ul>
    </>);

}

const DND_ITEM_TYPE = 'card';

const LICompo = (props) => {
    const ref = React.useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            props.moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: DND_ITEM_TYPE,
        item: () => {
            return { id: props.id, index: props.index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (<li style={{ opacity }} data-handler-id={handlerId}>
        <button type="button" className={props.isReorder ? "drag-buton display-show" : " drag-buton display-none"} ref={ref} ><i className="fa fa-arrows arr-icon"></i></button>
        <span>{props.text}
            <button type="button" className="btn-dele" onClick={() => props.delete(props.id)}><i className="fa fa-trash"></i></button>
            <button type="button" className="btn-dele" onClick={() => props.update({ id: props.id, text: props.text })}><i className="fa fa-pencil"></i></button>
        </span>
    </li>);
}

export default withRouter(props => <SkillsComponent {...props} />);