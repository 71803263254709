import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { apiHelper } from '../../../helper/api.helper';
import { toasterError, toasterSuccess } from '../../../helper/toaster.helper';
import { emailValidation, passwordValidation, confirmPassValidation } from '../../../helper/validation.helper';
import * as places from "places.js";
import { jwtDecode } from '../../../helper/jwt.helper';
import * as ACTION_TYPES from './../../../redux/actions/type';
import { connect } from 'react-redux';

class UpdateCompanyProfileComponent extends Component {
    headers = {
        "Authorization": localStorage.getItem('userToken')
    }
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            companyType: '',
            cityState: '',
            city: '',
            state: '',
            einNumber: '',
            fName: '',
            lName: '',
            //email: '',
            error: {
                companyName: '',
                companyType: '',
                cityState: '',
                einNumber: '',
                fName: '',
                lName: ''
                //email: ''
            },
            valid: {
                companyName: false,
                companyType: false,
                cityState: false,
                einNumber: false,
                fName: false,
                lName: false
                //email: false
            }
        }
    }

    componentDidMount() {
        const placeInstance = places({
            appId: "plL5W1957CCG",
            apiKey: "8f309db6542eab1fb7c18b83fcff357c",
            container: document.querySelector("#address-input")
        });

        placeInstance.configure({
            language: "en",
            type: this.props.type,
            countries: ["us"]
        });

        placeInstance.on("change", (e) => {
            const { name: city, administrative: state } = e.suggestion;
            this.setState({
                cityState: `${city}, ${state}`,
                city: city,
                state: state,
                error: {
                    ...this.state.error,
                    cityState: ''
                },
                valid: {
                    ...this.state.valid,
                    cityState: true
                }
            });
        });
        this.getCompanyInfo();
    }
    getCompanyInfo = async () => {

        try {
            const response = await apiHelper('company/getCompany', 'GET', null, this.headers);
            const res = response.data;

            if (res.status) {
                const data = jwtDecode(res.data);
                console.log(data);
                this.setState({
                    companyName: (data.name) ? data.name : '',
                    companyType: (data.irsStatus) ? data.irsStatus : '',
                    cityState: (data.city && data.state) ? data.city + ',' + data.state : '',
                    city: (data.city) ? data.city : '',
                    state: (data.state) ? data.state : '',
                    einNumber: (data.ein) ? data.ein : '',
                    fName: (data.firstName) ? data.firstName : '',
                    lName: (data.lastName) ? data.lastName : '',
                    //email: (data.email) ? data.email : '',
                    error: {
                        companyName: '',
                        companyType: '',
                        cityState: '',
                        einNumber: '',
                        fName: '',
                        lName: ''
                        //email: ''
                    },
                    valid: {
                        companyName: (data.name) ? true : false,
                        companyType: (data.irsStatus) ? true : false,
                        cityState: (data.city && data.state) ? true : false,
                        einNumber: (data.ein) ? true : false,
                        fName: (data.firstName) ? true : false,
                        lName: (data.lastName) ? true : false
                        //email: (data.email) ? true : false
                    }
                })
            } else {
                toasterError(res.message);
            }
        } catch (e) {
            toasterError(e.message);
        }
    }
    inputHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.checkValidation(name, value))
    }

    checkValidation = (name, value) => {
        let error = this.state.error;
        let valid = this.state.valid;

        switch (name) {
            case 'companyName':
                valid.companyName = value.length > 0;
                error.companyName = valid.companyName ? '' : 'Company name Required';
                break;
            case 'companyType':

                if (value === "Company Type") {
                    valid.companyType = false;
                    error.companyType = 'Company type Required';
                } else {
                    valid.companyType = value.length > 0;
                    error.companyType = valid.companyType ? '' : 'Company type Required';
                }
                break;
            case 'cityState':
                valid.cityState = value.length > 0;
                error.cityState = valid.cityState ? '' : 'City,State Required';
                break;
            case 'einNumber':
                valid.einNumber = value.length > 0;
                error.einNumber = valid.einNumber ? '' : 'EIN number Required';
                break;
            case 'fName':
                valid.fName = value.length > 0;
                error.fName = valid.fName ? '' : 'First Name Required';
                break;
            case 'lName':
                valid.lName = value.length > 0;
                error.lName = valid.lName ? '' : 'Last Name Required';
                break;

            // case 'email':
            //     const emailValid = emailValidation(value);
            //     valid.email = emailValid.status;
            //     error.email = emailValid.status ? "" : emailValid.message
            //     break;
            default:
                break;
        }
        this.setState({
            error,
            valid
        })
    }

    submitFun = async (e) => {
        try {
            e.preventDefault();
            const { valid } = this.state;
            if (!valid.companyName || !valid.companyType || !valid.einNumber || !valid.fName || !valid.lName || !valid.cityState) {
                toasterError('All Field required.');
                return;
            }

            const formData = new FormData();
            formData.append('companyName', this.state.companyName);
            formData.append('companyType', this.state.companyType);
            formData.append('einNumber', this.state.einNumber);
            formData.append('firstName', this.state.fName);
            formData.append('lastName', this.state.lName);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            //formData.append('email', this.state.email);
            formData.append('password', this.state.password);

            const response = await apiHelper('company/updateInfo', 'POST', formData, this.headers);
            const res = response.data;

            if (res.status) {
                localStorage.setItem('userToken', res.data);
                this.props.userUpdateFun(true);
                //toasterSuccess(res.message);
                //window.location.reload()
            } else {
                toasterError(res.message);
            }

        } catch (e) {
            toasterError(e.message);
        }
    }
    render() {
        const ranges = [
            {
                value: "nonprofit",
                label: "Non-Profit Theatre"
            },
            {
                value: "forProfit",
                label: "For-Profit Theatre"
            },
            {
                value: "filmAndTelevision",
                label: "TV & Film"
            },
            {
                value: "talentAgency",
                label: "Talent Agency"
            }
        ];
        const { companyName, companyType, cityState, einNumber, fName, lName, password, confirmPass, valid, acceptTerms } = this.state;
        const disabled = !valid.companyName || !valid.companyType || !valid.einNumber || !valid.fName || !valid.lName || !valid.cityState
        return (

            <div className="col-sm-6">
                <div className="steps_wrap">
                    <div className="step_conetnt" style={{ paddingTop: '0px' }}>
                        <div className="form_erap">
                            <h2 style={{ margin: '0 0 30px', textAlign: 'center' }}>Update Profile</h2>

                            <div>
                                <h2>Company Info</h2>

                                <div className="form-group">
                                    <label>Company Name *</label>
                                    <input type="text" name="companyName" className="form-control" value={companyName} onChange={this.inputHandler} placeholder="Company Name*" />
                                </div>

                                <div className="form-group">
                                    <label>City, State *</label>
                                    <input type="text" id="address-input" name="cityState" value={cityState} onChange={this.inputHandler} className="form-control" placeholder="City, State*" />
                                </div>

                                <div className="form-group">
                                    <label>Company *</label>
                                    <select name="companyType" value={companyType} onChange={this.inputHandler} className="form-control">
                                        <option>Company Type</option>
                                        {
                                            ranges.length > 0 ?
                                                ranges.map((range, i) => <option key={i} value={range.value}>{range.label}</option>)
                                                :
                                                <option>Add your info here!</option>
                                        }
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>EIN Number *</label>
                                    <input type="text" name="einNumber" value={einNumber} onChange={this.inputHandler} className="form-control" placeholder="EIN Number*" />
                                </div>
                            </div>
                            <div>
                                <h2 style={{ marginTop: '40px' }}>User Info</h2>

                                <div className="form-group">
                                    <label>First Name *</label>
                                    <input type="text" name="fName" value={fName} onChange={this.inputHandler} className="form-control" placeholder="First Name*" />
                                </div>

                                <div className="form-group">
                                    <label>Last Name *</label>
                                    <input type="text" name="lName" value={lName} onChange={this.inputHandler} className="form-control" placeholder="Last Name*" />
                                </div>

                                {/* <div className="form-group">
                                    <label>Email *</label>
                                    <input type="text" name="email" value={email} onChange={this.inputHandler} className="form-control" placeholder="Email*" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="btn-gr">
                        <button type="button" aria-label="Next" disabled={disabled} onClick={this.submitFun} className="btn btn-primary">Update Profile</button>
                    </div>
                </div>
            </div >

        );
    }
}

const mapDispatchToProps = dispatch => ({
    userUpdateFun: (bool) => dispatch({ type: ACTION_TYPES.UPDATE_USER_INFO, payload: bool })
})
export default withRouter(connect(null, mapDispatchToProps)(UpdateCompanyProfileComponent));