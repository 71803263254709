import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NotesComponent from './NotesComponent';
import CompanyTagsComponent from './CompanyTagsComponent';

import ActorSearchComponent from './SearchComponent';

class CompanyProfileComponent extends Component {

    _unmounted = false;
    headers = {
        'Authorization': localStorage.getItem('userToken')
    }
    // constructor(props) {
    //     super(props);
    //     this.state = {

    //     }
    // }


    render() {


        return (

            <section className="bg-company">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8">
                            <ActorSearchComponent />
                        </div>
                        <div className="col-sm-4">
                            <NotesComponent />
                            <CompanyTagsComponent />
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default withRouter(props => <CompanyProfileComponent {...props} />);