import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { withRouter } from "react-router";
import Autocomplete from "react-google-autocomplete";
import CreatableSelect from "react-select/creatable";
import { Form } from "react-bootstrap";
import {
  aboutValidation,
  emailValidation,
} from "../../../helper/validation.helper";
import { toasterError, toasterSuccess } from "./../../../helper/toaster.helper";
import { apiHelper } from "./../../../helper/api.helper";
import { servicesHelper } from "../../../helper/service.helper";

class ServiceDirectoryViewModal extends Component {
  headers = {
    Authorization: localStorage.getItem("token"),
  };
  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id,
      firstName: props.data.firstName,
      about: props.data.about,
      city: props.data.city,
      companyName: props.data.companyName,
      email: props.data.email,
      lastName: props.data.lastName,
      phone: props.data.phone,
      state: props.data.state,
      status: props.data.status,
      website: props.data.website,
      services: props.data.services,
      cityState: `${props.data.city},${props.data.state}`,

      error: {
        firstName: "",
        about: "",
        city: "",
        companyName: "",
        email: "",
        lastName: "",
        phone: "",
        state: "",
        status: "",
        website: "",
        services: "",
        cityState: "",
      },
      valid: {
        firstName: (props.data.firstName)? true : false,
        about: (props.data.about)? true : false,
        city: (props.data.city)? true : false,
        companyName: (props.data.companyName)? true : false,
        email: (props.data.email)? true : false,
        lastName: (props.data.lastName)? true : false,
        phone: (props.data.phone)? true : false,
        state: (props.data.state)? true : false,
        status: (props.data.status)? true : false,
        website: (props.data.website)? true : false,
        services: (props.data.services)? true : false,
        cityState:(props.data.city || props.data.state)? true : false,
      },
    };
  }
  serviceDirectorySubmit = async (e) => {
    try {
      e.preventDefault();
      const { valid } = this.state;
      if (
        !valid.companyName ||
        !valid.firstName ||
        !valid.lastName ||
        !valid.cityState ||
        !valid.email ||
        !valid.website ||
        !valid.services ||
        !valid.about
      ) {
        toasterError("Please fill required fields.");
        return;
      }

      const formData = new FormData();
      formData.append("id", this.state.id);
      formData.append("companyName", this.state.companyName);
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.firstName);
      formData.append("city", this.state.city);
      formData.append("state", this.state.state);
      formData.append("email", this.state.email);
      formData.append("website", this.state.website);
      formData.append("services", JSON.stringify(this.state.services));
      formData.append("about", this.state.about);
      formData.append("phone", this.state.phone);

      const response = await apiHelper(
        "admin/service-directory/update",
        "POST",
        formData,
        this.headers
      );
      const res = response.data;

      if (res.status) {
        
        this.setState({
          companyName: "",
          cityState: "",
          city: "",
          state: "",
          fName: "",
          lName: "",
          email: "",
          phone: "",
          website: "",
          services: [],
          about: "",
        });
        this.props.onHide();
        window.location.reload();
      } else {
        toasterError(res.message);
      }
    } catch (e) {
      toasterError(e.message);
    }
  };
  inputHandler = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.checkValidation(name, value)
    );
  };
  selectHandler = (optionSelected) => {
    this.setState(
      {
        services: optionSelected,
      },
      () => this.checkValidation("services", optionSelected)
    );
  };
  checkValidation = (name, value) => {
    let error = this.state.error;
    let valid = this.state.valid;

    switch (name) {
      case "firstName":
        valid.firstName = value.length > 0;
        error.firstName = valid.firstName ? "" : "First Name Required";
        break;
      case "lastName":
        valid.lastName = value.length > 0;
        error.lastName = valid.lastName ? "" : "Last Name Required";
        break;
      case "email":
        const emailValid = emailValidation(value);
        valid.email = emailValid.status;
        error.email = emailValid.status ? "" : emailValid.message;
        break;
      case "companyName":
        valid.companyName = value.length > 0;
        error.companyName = valid.companyName ? "" : "Company name Required";
        break;
      case "website":
        valid.website = value.length > 0;
        error.website = valid.website ? "" : "Website Required";
        break;
      case "cityState":
        valid.cityState = value.length > 0;
        error.cityState = valid.cityState ? "" : "City,State Required";
        break;
      case "services":
        valid.services = value.length > 0;
        error.services = valid.services ? "" : "City,State Required";
        break;
      case "about":
        const aboutValid = aboutValidation(value);
        valid.about = aboutValid.status;
        error.about = aboutValid.status ? "" : aboutValid.message;
        break;

      default:
        break;
    }
    this.setState({
      error,
      valid,
    });
  };
  render() {
    const {
      about,
      companyName,
      email,
      firstName,
      lastName,
      phone,
      website,
      services,
      cityState,
      valid
    } = this.state;
    const selectedServices = services.map((item) => ({
      value: item.service,
      label: item.service,
    }));
    let disabled = false;
    if (
      !valid.companyName ||
      !valid.firstName ||
      !valid.lastName ||
      !valid.cityState ||
      !valid.email ||
      !valid.website ||
      !valid.services ||
      !valid.about
    ) {
      disabled = true;
    }
    return (
      <>
        <Modal
          show={this.props.show}
          className="file_modal"
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h3>Edit Service Directory</h3>
          </Modal.Header>
          <form action="/" method="POST" onSubmit={this.serviceDirectorySubmit}>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={firstName}
                      onChange={this.inputHandler}
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      value={lastName}
                      onChange={this.inputHandler}
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      name="email"
                      required=""
                      type="email"
                      onChange={this.inputHandler}
                      className="form-control"
                      value={email}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={phone}
                      onChange={this.inputHandler}
                      placeholder="Phone"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      name="companyName"
                      required=""
                      type="text"
                      className="form-control"
                      value={companyName}
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Website</label>
                    <input
                      name="website"
                      required=""
                      type="text"
                      className="form-control"
                      value={website}
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>City, State</label>
                    <Autocomplete
                      style={{
                        position: "relative",
                        verticalAlign: "top",
                      }}
                      name="cityState"
                      required=""
                      value={cityState}
                      type="city"
                      className="form-control"
                      id="address-input"
                      placeholder=""
                      onChange={this.inputHandler}
                      apiKey="AIzaSyCHtQQUdTM2dpMkJSEhkUWDgRVnwuZxfQU"
                      onPlaceSelected={(place) => {
                        var formatted_address =
                          place.formatted_address.split(",");
                        this.setState({
                          cityState: `${formatted_address[0]}, ${formatted_address[1]}`,
                          city: formatted_address[0],
                          state: formatted_address[1],
                          error: {
                            ...this.state.error,
                            cityState: "",
                          },
                          valid: {
                            ...this.state.valid,
                            cityState: true,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Services</label>
                    <CreatableSelect
                      defaultValue={selectedServices}
                      isMulti
                      onChange={this.selectHandler}
                      options={servicesHelper}
                      className="form-control-multi"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>About</label>
                    <Form.Control
                      as="textarea"
                      name="about"
                      onChange={this.inputHandler}
                      value={about}
                      className="form-control"
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" disabled={disabled} className="btn btn-primary">
                Update
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.props.onHide}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
export default withRouter((props) => <ServiceDirectoryViewModal {...props} />);
